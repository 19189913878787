import React, { createContext } from 'react';
import  io from 'socket.io-client';

// const ENDPOINT = 'http://46.17.250.209:8082';
// const admin = JSON.parse(sessionStorage.getItem('user'));

// export const socket = io.connect(ENDPOINT, {
//     transports: ['websocket', 'polling', 'flashsocket'],
//     jsonp: false, 
//     'forceNew': true
//   });
//   socket.on("connect", () => {
//     if (admin != null && admin.admin_type > 0){
//         socket.emit("admin_joined", admin.id);
//     }
    
//   });
// export const SocketContext = React.createContext();


const ENDPOINT = 'http://stirka.me:8082';
const admin = JSON.parse(sessionStorage.getItem('user'));

export const socket = io.connect(ENDPOINT, {
    transports: ['websocket'],
    jsonp: false, 
    'forceNew': true
  });
  socket.on("connect", () => {
    if (admin != null && admin.admin_type > 0){
        socket.emit("admin_joined", admin.id);
    } else if (admin != null && admin.admin_type == 0) {
      socket.emit("admin_joined_dwa", admin.id, admin.admin_type);
    }
    
  });
export const SocketContext = React.createContext();