import React, { createContext } from 'react';
import  io from 'socket.io-client';

const ENDPOINT = 'http://stirka.me:8086';
const admin = JSON.parse(sessionStorage.getItem('user'));

export const socket2 = io.connect(ENDPOINT, {
    transports: ['websocket'],
    jsonp: false, 
    'forceNew': true
  });
  socket2.on("connect", () => {
    if (admin != null && admin.admin_type > 0){
        socket2.emit("admin_joined", admin.id);
    } else if (admin != null && admin.admin_type == 0) {
      socket2.emit("admin_joined_dwa", admin.id, admin.admin_type);
    }
    
  });
export const SocketContext2 = React.createContext();