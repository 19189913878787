import React, {memo,useEffect,useState,useRef} from "react";


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';

import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import parse from 'html-react-parser';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from "@mui/material";

const AddEditProduct = ({ open, onClose, selectedProduct, selectedTocka,  selectedProductTypeId, socket, socket2 }) => {

    

    // var selectedProductTypeId2 = selectedProductTypeId;
    const [sliderValue, setSliderValue] = useState(typeof selectedProduct != 'undefined' ? selectedProduct.product_stock_limit : 30);
    const [isHiddenBox, setIsHiddenBox] = useState(selectedProductTypeId == 3 || selectedProductTypeId == 4 ? true : false);
    const [loading, setLoading] = React.useState(false);
    
    const selectedProductTypeId2 = useRef(null);
    const [productType, setProductType] = useState(selectedProductTypeId);
    const [rascyot, setRascyot] = useState(typeof selectedProduct != 'undefined' ? ~~(selectedProduct.product_volume/selectedProduct.product_unit) : 0);
    const priceRef = useRef();
    const capacityRef = useRef();
    const volumeRef = useRef(0);
    const limitRef = useRef(0);
    const unitRef = useRef(0);
    const stockRef = useRef(0);
    // setProductType(prdtype);

    useEffect(()=>{
      
      selectedProductTypeId2.current = productType; //typeof productType != 'undefined' || productType == 0 ? productType : selectedProductTypeId;
       //console.log('SELECTED TOCKA ISLEDI ' + selectedProductTypeId);
      setProductType(selectedProductTypeId)
       //console.log('PODUCCTTYPE ' + productType);

      
    },[selectedProductTypeId]);

    useEffect(() => {

      // selectedProductTypeId2.current = productType; //typeof productType != 'undefined' || productType == 0 ? productType : selectedProductTypeId;
      //  //console.log('SELECTED TOCKA ISLEDI ' + selectedProductTypeId2.current);
      // setProductType(selectedProductTypeId2.current)
      //  //console.log('PODUCCTTYPE ' + productType);


      // Update the document title using the browser API
      // setProductType(selectedProductTypeId);
       //console.log('PRODUCT TYPE UPDATED ' + productType + ' we ' + selectedProductTypeId + ' we ' );
       //console.log(productType == 3 || productType == 4 ? true : false);
      
        if (typeof selectedTocka != 'undefined' && typeof selectedTocka.podpiska != 'undefined') {
          if (selectedTocka.podpiska.length > 0 && selectedTocka.podpiska[0] == '1'){
          
            if (productType == 3 || productType == 4 || productType == 101 ? true : false) {
              setIsHiddenBox(true)
            } else {
              setIsHiddenBox(false)
            }
          } else {
            setIsHiddenBox(false)
          }
        }
      
      
      
    },[productType,selectedProductTypeId]);


    const handleProductTypeChange = (e) => {
       //console.log(e.target.value + ' ' + selectedProduct.product_has_limit + ' PODPISKA ' );
      
      setProductType(e.target.value);
      selectedProduct.col2.product_type = e.target.value;
      
      // selectedProductTypeId2 = e.target.value;
      //  //console.log('SELECTED PRODUCT TYPE ' + productType);
      // if (selectedTocka.podpiska.length > 0 && selectedTocka.podpiska[selectedTocka.podpiska-1] == '1'){
        
      //   if (e.target.value == 3 || e.target.value == 4) {
      //     setIsHiddenBox(true)
      //   } else {
      //     setIsHiddenBox(false)
      //   }
      // } else {
      //   setIsHiddenBox(false)
      // }
      
    }

     

    const handleSliderChange = (e) => {
       //console.log('e ' + e);
      setSliderValue(e.target.value)
    }

  return (
    <Dialog
        PaperProps={{ onClick: e => e.stopPropagation() }}
        onBackdropClick={e => e.stopPropagation()}
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 470 } }}
        maxWidth="xs"
        
        // TransitionProps={{ onEntering: handleEntering }}
        open={open}
        // {...other}
      >
        <DialogTitle>Редактирование</DialogTitle>
        <DialogContent dividers>
          {/* <TextField
            sx={{width:'100%'}}
            id="outlined-required"
            label="Наименование"
            defaultValue={typeof selectedProduct.col2 != 'undefined' ? selectedProduct.col2.product_name : ''}
          /> */}
          <FormControl sx={{mt:2,width:'100%'}}>
            <InputLabel id="demo-simple-select-label">Тип оборудования</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={productType}
              label="Тип оборудования"
              onChange={handleProductTypeChange}
            >
              <MenuItem value={1}>Стиральная машина</MenuItem>
              <MenuItem value={2}>Сушильная машина</MenuItem>
              <MenuItem value={3}>Дозатор геля</MenuItem>
              <MenuItem value={4}>Дозатор кондиционера</MenuItem>
              <MenuItem value={5}>Стирка обуви</MenuItem>
              <MenuItem value={6}>Стирка обуви(2)</MenuItem>
			  <MenuItem value={7}>Сушка обуви</MenuItem>
			  <MenuItem value={8}>Услуга шкафчик</MenuItem>
			  <MenuItem value={9}>Пакеты</MenuItem>
            </Select>
          </FormControl>
          <Stack fullwidth='true' direction='row' spacing={2} sx={{mt:2}}>
          <TextField
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">кг</InputAdornment>,
              min: 4, max: 10 
            }}
            
            inputProps={{type: 'number'}}
            sx={{width:'50%'}}
            id="outlined-required"
            label="Объем"
            inputRef={capacityRef}
            defaultValue={typeof selectedProduct.col2 != 'undefined' ? selectedProduct.col2.product_capacity : 0}
          />
          <TextField
            InputProps={{
              startAdornment: <InputAdornment position="start">{ 
                typeof selectedTocka != 'undefined' ? 
                typeof selectedTocka.currencysign != 'undefined' ?
                parse(selectedTocka.currencysign) : 'Р' 
                : 'Р' 
                }</InputAdornment>,
            }}
            sx={{width:'50%'}}
            id="outlined-required"
            type="number"
            label= 'Цена'
            inputRef={priceRef} 
            defaultValue={ 
              typeof selectedProduct.col4 != 'undefined' ? selectedProduct.col4.product_price : 0
          }
          />
          </Stack>
          {/* <FormControlLabel control={<Checkbox onChange={(event)=>{
             //console.log('E ' + event.target.checked);
            setIsHiddenBox(event.target.checked)
          }}/>} label="Имеет объем или количество" /> */}
            <Box 
            
            >
            {isHiddenBox == true ? 
                <Box>
                <Stack direction = 'row' spacing={2} sx={{mt:2}}>
                <TextField 
                    
                    type="number"
                    
                    
                    inputProps={{type: 'number'}}
                    sx={{width:'50%'}}
                    id="outlined-required"
                    label="Объем / количество   "
                    inputRef={volumeRef}
                    onChange={(e)=>{
                       //console.log('uytgetdim ' + e.target.value + ' we ' + typeof unitRef.current.value != 'undefined' ? ~~(e.target.value/unitRef.current.value) : 0)
                      setRascyot(typeof unitRef.current.value != 'undefined' ? ~~(e.target.value/unitRef.current.value) : 0);
                      // setRascyot(e.target.value)
                    }}
                    defaultValue={typeof selectedProduct.col2 != 'undefined' ? selectedProduct.product_volume : 0}
                />

                <TextField 
                    
                    type="number"
                    onChange={(e)=>{
                       //console.log('uytgetdim2 ' + e.target.value)
                      setRascyot(typeof volumeRef.current.value != 'undefined' ? ~~(volumeRef.current.value/e.target.value) : 0);
                      }}
                    
                    inputProps={{type: 'number'}}
                    sx={{width:'50%'}}
                    id="outlined-required"
                    label="Размер запуска / шт   "
                    inputRef={unitRef}
                    defaultValue={typeof selectedProduct.col2 != 'undefined' ? selectedProduct.product_unit : 0}
                />
                </Stack>

                <Stack direction = 'row' spacing={2} sx={{mt:2}}>
                <TextField 
                    
                    type="number"
                    
                    
                    inputProps={{type: 'number'}}
                    sx={{width:'50%'}}
                    id="outlined-required"
                    label="Остаток"
                    inputRef={stockRef}
                    
                    defaultValue={typeof selectedProduct.product_stock != 'undefined' ? selectedProduct.product_stock : 0}
                />

                <TextField 
                    disabled
                    type="number"
                    
                    
                    inputProps={{type: 'number'}}
                    sx={{width:'50%'}}
                    id="outlined-required"
                    label="Рассчет"
                    value = {typeof rascyot != 'undefined' ? rascyot : 0}
                    defaultValue={typeof rascyot != 'undefined' ? rascyot : 0}
                />
                </Stack>

                <Typography id="non-linear-slider" gutterBottom sx={{mt:2}}>
                    Уведомления при лимите: {sliderValue + ' %'} 
                  </Typography>
                  <Slider 
                    value={sliderValue}
                    min={0}
                    step={5}
                    max={100}
                    marks
                    
                    // scale={5}
                    // getAriaValueText={valueLabelFormat}
                    // valueLabelFormat={valueLabelFormat}
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                  />
                </Box>
                
                 : ''    
        }
          
            </Box>
            
          
          
        </DialogContent>
        <DialogActions sx={{pt:2, pb:2, pr:3}}>
        <LoadingButton disableElevation={true} sx={{
                          backgroundColor: 'warning.light',
                          pt:1, pb:1,
                          '&:hover': {
                            backgroundColor: 'warning.main',
                            // opacity: [0.3, 0.4, 0.3],
                          },
                        }}
                          onClick={onClose}
                          // loading={loading}
                          
                          loadingPosition="center"
                          variant="contained"
                        ><span>ОТМЕНИТЬ</span></LoadingButton>

                        <LoadingButton disableElevation={true} sx={{
                          backgroundColor: 'primary.light',
                          pt:1, pb:1,
                          '&:hover': {
                            backgroundColor: 'primary.main',
                            // opacity: [0.3, 0.4, 0.3],
                          },
                        }}
                          // onClick={}
                          loading={loading}
                          onClick={()=>{
                            setLoading(true)
                            selectedProduct.col4.product_price = priceRef.current.value;
                            selectedProduct.col2.product_type = productType;
                             //console.log('SAYALAN VALUE ONCLIKDA ' + productType);
                            var prdname = 'СТИРКА';
                            var prdname2 = 'Стиральная машина'
                            var prdtype = 1;

                            //  //console.log('SAYALAN VALUE ONCLIKDA ' + productType);
                             //console.log('HANY ' + priceRef.current.value,
                            // selectedProduct.id, 
                            // selectedTocka.id,
                            // prdtype,
                            // prdname,
                            // prdname2,
                            // capacityRef.current.value,
                            // volumeRef != 'undefined' ? volumeRef.current.value : 0,
                            // unitRef.current.value,
                            // sliderValue);



                            switch (productType) {
                              case 2:
                                prdname='СУШКА'
                                prdname2='Сушильная машина'
                                prdtype = 2;
                                break;
                              case 3:
                                prdname='ГЕЛЬ'
                                prdtype = 3;
                                prdname2='Дозатор геля'
                                break;
                              case 4:
                                prdname='КОНДИЦИОНЕР'
                                prdname2='Дозатор кондиционера'
                                prdtype = 4;
                                break;
                              case 5:
                                prdname='СТИРКА ОБУВИ'
                                prdname2='Аппарат стирки обуви'
                                prdtype = 5;
                                break;
                              case 7:
                                prdname='СУШКА ОБУВИ'
                                prdname2='Аппарат сушки обуви'
                                prdtype = 6;
                                break;
							  case 6:
                                prdname='СТИРКА ОБУВИ(2)'
                                prdname2='Аппарат стирки обуви'
                                prdtype = 51;
                                break;
							  case 8:
                                prdname='ШКАФЧИК'
                                prdname2='Услуга шкафчик'
                                prdtype = 100;
                                break;
							  case 9:
                                prdname='ПАКЕТЫ'
                                prdname2='Пакеты'
                                prdtype = 100;
                                break;
                              default:
                                break;
                            }

                            socket.emit('update_mashynka',
                            selectedTocka.tocka_tablet_id,
                            priceRef.current.value,
                            selectedProduct.id, 
                            selectedTocka.id,
                            prdtype,
                            prdname,
                            prdname2,
                            capacityRef.current.value,
                            volumeRef.current.value ,
                            unitRef.current.value,
                            sliderValue, stockRef.current.value);
                            
                            socket2.emit('update_mashynka',
                            selectedTocka.tocka_tablet_id,
                            priceRef.current.value,
                            selectedProduct.id, 
                            selectedTocka.id,
                            prdtype,
                            prdname,
                            prdname2,
                            capacityRef.current.value,
                            volumeRef.current.value,
                            unitRef.current.value,
                            sliderValue, stockRef.current.value);
                            
                            setTimeout(function () {
                              setLoading(false)
                              onClose();
                          }, 200);

                            

                          }}
                          loadingPosition="center"
                          variant="contained"
                        ><span>СОХРАНИТЬ</span>
                        </LoadingButton>
        </DialogActions>
      </Dialog>
  );
};
 
export default memo(AddEditProduct);