import React, { useContext, useEffect, useState,useRef } from 'react'
import Header from './Header';
import Login from '../Login/Login';
import axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import swal from 'sweetalert';
import LoadingButton from '@mui/lab/LoadingButton';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Box, Grid,FormControl,Select,MenuItem,FormHelperText,
	Typography, Stack,Paper, LinearProgress, Checkbox,IconButton,
	Button,TextField
  } from "@mui/material";
  import { darken, lighten, styled } from '@mui/material/styles';
  import ReceiptIcon from '@mui/icons-material/Receipt'; 


  import dayjs from 'dayjs';
  // import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
  
  
  import { ruRU } from '@mui/x-date-pickers/locales';
  import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
  import { LocalizationProvider } from '@mui/x-date-pickers';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import { DatePicker as DatePicker2 } from '@mui/x-date-pickers/DatePicker';
  import ru from 'dayjs/locale/ru';

import { SocketContext2 } from './SocketProvider2'; 
import AddEditPodpiska from './AddEditPodpiska';


export const Contracts = (props) => {

	// CONSTANTS
	const token2 = 'mesanatoken';
	const admin = JSON.parse(sessionStorage.getItem('user'));
	const token = sessionStorage.getItem('accessToken');
	const [selectedTocka,setSelectedTocka] = useState([]);
	const [selectedTockaId,setSelectedTockaId] = useState(0);
	const [selectedPodpiskaId,setSelectedPodpiskaId] = useState(0);
	const [selectedPodpiska, setSelectedPodpiska] = useState(null);
	const [selectedContract,setSelectedContract] = useState(null);
	const [loading,setLoading] = useState(false);
	const [tockas, setTockas] = useState([]);
	const [podpiskas, setPodpiskas] = useState([]);
	const [contracts, setContracts] = useState([]);
	const [ipes, setIpes] = useState([]);
	const [podpiskas2, setPodpiskas2] = useState([]);
	const [sany,setSany] = useState(0);
	const [open, setOpen] = useState(false);
	const [urlshka, setUrlshka] = useState('');
	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 50,
	  });
	const [rowCountState, setRowCountState] = React.useState(sany);
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: [],
		total: 0,
		page: 1,
		pageSize: 10
	  })

	  const [signed, setSigned] = useState(false)
	  const usetimesRef = useRef(0);

	  const costRef = useRef(0);
	  const startDateRef = useRef(null);
	  const endDateRef = useRef(null);

	  const cnameRef = useRef('')
	  const innRef = useRef('')
	  const checkRef = useRef(false)
	  const check2Ref = useRef(false)

	  const [startDate, setStartDate] = useState(dayjs(moment(new Date()).format("YYYY-MM-DD")));
	  const [endDate, setEndDate] = useState(dayjs(moment(new Date()).add(1,'months').format("YYYY-MM-DD")));
	  const [rasDay, setRasDay] = useState(moment().format('DD'))
	  const [podCost, setPodCost] = useState(0)

	  const [inny, setInny] = useState('')
	  const [ady, setAdy] = useState('')

	const socket2 = useContext(SocketContext2);

	socket2.on('connect', () => {
		//get the id from socket
		console.log(socket2.id);
	});


	const columns = [

		{ field: 'col1', headerName: 'Наименование', width: 300 ,sortable: false },
		{ field: 'col2', headerName: 'Принят', width: 100 ,sortable: false ,
		renderCell: (params) => (
			params.value == false ? 'не принят' : 'принят'
		)
		},
		/*
		Допы 
		ТГ Уведомления о непробитии чека - 6500 р
		ТГ Уведомление о снижении заряда батареи - 6500 р
		Сброс остатков сдачи на терминале удалённо - 2 500 р
		Добавление договоров оферты в ЛК - 10 000р

		Допы 25500
		ЗП декабрь 70000
		и по поводу ноября как договаривались с момента окончания работ увеличим зп до 70к я закончил 15 ноября, остальное время я заполнял подписки и оплаченные даты по точкам, получается надбавка на ноябрь не 20к а 10к как бы половина, если такой вариант устраивает то и за ноябрь хотел бы получить доплату ввиде 10к
		итого 105500р будет  
		*/


		// { field: 'col3', headerName: 'Конец', width: 100 ,sortable: false, },
		// { field: 'col4', headerName: 'Цена', width: 100 ,sortable: false },
		// { field: 'col5', headerName: 'Оплачено', width: 100 ,sortable: false },
		// { field: 'col6', headerName: 'Оплатить', width: 100 ,sortable: false ,
		// 	renderCell: (params) => (
		// 		params.value.podpiska.cost == params.value.podpiska.paid ?
		// 		<Button 
		// 			disabled
		// 			disableElevation={true} sx={{
		// 			width:'50%',
		// 			size:'small',
		// 			fontSize:7,
		// 			backgroundColor: '#66bb6a',
		// 			pt:1, pb:1,

		// 			'&:disabled': {
		// 					backgroundColor: '#ffb74d',
		// 					color: '#000'
		// 				},	
		// 			'&:hover': {
		// 			backgroundColor: '#388e3c',
		// 			},
		// 			}}
		// 			loading={loading}
		// 			onClick={()=>{
		// 			// setLoading(true)
		// 			// fetchPodpiskasByTocka(0)
					
		// 			}}
		// 			size="small"
		// 			loadingPosition="center"
		// 			variant="contained"
		// 			><span>ПОДКЛЮЧЕНО</span>
		// 			</Button> 
		// 			: 
		// 			<LoadingButton 
		// 				disableElevation={true} sx={{
		// 				width:'50%',
		// 				size:'small',
		// 				fontSize:9,
		// 				backgroundColor: '#66bb6a',
		// 				pt:1, pb:1,
						
		// 				'&:hover': {
		// 				backgroundColor: '#388e3c',
		// 				},
		// 				}}
		// 				loading={loading}
		// 				onClick={()=>{
		// 				// setLoading(true)
		// 				// fetchPodpiskasByTocka(0)
						
		// 				// setOpen(true)
		// 				fetchQRCode(100,params.value.podpiska)
						
		// 				}}
		// 				loadingPosition="center"
		// 				variant="contained"
		// 				><span>ОПЛАТИТЬ</span>
		// 			</LoadingButton> 
		// 	)
		// },
		// { field: 'col7', headerName: 'Чек', width: 50 ,sortable: false,
		// 	renderCell: (params) => (
      
		// 	  <IconButton color="primary" component="label" onClick={()=>{
		
		// 		console.log('BILLID ' + params.value)
		// 		var antonInn = '780432674007';
		// 	    const newWindow = window.open('https://cheques-lk.orangedata.ru/'+antonInn+'/'+params.value.billid, '_blank', 'noopener,noreferrer')
		// 	      if (newWindow) newWindow.opener = null
		// 	  }}>
				
		// 	    <ReceiptIcon />
		// 	  </IconButton>
		// 	),
		// },
		
	  ];

	// FUNCTIONS

	useEffect(() => {
		console.log('CONTRACTS ' + admin.id);
		console.log(admin);
		fetchProducts();
		fetchContracts();


		socket2.on('spb_bill_payed', (data,name) =>{
			console.log('spb_bill_payed ' + data + ', ' + name);
			(async function() {
			  try {
				console.log('GELDIM');
				
				setOpen(false)
				setLoading(true)
				// signContract2()
				fetchProducts();
				fetchContracts()
			  } catch (e) {
				console.error(e);
			  }
			})();
		  })

		return () => {
			socket2.off('spb_bill_payed');
      		socket2.off('get_tockas_power_pulse');
      
	   };
	},[]);

	
	const signContract = async () => {



		const { data } = await axios.post(
			"http://46.17.250.209:8081/tocka/sign/contract",{
				admin_id: admin.id,
				token: 'mesanatoken',
				cid: selectedContract.id,
				cname: cnameRef.current.value,
				cinn: innRef.current.value
				}
			)
	
			console.log(data);
		if (data.success == 1) {
			fetchContracts()
		}
	}

	const signContract2 = async () => {



		const { data } = await axios.post(
			"http://46.17.250.209:8081/tocka/sign/contract/dwa",{
				admin_id: admin.id,
				token: 'mesanatoken',
				cid: selectedContract.id,
				cname: ady,
				cinn: inny
				}
			)
	
			console.log(data);
		if (data.success == 1) {
			fetchContracts()
		}
	}


	const fetchQRCode = async () => {

		console.log('SELECTED TOCKAID ' + selectedContract);

		const { data } = await axios.post(
		"http://46.17.250.209:8085/stirka/sbp/request/qr/tri",{
			adminid: admin.id,
			token: 'mesanatoken',
			summa: selectedContract.cprice,
			cid: selectedContract.id,
			}
		)

		console.log('URL ALDYM GELDIM '+ data.sbp.Data.qrcId)
		setUrlshka(data.sbp.Data.qrcId)
		setOpen(true)

		//add cek probiwka
		//make button paid
		//make log by whom marked paid
		//make checkBox do not prodlit
		//do not add second same podpiska
		//add more days for payment +5 from now
		//make sever function to check payments and block tockas
		//make server function to prodlit podpiskas by creating newones
		

		
	}

	React.useEffect(() => {
		setRowCountState((prevRowCountState) =>
		  sany !== undefined ? sany : prevRowCountState,
		);
	  }, [sany, setRowCountState]);


	const addPodpiskaToTocka = async () => {

		console.log('HANY ' + startDateRef.current.value);
		const { data } = await axios.post(
			"http://46.17.250.209:8081/add/podpiska/by/tocka",{
				tockaid:selectedTockaId,
				token:token2,
				ras_day: rasDay,
				podid: selectedPodpiskaId,
				startdate: moment(moment(startDateRef.current.value,'DD.MM.YYYY')).format('YYYY-MM-DD') + ' 00:00:00',
				enddate: moment(moment(endDateRef.current.value,'DD.MM.YYYY')).format('YYYY-MM-DD') + ' 23:59:59',
				cost: costRef.current.value,
				is_main: selectedPodpiska.pod_is_major,
				podtype: selectedPodpiska.pod_type,
				podpiska: selectedTocka.podpiska
			}
			)
		fetchPodpiskasByTocka(selectedPodpiska.tid)
	}

	const exceptAdmins = [95,112,10,58,108,98,20,51,71,49]

	const fetchPodpiskasByTocka = async (of3) => {

		setPodpiskas([])
		setPodpiskas2([])
		setPageState(old => ({ ...old, isLoading: true }))
	
		var of = 0;
		if (of3 > 0) {
		  of = (of3 - 1) * 50;
		} 
		
	
		console.log('SELECTED TOCKAID IN LOAD PODPISKAS' + selectedTockaId);
	
		const { data } = await axios.post(
		  "http://46.17.250.209:8081/get/podpiskas/by/tocka",{
			tockaid:selectedTockaId,
			token:token2,
			offset:of
		  }
		)
		
		setPodpiskas(data.podpiskas);
		setPodpiskas2(data.podpiskas2);
	
	
	
		var rows2 = [];
		var ind2 = 0;
		if (of3 > 0) {
		ind2 = (of3 - 1) * 50
		}
		  
	
		data.podpiskas.map((podpiska,ind) => {
			var ind3 = ind2 + 1 + ind;
			
			rows2.push({ id: podpiska.id, 
			  col1: podpiska.pod_name, 
			  col2: moment.utc(moment(podpiska.startdate).add(3,'hours')).format("DD.MM.YYYY"), 
			  col3: moment.utc(moment(podpiska.enddate).add(3,'hours')).format("DD.MM.YYYY"), 
			  col4: podpiska.cost,
			  col5: podpiska.paid,
			  col6: {podpiska : podpiska},
			  col7: podpiska.billid,
			  podpiska: podpiska
			});
	
			
		  	setLoading(false)
	
		});
	
		setPageState(old => ({ ...old, isLoading: false, data: data.podpiskas, total: data.sany }))
		setLoading(false)
	
		setPodpiskas(rows2);
	
		setSany(data.sany);
		
		if (data.podpiskas.length == 0){
		  swal("ВНИМАНИЕ", 'НИЧЕГО НЕ НАЙДЕНО', "warning", {
			buttons: false,
			timer: 2000,
		  })
		} 
	
		
	  //(38,116,131,160,164,182,200,209,220)
	  //(48,53,76,77,83,135,138,165,188,195)
	};

	const fetchProducts = async () => {
		if (admin.admin_type == 1 || admin.admin_type == 2 || admin.admin_type == 4){
		  const { data } = await axios.post(
			"http://46.17.250.209:8081/get/tockas/for/admin/new",{
			  admin_id:admin.id,
			  token:token2
			}
		  )
		  console.log(' HANT ' + data.tockas.length);
		  const products = data;
		  setTockas(products.tockas);
		  setSelectedTockaId(data.tockas[0].id);
		  console.log(' HANT2 ' + selectedTockaId);
		  // if (products.tockas.length == 1){
			setSelectedTocka(products.tockas[0]);
		  // }
		  // innertockas = products.tockas;
		  
		} 
		else {
	
		  axios.post(
			"http://46.17.250.209:8081/get/tockas/by/admin/new",{
			  admin_id:admin.id,
			  token:token2
			}
		  ).then(res=>{
			const products = res.data;
			// innertockas = products.tockas;
			setTockas(products.tockas);
			setSelectedTockaId(products.tockas[0].id);
			// setIsLoaded(true);
			  if (products.tockas.length > 0){
				setSelectedTocka(products.tockas[0]);
				// setProducts(products.tockas[0].products);
				// setOrange(products.tockas[0].orange_data);
			  } 
			  
			}
	
		  )
		 }
	
		
	  };

	const fetchContracts = async () => {

		setContracts([])
		setSelectedContract(null)
		setSigned(false)
		  const { data } = await axios.post(
			"http://46.17.250.209:8081/get/tocka/contracts",{
			  admin_id: admin.id,
			  token:token2
			}
		  )
		  console.log(' HANT ' + data.contracts.length);
		  setContracts(data.contracts);
		  setIpes(data.ipes)
	  
		  if (selectedContract != null) {
			setSelectedContract(selectedContract.contract)
		  }
	  
	  
		  var rows2 = [];
		  var ind2 = 0;
			
		  console.log(data.ipes);
	  
		  data.contracts.map((contract,ind) => {
			  var ind3 = ind2 + 1 + ind;

			  console.log(contract);
			  
			  rows2.push({ id: contract.id, 
				col1: contract.cname, 
				col2: contract.signed,//moment.utc(moment(podpiska.startdate).add(3,'hours')).format("DD.MM.YYYY"), 
				// col3: moment.utc(moment(podpiska.enddate).add(3,'hours')).format("DD.MM.YYYY"), 
				// col4: podpiska.cost,
				// col5: podpiska.paid,
				// col6: {podpiska : podpiska},
				// col7: podpiska.billid,
				 contract: contract
			  });
	  
			  
				setLoading(false)
	  
		  });
	  
		  //setPageState(old => ({ ...old, isLoading: false, data: data.podpiskas, total: data.sany }))
		  setLoading(false)
	  
		  setContracts(rows2);
	  
		//   setSany(data.sany);
		  
		  if (data.contracts.length == 0){
			swal("ВНИМАНИЕ", 'НИЧЕГО НЕ НАЙДЕНО', "warning", {
			  buttons: false,
			  timer: 2000,
			})
		  } 
		  
		
	
		
	  };


	  const fetchContractsByTocka = async () => {

		console.log('HANY@ ' + selectedTocka.tocka_admin_id);
		setContracts([])
		setSigned(false)
		  const { data } = await axios.post(
			"http://46.17.250.209:8081/get/tocka/contracts",{
			  admin_id:selectedTocka.tocka_admin_id,
			  token:token2
			}
		  )
		  console.log(' HANT ' + data.contracts.length);
		  setContracts(data.contracts);
		  setIpes(data.ipes)
	  
		  if (selectedContract != null) {
			setSelectedContract(selectedContract.contract)
		  }
	  
	  
		  var rows2 = [];
		  var ind2 = 0;
			
	  
		  data.contracts.map((contract,ind) => {
			  var ind3 = ind2 + 1 + ind;
			  
			  rows2.push({ id: contract.id, 
				col1: contract.cname, 
				col2: contract.signed,//moment.utc(moment(podpiska.startdate).add(3,'hours')).format("DD.MM.YYYY"), 
				// col3: moment.utc(moment(podpiska.enddate).add(3,'hours')).format("DD.MM.YYYY"), 
				// col4: podpiska.cost,
				// col5: podpiska.paid,
				// col6: {podpiska : podpiska},
				// col7: podpiska.billid,
				 contract: contract
			  });
	  
			  
				setLoading(false)
	  
		  });
	  
		  //setPageState(old => ({ ...old, isLoading: false, data: data.podpiskas, total: data.sany }))
		  setLoading(false)
	  
		  setContracts(rows2);
	  
		//   setSany(data.sany);
		  
		  if (data.contracts.length == 0){
			swal("ВНИМАНИЕ", 'НИЧЕГО НЕ НАЙДЕНО', "warning", {
			  buttons: false,
			  timer: 2000,
			})
		  } 
		  
		
	
		
	  };

	const handleTockaChange = (event) => {
		// setValue(event.target.value);
		setPodpiskas([]);
		setSelectedTocka(tockas.filter((tocka)=>{return tocka.id === event.target.value})[0]);
		setSelectedTockaId(event.target.value);
	};

	const handlePodpiskaChange = (event) => {
		// setValue(event.target.value);
		// setPodpiskas([]);
		// 
		setSelectedPodpiskaId(event.target.value);
		var pod = podpiskas2.filter((tocka)=>{return tocka.id === event.target.value})[0];
		console.log('POD PODID ' + pod.podid);
		setSelectedPodpiska(podpiskas2.filter((tocka)=>{return tocka.id === event.target.value})[0]);
		setPodCost(podpiskas2.filter((tocka)=>{return tocka.id === event.target.value})[0].pod_price);
	};

	const handleCancel = () => {
		// setSelectedPromocodeId(0)
		setOpen(false)
	  };

	// STYLED ITEMS

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'center',
		elevation: 0,
		color: theme.palette.text.secondary,
	  }));

	const Item2 = styled(Paper)(({ theme }) => ({
		backgroundColor: '#501A2027',
		...theme.typography.body2,
		padding: theme.spacing(2),
		elevation: 0,
		// textAlign: 'center',
		color: theme.palette.text.secondary,
		
	  }));

	const getBackgroundColor = (color: string, mode: string) =>
		mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  	const getHoverBackgroundColor = (color: string, mode: string) =>
    	mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  	const getSelectedBackgroundColor = (color: string, mode: string) =>
    	mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  	const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
    	mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

	const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
		'& .super-app-theme--Open': {
		  backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
		  '&:hover': {
			backgroundColor: getHoverBackgroundColor(
			  theme.palette.info.main,
			  theme.palette.mode,
			),
		  },
		  '&.Mui-selected': {
			backgroundColor: getSelectedBackgroundColor(
			  theme.palette.info.main,
			  theme.palette.mode,
			),
			'&:hover': {
			  backgroundColor: getSelectedHoverBackgroundColor(
				theme.palette.info.main,
				theme.palette.mode,
			  ),
			},
		  },
		},
		'& .super-app-theme--Filled': {
		  backgroundColor: getBackgroundColor(
			theme.palette.success.main,
			theme.palette.mode,
		  ),
		  '&:hover': {
			backgroundColor: getHoverBackgroundColor(
			  theme.palette.success.main,
			  theme.palette.mode,
			),
		  },
		  '&.Mui-selected': {
			backgroundColor: getSelectedBackgroundColor(
			  theme.palette.success.main,
			  theme.palette.mode,
			),
			'&:hover': {
			  backgroundColor: getSelectedHoverBackgroundColor(
				theme.palette.success.main,
				theme.palette.mode,
			  ),
			},
		  },
		},
		'& .super-app-theme--PartiallyFilled': {
		  backgroundColor: getBackgroundColor(
			theme.palette.warning.main,
			theme.palette.mode,
		  ),
		  '&:hover': {
			backgroundColor: getHoverBackgroundColor(
			  theme.palette.warning.main,
			  theme.palette.mode,
			),
		  },
		  '&.Mui-selected': {
			backgroundColor: getSelectedBackgroundColor(
			  theme.palette.warning.main,
			  theme.palette.mode,
			),
			'&:hover': {
			  backgroundColor: getSelectedHoverBackgroundColor(
				theme.palette.warning.main,
				theme.palette.mode,
			  ),
			},
		  },
		},
		'& .super-app-theme--Rejected': {
		  backgroundColor: getBackgroundColor(
			theme.palette.error.main,
			theme.palette.mode,
		  ),
		  '&:hover': {
			backgroundColor: getHoverBackgroundColor(
			  theme.palette.error.main,
			  theme.palette.mode,
			),
		  },
		  '&.Mui-selected': {
			backgroundColor: getSelectedBackgroundColor(
			  theme.palette.error.main,
			  theme.palette.mode,
			),
			'&:hover': {
			  backgroundColor: getSelectedHoverBackgroundColor(
				theme.palette.error.main,
				theme.palette.mode,
			  ),
			},
		  },
		},
	  }));


    
	if(!token ) {
		return (<Login  />)
	  }

	  // RETURN RENDER 

	  return (
		<div>
		<Header selected = {8}/>

		{ 
			<AddEditPodpiska open={open} onClose={handleCancel} summa={100} tid={selectedTockaId} urlshka={urlshka} />
			}

		<Box sx={{ flexGrow: 1 }} margin = {2}>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}  >

				<Grid item xs={12} sm={6} md={3}  >
					<Grid item xs={12} sm={12} md={12} >
						
						{ admin.admin_type == 1 ? 
							<Item2 elevation='0' sx={{ 
							height: '100%', 
							backgroundColor: '#eafefd',
							'&:hover': {
							backgroundColor: '#eaf5fe',
							},
							}}>
							<div>
							<Typography variant="h5" color="#000">ВЫБОР ПРАЧЕЧНОЙ</Typography>
								<br/>

								<FormControl sx={{ width:'100%' }}>
								<Select
									size='small'
									value={selectedTockaId}
									onChange={handleTockaChange}
									displayEmpty
									inputProps={{ 'aria-label': 'Without label' }}
								>
									
									{
									typeof tockas != 'undefined' ?
									tockas && tockas.map(tocka=>(
															
																
																
										<MenuItem value={tocka.id}>{tocka.tocka_address}</MenuItem>
									))
									: ''
									}
									

									
								</Select>
								<FormHelperText>Выберите из выпадающего списка</FormHelperText>
								</FormControl>
								<br/><br/>
								<Stack direction='row' spacing={2}>

								<LoadingButton 
									disableElevation={true} sx={{
									width:'50%',
									backgroundColor: '#66bb6a',
									pt:1, pb:1,
									'&:hover': {
									backgroundColor: '#388e3c',
									// opacity: [0.3, 0.4, 0.3],
									},
									}}
									// onClick={}
									loading={loading}
									onClick={()=>{
									setLoading(true)
									fetchContractsByTocka()
									console.log('SHOW selectedTocka')
									console.log(selectedTocka)
									// 		setTimeEditing(true)
									//		fetchPodpiskasByTocka(0)
									//  	setTimeout(function () {
									//     setLoading(false)
									//     // onClose();
									// }, 200);

									

									}}
									loadingPosition="center"
									variant="contained"
								><span>ПОКАЗАТЬ</span>
								</LoadingButton>

								</Stack>
							</div>
						</Item2>
						
						
						: ''}

						<Item elevation='0' height ={500} sx={{ height: '500', backgroundColor: '#eafefd', }}>
						<Typography variant="h5" color="#000" sx={{m:1}} align='left'>ДОГОВОРА ОФЕРТЫ</Typography>
						<div style={{ height: 350, width: '100%' }}>
							{/* STYLED GRID   		*/}
							<StyledDataGrid  sx={{m:1}}
								getRowClassName={(params) => `super-app-theme--${params.row.isactive == false ? 'Rejected':''}`} 
								paginationMode="server"
								disableColumnMenu
								slots={{
									loadingOverlay: LinearProgress,
								}}
								loading = {loading}
								height={500}
								disableColumnFilter

								onCellDoubleClick = {async (params, event) => {
										console.log('DOUBLE CLICKED ' + params.row.id);

										var selectedContract = contracts.filter((cont)=> { return cont.id === params.id})[0]
										setSelectedContract(selectedContract.contract)
										console.log(selectedContract)
										
									// if (admin.admin_type == 1 || admin.admin_type == 4) {
									// 	setSelectedPromocode(promocodes.filter((prod)=> { return prod.id === params.id})[0])
									// 	setSelectedPromocodeId(promocodes.filter((prod)=> { return prod.id === params.id})[0].id)
									// 	setOpen(true);
									// } else {
									// 	if (typeof selectedTocka.podpiska != 'undefined' && selectedTocka.podpiska[1] == '1' && admin.admin_type != 2) {
									// 	setSelectedPromocode(promocodes.filter((prod)=> { return prod.id === params.id})[0])
									// 	setSelectedPromocodeId(promocodes.filter((prod)=> { return prod.id === params.id})[0].id)
									// 	setOpen(true);
									// 	}
									// }

									
										

									if (!event.ctrlKey) {
										event.defaultMuiPrevented = true;
									}
									}}

									// pagination
									rows={contracts}
									{...contracts}
									rowCount={rowCountState}
									
									columns={columns}
									// pageSize={50}
									// onPageChange={(params) => {
									// 	//console.log("===params===", params);
									// }}
									rowsPerPageOptions={[]}
									hideFooterPagination
    								hideFooterSelectedRowCount
									
									// pageSizeOptions={[50]}
									// paginationModel={paginationModel}
									// paginationMode="server"
									// onPaginationModelChange={(page)=>{
									// // //console.log('page ' + page.page);
									// setLoading(true);
									// setPaginationModel({ 'page' : page.page, pageSize: 50})
									// fetchPodpiskasByTocka(page.page + 1)
									// setLoading(false);
									// }}

							/> 

						</div>
					</Item>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={6} md={9} >
					

					<Item elevation='0' height ={500} sx={{ mt: 0, height: '500', backgroundColor: '#eafefd', }}>
						<Typography variant="h5" color="#000" sx={{m:1}} align='left'>СОДЕРЖАНИЕ ДОГОВОРА-ОФЕРТЫ</Typography>


						<div style={{ height: 350, width: '100%', m:2 }}>
							{/* STYLED GRID   		*/}

						{/* <Typography variant="h10" color="#000" sx={{m:1}} align='left'>
						dangerouslySetInnerHTML={{ __html:  
										 " netu <br> netu"
									
          
      						}} */}
						
						
						{/* </Typography>	 */}

						<div dangerouslySetInnerHTML={{ __html: selectedContract != null ? selectedContract.ctext : 'Выберите договор'}} />
						<br/><br/>

						
						{
							selectedContract != null ? 
							ipes != null ? 
							ipes.length > 0 ?

							<Stack direction={'column'} justifyContent={'center'} sx={{width:'100%'}}>
								<Box sx={{width:'100%'}} >
									
								{ selectedContract.signed ? 
								
									<TextField disabled
									//inputProps={{type: 'number'}}
									InputProps={{ inputProps: { min: 0, max: 31 } }}
									sx={{width:'85%'}}
									id="outlined-required"
									label="НАИМЕНОВАНИЕ ОРГАНИЗАЦИИ"
									inputRef={cnameRef}
									defaultValue={selectedContract.name}
									onChange={(e)=>{
    									console.log(e.target.value)
										// var curDate = moment(startDate.format('YYYY-MM-DD')).set('date',e.target.value)
										// setStartDate(curDate)
										}}
									/>
									 : 
									 <TextField 
										//inputProps={{type: 'number'}}
										InputProps={{ inputProps: { min: 0, max: 31 } }}
										sx={{width:'85%'}}
										id="outlined-required"
										label="НАИМЕНОВАНИЕ ОРГАНИЗАЦИИ"
										inputRef={cnameRef}
										defaultValue={ipes[0].ipename}
										onChange={(e)=>{
											console.log(e.target.value)
											// var curDate = moment(startDate.format('YYYY-MM-DD')).set('date',e.target.value)
											// setStartDate(curDate)
											}}
									/>
									 }
									
									</Box>

								<Box sx={{width:'100%', mt:5}} >
									
									{selectedContract.signed ? 
										<TextField disabled
											type="number"
											
											//inputProps={{type: 'number'}}
											InputProps={{ inputProps: { min: 0, max: 31 } }}
											sx={{width:'85%'}}
											id="outlined-required"
											label="ИНН"
											inputRef={innRef}
											defaultValue={selectedContract.inn}
											/>
										: 
										<TextField 
											type="number"
											
											//inputProps={{type: 'number'}}
											InputProps={{ inputProps: { min: 0, max: 31 } }}
											sx={{width:'85%'}}
											id="outlined-required"
											label="ИНН"
											inputRef={innRef}
											defaultValue={ipes[0].inn}
											/>
									}
									
									</Box>
							</Stack>

							: <Stack direction={'column'} justifyContent={'center'} sx={{width:'100%'}}>
								<Box sx={{width:'100%'}} >
									
								{selectedContract.signed ? 
								
									<TextField disabled
									//inputProps={{type: 'number'}}
									InputProps={{ inputProps: { min: 0, max: 31 } }}
									sx={{width:'85%'}}
									id="outlined-required"
									label="НАИМЕНОВАНИЕ ОРГАНИЗАЦИИ"
									inputRef={cnameRef}
									defaultValue={selectedContract.name}
									onChange={(e)=>{
    									console.log(e.target.value)
										// var curDate = moment(startDate.format('YYYY-MM-DD')).set('date',e.target.value)
										// setStartDate(curDate)
										}}
									/>
									 : 
									 <TextField 
										//inputProps={{type: 'number'}}
										InputProps={{ inputProps: { min: 0, max: 31 } }}
										sx={{width:'85%'}}
										id="outlined-required"
										label="НАИМЕНОВАНИЕ ОРГАНИЗАЦИИ"
										inputRef={cnameRef}
										defaultValue={selectedContract.name}
										onChange={(e)=>{
											console.log(e.target.value)
											// var curDate = moment(startDate.format('YYYY-MM-DD')).set('date',e.target.value)
											// setStartDate(curDate)
											}}
									/>
									 }
									
									</Box>

								<Box sx={{width:'100%', mt:5}} >
									
									{selectedContract.signed ? 
										<TextField disabled
											type="number"
											
											//inputProps={{type: 'number'}}
											InputProps={{ inputProps: { min: 0, max: 31 } }}
											sx={{width:'85%'}}
											id="outlined-required"
											label="ИНН"
											inputRef={innRef}
											defaultValue={selectedContract.inn}
											/>
										: 
										<TextField 
											type="number"
											
											//inputProps={{type: 'number'}}
											InputProps={{ inputProps: { min: 0, max: 31 } }}
											sx={{width:'85%'}}
											id="outlined-required"
											label="ИНН"
											inputRef={innRef}
											defaultValue={selectedContract.inn}
											/>
									}
									
									</Box>
							</Stack>
							: ''
							: ''
						}
						
						
						{/* selectedContract.cid == 2 ? 
							''
							: */}


						{ 
						
						selectedContract != null ? 
							selectedContract.signed == false ? 
								
								exceptAdmins.includes(admin.id) ? 
									selectedContract.cid == 2 ? 
									'' 
									:    
									<Stack direction={'row'} justifyContent={'center'} sx={{width:'100%', mt:5, mr: 25}} >
													
													<Stack direction={'column'} >

														<Stack direction={'row'}>
														
														<Checkbox //checked={signed} 
																	
																	inputRef = {checkRef}
																	onChange={(event)=>{
																	console.log('HANY ' + event.target.checked)
																	console.log(event)
																	// setSigned(event.target.checked)
																	}
																	

															} />

														<Typography variant="h7" color="#000" sx={{m:1}} align='left'>ДОГОВОР ОФЕРТЫ</Typography>
														</Stack>

														<Stack direction={'row'}>
														
														<Checkbox //checked={signed} 
																	
																	inputRef = {check2Ref}
																	onChange={(event)=>{
																	console.log('HANY ' + event.target.checked)
																	console.log(event)
																	// setSigned(event.target.checked)
																	}
																	

															} />

														<Typography variant="h7" color="#000" sx={{m:1}} align='left'>ПРИЛОЖЕНИЕ К ДОГОВОРУ ОФЕРТЫ</Typography>
														</Stack>

													</Stack>

												<LoadingButton 
													disableElevation={true} sx={{
													width:'40%',
													height:'35px',
													//size:'small',
													fontSize:9,
													backgroundColor: '#66bb6a',
													//pt:1, pb:1,
													ml:5,
													'&:hover': {
													backgroundColor: '#388e3c',
													},
													}}
													loading={loading}
													onClick={()=>{
													// setLoading(true)
													// fetchPodpiskasByTocka(0)
													
													// setOpen(true)
													//fetchQRCode()

													console.log('CNAME ' + cnameRef.current.value + ' ' + checkRef.current.checked)
													console.log('CINN ' + innRef.current.value)

														if (checkRef.current.checked && check2Ref.current.checked && cnameRef.current.value != '' && innRef.current.value != '') {

															//setOpen(true)
															setInny(innRef.current.value)
															setAdy(cnameRef.current.value)
															fetchQRCode()
															//signContract()



														} else {
															swal("ВНИМАНИЕ", 'ГАЛОЧКА НЕ ОТМЕЧЕНА ИЛИ НЕ ВВЕДЕНЫ РЕКВИЗИТЫ', "warning", {
																buttons: false,
																timer: 2000,
															})
														}

													
													}}
													loadingPosition="center"
													variant="contained"
													><span>ПРИНЯТЬ</span>
												</LoadingButton> 
											</Stack>
								: <Stack direction={'row'} justifyContent={'center'} sx={{width:'100%', mt:5, mr: 25}} >
												
												{/* <Stack direction={'column'} >

													<Stack direction={'row'}>
													
													<Checkbox //checked={signed} 
																
																inputRef = {checkRef}
																onChange={(event)=>{
																console.log('HANY ' + event.target.checked)
																console.log(event)
																// setSigned(event.target.checked)
																}
																

														} />

													<Typography variant="h7" color="#000" sx={{m:1}} align='left'>ДОГОВОР ОФЕРТЫ</Typography>
													</Stack>

													<Stack direction={'row'}>
													
													<Checkbox //checked={signed} 
																
																inputRef = {check2Ref}
																onChange={(event)=>{
																console.log('HANY ' + event.target.checked)
																console.log(event)
																// setSigned(event.target.checked)
																}
																

														} />

													<Typography variant="h7" color="#000" sx={{m:1}} align='left'>ПРИЛОЖЕНИЕ К ДОГОВОРУ ОФЕРТЫ</Typography>
													</Stack>

												</Stack> */}

												<Checkbox //checked={signed} 
																
																inputRef = {checkRef}
																onChange={(event)=>{
																console.log('HANY ' + event.target.checked)
																console.log(event)
																// setSigned(event.target.checked)
																}
																

														} />

											<LoadingButton 
												disableElevation={true} sx={{
												width:'40%',
												height:'35px',
												//size:'small',
												fontSize:9,
												backgroundColor: '#66bb6a',
												//pt:1, pb:1,
												ml:5,
												'&:hover': {
												backgroundColor: '#388e3c',
												},
												}}
												loading={loading}
												onClick={()=>{
												// setLoading(true)
												// fetchPodpiskasByTocka(0)
												
												// setOpen(true)
												//fetchQRCode()

												console.log('CNAME ' + cnameRef.current.value + ' ' + checkRef.current.checked)
												console.log('CINN ' + innRef.current.value)

													if (checkRef.current.checked  && cnameRef.current.value != '' && innRef.current.value != '') {

														//setOpen(true)
														setInny(innRef.current.value)
														setAdy(cnameRef.current.value)
														//fetchQRCode()
														signContract()



													} else {
														swal("ВНИМАНИЕ", 'ГАЛОЧКА НЕ ОТМЕЧЕНА ИЛИ НЕ ВВЕДЕНЫ РЕКВИЗИТЫ', "warning", {
															buttons: false,
															timer: 2000,
														})
													}

												
												}}
												loadingPosition="center"
												variant="contained"
												><span>ПРИНЯТЬ</span>
											</LoadingButton> 
								</Stack>
										
							: ''
							
								
						: 
						''
								} 
						

						
						
						<br/><br/><br/><br/>
						</div>
					</Item>			
				</Grid>

			</Grid>
		</Box>
		</div>)
  }
  
  export default Contracts;
  