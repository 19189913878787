import React, { useContext, useEffect, useState,useRef } from 'react'
import Header from './Header';
import Login from '../Login/Login';
import axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import LoadingButton from '@mui/lab/LoadingButton';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Box, Grid,FormControl,Select,MenuItem,FormHelperText,
	Typography, Stack,Paper, LinearProgress, Checkbox,IconButton,
	Button,TextField
  } from "@mui/material";
  import { darken, lighten, styled } from '@mui/material/styles';
  import ReceiptIcon from '@mui/icons-material/Receipt'; 


  import dayjs from 'dayjs';
  // import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
  
  
  import { ruRU } from '@mui/x-date-pickers/locales';
  import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
  import { LocalizationProvider } from '@mui/x-date-pickers';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import { DatePicker as DatePicker2 } from '@mui/x-date-pickers/DatePicker';
  import ru from 'dayjs/locale/ru';

import { SocketContext2 } from './SocketProvider2'; 
import AddEditPodpiska from './AddEditPodpiska';


export const Podpiskas = (props) => {

	// CONSTANTS
	const token2 = 'mesanatoken';
	const admin = JSON.parse(sessionStorage.getItem('user'));
	const token = sessionStorage.getItem('accessToken');
	const [selectedTocka,setSelectedTocka] = useState([]);
	const [selectedTockaId,setSelectedTockaId] = useState(0);
	const [selectedPodpiskaId,setSelectedPodpiskaId] = useState(0);
	const [selectedPodpiska, setSelectedPodpiska] = useState(null);
	const [loading,setLoading] = useState(false);
	const [tockas, setTockas] = useState([]);
	const [podpiskas, setPodpiskas] = useState([]);
	const [podpiskas2, setPodpiskas2] = useState([]);
	const [sany,setSany] = useState(0);
	
	const [pastPodpiskas, setPastPodpiskas] = useState([]);
	const [pastPodpiskas2, setPastPodpiskas2] = useState([]);
	const [pastSany,setPastSany] = useState(0);
	

	const [open, setOpen] = useState(false);
	const [urlshka, setUrlshka] = useState('');
	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 50,
	  });
	const [rowCountState, setRowCountState] = React.useState(sany);
	const [rowCountStatePast, setRowCountStatePast] = React.useState(sany);
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: [],
		total: 0,
		page: 1,
		pageSize: 10
	  })

	  const usetimesRef = useRef(0);

	  const costRef = useRef(0);
	  const startDateRef = useRef(null);
	  const endDateRef = useRef(null);

	  const [startDate, setStartDate] = useState(dayjs(moment(new Date()).format("YYYY-MM-DD")));
	  const [endDate, setEndDate] = useState(dayjs(moment(new Date()).add(1,'months').format("YYYY-MM-DD")));
	  const [rasDay, setRasDay] = useState(moment().format('DD'))
	  const [podCost, setPodCost] = useState(0)

	const socket2 = useContext(SocketContext2);

	socket2.on('connect', () => {
		//get the id from socket
		console.log(socket2.id);
	});


	const columns = [

		{ field: 'col1', headerName: 'Наименование подписки', width: 250 ,sortable: false },
		{ field: 'col2', headerName: 'Начало', width: 100 ,sortable: false },
		{ field: 'col3', headerName: 'Конец', width: 100 ,sortable: false, },
		{ field: 'col4', headerName: 'Цена', width: 100 ,sortable: false },
		{ field: 'col5', headerName: 'Оплачено', width: 100 ,sortable: false },
		{ field: 'col6', headerName: 'Оплатить', width: 100 ,sortable: false ,
			renderCell: (params) => (
				params.value.podpiska.cost == params.value.podpiska.paid ?
				<Button 
					disabled
					disableElevation={true} sx={{
					width:'50%',
					size:'small',
					fontSize:7,
					backgroundColor: '#66bb6a',
					pt:1, pb:1,

					'&:disabled': {
							backgroundColor: '#ffb74d',
							color: '#000'
						},	
					'&:hover': {
					backgroundColor: '#388e3c',
					},
					}}
					loading={loading}
					onClick={()=>{
					// setLoading(true)
					// fetchPodpiskasByTocka(0)
					
					}}
					size="small"
					loadingPosition="center"
					variant="contained"
					><span>ПОДКЛЮЧЕНО</span>
					</Button> 
					: 
					<LoadingButton 
						disableElevation={true} sx={{
						width:'50%',
						size:'small',
						fontSize:9,
						backgroundColor: '#66bb6a',
						pt:1, pb:1,
						
						'&:hover': {
						backgroundColor: '#388e3c',
						},
						}}
						loading={loading}
						onClick={()=>{
						// setLoading(true)
						// fetchPodpiskasByTocka(0)
						
						// setOpen(true)
						fetchQRCode(100,params.value.podpiska)
						
						}}
						loadingPosition="center"
						variant="contained"
						><span>ОПЛАТИТЬ</span>
					</LoadingButton> 
			)
		},
		{ field: 'col7', headerName: 'Чек', width: 150 ,sortable: false,
			renderCell: (params) => (
      
			  admin.admin_type == 1 ? 
			  <div>
					<IconButton color="primary" component="label" onClick={()=>{
							
							console.log('BILLID ' + params.value.podpiska.billid)
							var antonInn = '780432674007';
							const newWindow = window.open('https://cheques-lk.orangedata.ru/'+antonInn+'/'+params.value.podpiska.odbillid, '_blank', 'noopener,noreferrer')
							if (newWindow) newWindow.opener = null
						}}>
							
							<ReceiptIcon />
						</IconButton> 
						<IconButton color="warning" component="label" onClick={()=>{
							
							//probit cek za dannuyu podpisku

							Swal.fire({
								title: "Вы уверены что хотите пробить чек на заданный период?",
								text: "Вы уверены что хотите пробить чек на заданный период!",
								icon: "warning",
								showCancelButton: true,
								confirmButtonText: 'Yes',
								denyButtonText: 'No',
								dangerMode: true,
								})
								.then((willDelete) => {
								if (willDelete.isConfirmed) 
									{
										console.log('TAPPED YES ' + params)
										axios.post(
										'http://46.17.250.209:8081/podpiska/create/odbill',
										{
											podtype: params.value.podpiska.podid,
											token:token2,
											tockaid:selectedTocka.id,
											qrcid: params.value.podpiska.billid,
											tobepaid: params.value.podpiska.cost
										}
										).then(res => {
										if (res.data.success == 1){
											Swal.fire("УСПЕХ","Данные успешно удалены!", "success",{
												button:'OK'
											})
											.then((value) => {
												//fetchProductsByTocka(selectedTocka.id);
											});
										} else {
											Swal.fire("ОШИБКА", res.data.message, "error", {
												button: 'OK'
											});
											
										}
										})
									} else 
									{
										console.log('CANCELLED')
									//Swal.fire("Your imaginary file is safe!");
									}
								});


							// console.log('BILLID ' + params.value)
							// var antonInn = '780432674007';
							// const newWindow = window.open('https://cheques-lk.orangedata.ru/'+antonInn+'/'+params.value, '_blank', 'noopener,noreferrer')
							// if (newWindow) newWindow.opener = null
						}}>
							
							<ReceiptIcon />
						</IconButton> 
						
			  </div>
			  
			  : 
			  <IconButton color="primary" component="label" onClick={()=>{
		
				console.log('BILLID ' + params.value.podpiska.odbillid)
				var antonInn = '780432674007';
			    const newWindow = window.open('https://cheques-lk.orangedata.ru/'+antonInn+'/'+params.value.podpiska.odbillid, '_blank', 'noopener,noreferrer')
			      if (newWindow) newWindow.opener = null
			  }}>
				
			    <ReceiptIcon />
			  </IconButton>
			  
			),
		},
		
	  ];

	const columns2 = [

	{ field: 'col1', headerName: 'Наименование подписки', width: 250 ,sortable: false },
	{ field: 'col2', headerName: 'Начало', width: 100 ,sortable: false },
	{ field: 'col3', headerName: 'Конец', width: 100 ,sortable: false, },
	{ field: 'col4', headerName: 'Цена', width: 100 ,sortable: false },
	{ field: 'col5', headerName: 'Оплачено', width: 100 ,sortable: false },
	{ field: 'col6', headerName: 'Оплатить', width: 100 ,sortable: false ,
		renderCell: (params) => (
			params.value.podpiska.cost == params.value.podpiska.paid ?
			<Button 
				disabled
				disableElevation={true} sx={{
				width:'50%',
				size:'small',
				fontSize:7,
				backgroundColor: '#66bb6a',
				pt:1, pb:1,

				'&:disabled': {
						backgroundColor: '#ffb74d',
						color: '#000'
					},	
				'&:hover': {
				backgroundColor: '#388e3c',
				},
				}}
				loading={loading}
				onClick={()=>{
				// setLoading(true)
				// fetchPodpiskasByTocka(0)
				
				}}
				size="small"
				loadingPosition="center"
				variant="contained"
				><span>ПОДКЛЮЧЕНО</span>
				</Button> 
				: 
				<LoadingButton 
					disableElevation={true} sx={{
					width:'50%',
					size:'small',
					fontSize:9,
					backgroundColor: '#66bb6a',
					pt:1, pb:1,
					
					'&:hover': {
					backgroundColor: '#388e3c',
					},
					}}
					loading={loading}
					onClick={()=>{
					// setLoading(true)
					// fetchPodpiskasByTocka(0)
					
					// setOpen(true)
					fetchQRCode(100,params.value.podpiska)
					
					}}
					loadingPosition="center"
					variant="contained"
					><span>ОПЛАТИТЬ</span>
				</LoadingButton> 
		)
	},
	{ field: 'col7', headerName: 'Чек', width: 150 ,sortable: false,
		renderCell: (params) => (
	
			admin.admin_type == 1 ? 
			<div>
				<IconButton color="primary" component="label" onClick={()=>{
						
						console.log('BILLID ' + params.value.podpiska.billid)
						var antonInn = '780432674007';
						const newWindow = window.open('https://cheques-lk.orangedata.ru/'+antonInn+'/'+params.value.podpiska.odbillid, '_blank', 'noopener,noreferrer')
						if (newWindow) newWindow.opener = null
					}}>
						
						<ReceiptIcon />
					</IconButton> 
					<IconButton color="warning" component="label" onClick={()=>{
						
						//probit cek za dannuyu podpisku

						Swal.fire({
							title: "Вы уверены что хотите пробить чек на заданный период?",
							text: "Вы уверены что хотите пробить чек на заданный период!",
							icon: "warning",
							showCancelButton: true,
							confirmButtonText: 'Yes',
							denyButtonText: 'No',
							dangerMode: true,
							})
							.then((willDelete) => {
							if (willDelete.isConfirmed) 
								{
									console.log('TAPPED YES ' + params)
									axios.post(
									'http://46.17.250.209:8081/podpiska/create/odbill',
									{
										podtype: params.value.podpiska.podid,
										token:token2,
										tockaid:selectedTocka.id,
										qrcid: params.value.podpiska.billid,
										tobepaid: params.value.podpiska.cost
									}
									).then(res => {
									if (res.data.success == 1){
										Swal.fire("УСПЕХ","Данные успешно удалены!", "success",{
											button:'OK'
										})
										.then((value) => {
											//fetchProductsByTocka(selectedTocka.id);
										});
									} else {
										Swal.fire("ОШИБКА", res.data.message, "error", {
											button: 'OK'
										});
										
									}
									})
								} else 
								{
									console.log('CANCELLED')
								//Swal.fire("Your imaginary file is safe!");
								}
							});


						// console.log('BILLID ' + params.value)
						// var antonInn = '780432674007';
						// const newWindow = window.open('https://cheques-lk.orangedata.ru/'+antonInn+'/'+params.value, '_blank', 'noopener,noreferrer')
						// if (newWindow) newWindow.opener = null
					}}>
						
						<ReceiptIcon />
					</IconButton> 
					
			</div>
			
			: 
			<IconButton color="primary" component="label" onClick={()=>{
	
			console.log('BILLID ' + params.value)
			var antonInn = '780432674007';
			const newWindow = window.open('https://cheques-lk.orangedata.ru/'+antonInn+'/'+params.value, '_blank', 'noopener,noreferrer')
				if (newWindow) newWindow.opener = null
			}}>
			
			<ReceiptIcon />
			</IconButton>
			
		),
	},
	
	];

	// FUNCTIONS

	useEffect(() => {
		console.log('PODPISKAS ');
		fetchProducts();


		socket2.on('spb_bill_payed', (data,name) =>{
			console.log('spb_bill_payed ' + data + ', ' + name);
			(async function() {
			  try {
				console.log('GELDIM');
				// //console.log('SELECTED TOCKA ID ' + selectedTockaId2.current + ' we ' + selectedTocka.id);
				// var numone = 0;
				// numone = Number(data);
				// var numtwo = 0;
				// numtwo = selectedTockaId2.current;
				// var numtri = Number(numtwo);
				// //fetchProductsByTocka(data);
				// //console.log('HANY HANY HANY ' + numone + ' WE ' + numtwo + ' WE ' + numtri);
				// if (numone === numtri){
				//   fetchSelectedTockaData(data);
				//   fetchSelectedTockaProducts(data)
				//   fetchSelectedTockaSales(data)
				// } else {
				//   //console.log('UPDATE NOT FOR THIS TOCKA');
				// }
				
				setOpen(false)
				setLoading(true)
									// setTimeEditing(true)
				fetchPodpiskasByTocka(0)
				fetchPastPodpiskasByTocka(0)
				
			  } catch (e) {
				console.error(e);
			  }
			})();
		  })

		return () => {
			socket2.off('spb_bill_payed');
      		socket2.off('get_tockas_power_pulse');
      
	   };
	},[]);

	


	const fetchQRCode = async (of3,podpiska) => {

		console.log('SELECTED TOCKAID ' + selectedTockaId);
		console.log('PODTYPE  ' + podpiska.pod_type);

		const { data } = await axios.post(
		"http://46.17.250.209:8085/stirka/sbp/request/qr/dwa",{
			tockaid: selectedTockaId,
			token: 'mesanatoken',
			summa: podpiska.cost,
			pid: podpiska.id,
			podtype: podpiska.pod_type,
			podpiska: selectedTocka.podpiska,
			podid: podpiska.pod_is_major
		}
		)

		console.log('URL ALDYM GELDIM '+ data.sbp.Data.qrcId)
		setUrlshka(data.sbp.Data.qrcId)
		setOpen(true)

		//add cek probiwka
		//make button paid
		//make log by whom marked paid
		//make checkBox do not prodlit
		//do not add second same podpiska
		//add more days for payment +5 from now
		//make sever function to check payments and block tockas
		//make server function to prodlit podpiskas by creating newones
		

		
	}

	React.useEffect(() => {
		setRowCountState((prevRowCountState) =>
		  sany !== undefined ? sany : prevRowCountState,
		);
	  }, [sany, setRowCountState]);


	const addPodpiskaToTocka = async () => {

		console.log('HANY ' + startDateRef.current.value);
		const { data } = await axios.post(
			"http://46.17.250.209:8081/add/podpiska/by/tocka",{
				tockaid:selectedTockaId,
				token:token2,
				ras_day: rasDay,
				podid: selectedPodpiskaId,
				startdate: moment(moment(startDateRef.current.value,'DD.MM.YYYY')).format('YYYY-MM-DD') + ' 00:00:00',
				enddate: moment(moment(endDateRef.current.value,'DD.MM.YYYY')).format('YYYY-MM-DD') + ' 23:59:59',
				cost: costRef.current.value,
				is_main: selectedPodpiska.pod_is_major,
				podtype: selectedPodpiska.pod_type,
				podpiska: selectedTocka.podpiska
			}
			)
		fetchPodpiskasByTocka(selectedPodpiska.tid)
	}


	const fetchPodpiskasByTocka = async (of3) => {

		setPodpiskas([])
		setPodpiskas2([])
		setPageState(old => ({ ...old, isLoading: true }))
	
		var of = 0;
		if (of3 > 0) {
		  of = (of3 - 1) * 50;
		} 
		
	
		console.log('SELECTED TOCKAID IN LOAD PODPISKAS' + selectedTockaId);
	
		const { data } = await axios.post(
		  "http://46.17.250.209:8081/get/podpiskas/by/tocka",{
			tockaid:selectedTockaId,
			token:token2,
			offset:of
		  }
		)
		
		setPodpiskas(data.podpiskas);
		setPodpiskas2(data.podpiskas2);
	
	
	
		var rows2 = [];
		var ind2 = 0;
		if (of3 > 0) {
		ind2 = (of3 - 1) * 50
		}
		  
	
		data.podpiskas.map((podpiska,ind) => {
			var ind3 = ind2 + 1 + ind;
			
			rows2.push({ id: podpiska.id, 
			  col1: podpiska.pod_name, 
			  col2: moment.utc(moment(podpiska.startdate).add(3,'hours')).format("DD.MM.YYYY"), 
			  col3: moment.utc(moment(podpiska.enddate).add(3,'hours')).format("DD.MM.YYYY"), 
			  col4: podpiska.cost,
			  col5: podpiska.paid,
			  col6: {podpiska : podpiska},
			  col7: {podpiska: podpiska},
			  podpiska: podpiska
			});
	
			
		  	setLoading(false)
	
		});
	
		setPageState(old => ({ ...old, isLoading: false, data: data.podpiskas, total: data.sany }))
		setLoading(false)
	
		setPodpiskas(rows2);
	
		setSany(data.sany);
		
		if (data.podpiskas.length == 0){
		  swal("ВНИМАНИЕ", 'НИЧЕГО НЕ НАЙДЕНО', "warning", {
			buttons: false,
			timer: 2000,
		  })
		} 
	
		
	  //(38,116,131,160,164,182,200,209,220)
	  //(48,53,76,77,83,135,138,165,188,195)
	};

	const fetchPastPodpiskasByTocka = async (of3) => {

		setPastPodpiskas([])
		setPastPodpiskas2([])
		//setPageState(old => ({ ...old, isLoading: true }))
	
		var of = 0;
		if (of3 > 0) {
		  of = (of3 - 1) * 50;
		} 
		
	
		console.log('SELECTED TOCKAID IN LOAD PODPISKAS' + selectedTockaId);
	
		const { data } = await axios.post(
		  "http://46.17.250.209:8081/get/past/podpiskas/by/tocka",{
			tockaid:selectedTockaId,
			token:token2,
			offset:of
		  }
		)
		
		setPastPodpiskas(data.podpiskas);
		setPastPodpiskas2(data.podpiskas2);
	
	
	
		var rows2 = [];
		var ind2 = 0;
		if (of3 > 0) {
		ind2 = (of3 - 1) * 50
		}
		  
	
		data.podpiskas.map((podpiska,ind) => {
			var ind3 = ind2 + 1 + ind;
			
			rows2.push({ id: podpiska.id, 
			  col1: podpiska.pod_name, 
			  col2: moment.utc(moment(podpiska.startdate).add(3,'hours')).format("DD.MM.YYYY"), 
			  col3: moment.utc(moment(podpiska.enddate).add(3,'hours')).format("DD.MM.YYYY"), 
			  col4: podpiska.cost,
			  col5: podpiska.paid,
			  col6: {podpiska : podpiska},
			  col7: {podpiska: podpiska},
			  podpiska: podpiska
			});
	
			
		  	setLoading(false)
	
		});
	
		//setPageState(old => ({ ...old, isLoading: false, data: data.podpiskas, total: data.sany }))
		setLoading(false)
	
		setPastPodpiskas(rows2);
	
		setPastSany(data.sany);
		
		if (data.podpiskas.length == 0){
		  swal("ВНИМАНИЕ", 'НИЧЕГО НЕ НАЙДЕНО', "warning", {
			buttons: false,
			timer: 2000,
		  })
		} 
	
		
	  //(38,116,131,160,164,182,200,209,220)
	  //(48,53,76,77,83,135,138,165,188,195)
	};

	const fetchProducts = async () => {
		if (admin.admin_type == 1 || admin.admin_type == 2 || admin.admin_type == 4){
		  const { data } = await axios.post(
			"http://46.17.250.209:8081/get/tockas/for/admin/new",{
			  admin_id:admin.id,
			  token:token2
			}
		  )
		  console.log(' HANT ' + data.tockas.length);
		  const products = data;
		  setTockas(products.tockas);
		  setSelectedTockaId(data.tockas[0].id);
		  console.log(' HANT2 ' + selectedTockaId);
		  // if (products.tockas.length == 1){
			setSelectedTocka(products.tockas[0]);
		  // }
		  // innertockas = products.tockas;
		  
		} 
		else {
	
		  axios.post(
			"http://46.17.250.209:8081/get/tockas/by/admin/new",{
			  admin_id:admin.id,
			  token:token2
			}
		  ).then(res=>{
			const products = res.data;
			// innertockas = products.tockas;
			setTockas(products.tockas);
			setSelectedTockaId(products.tockas[0].id);
			// setIsLoaded(true);
			  if (products.tockas.length > 0){
				setSelectedTocka(products.tockas[0]);
				// setProducts(products.tockas[0].products);
				// setOrange(products.tockas[0].orange_data);
			  } 
			  
			}
	
		  )
		 }
	
		
	  };

	const handleTockaChange = (event) => {
		// setValue(event.target.value);
		setPodpiskas([]);
		setSelectedTocka(tockas.filter((tocka)=>{return tocka.id === event.target.value})[0]);
		setSelectedTockaId(event.target.value);
	};

	const handlePodpiskaChange = (event) => {
		// setValue(event.target.value);
		// setPodpiskas([]);
		// 
		setSelectedPodpiskaId(event.target.value);
		var pod = podpiskas2.filter((tocka)=>{return tocka.id === event.target.value})[0];
		console.log('POD PODID ' + pod.podid);
		setSelectedPodpiska(podpiskas2.filter((tocka)=>{return tocka.id === event.target.value})[0]);
		setPodCost(podpiskas2.filter((tocka)=>{return tocka.id === event.target.value})[0].pod_price);
	};

	const handleCancel = () => {
		// setSelectedPromocodeId(0)
		setOpen(false)
	  };

	// STYLED ITEMS

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'center',
		elevation: 0,
		color: theme.palette.text.secondary,
	  }));

	const Item2 = styled(Paper)(({ theme }) => ({
		backgroundColor: '#501A2027',
		...theme.typography.body2,
		padding: theme.spacing(2),
		elevation: 0,
		// textAlign: 'center',
		color: theme.palette.text.secondary,
		
	  }));

	const getBackgroundColor = (color: string, mode: string) =>
		mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  	const getHoverBackgroundColor = (color: string, mode: string) =>
    	mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  	const getSelectedBackgroundColor = (color: string, mode: string) =>
    	mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  	const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
    	mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

	const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
		'& .super-app-theme--Open': {
		  backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
		  '&:hover': {
			backgroundColor: getHoverBackgroundColor(
			  theme.palette.info.main,
			  theme.palette.mode,
			),
		  },
		  '&.Mui-selected': {
			backgroundColor: getSelectedBackgroundColor(
			  theme.palette.info.main,
			  theme.palette.mode,
			),
			'&:hover': {
			  backgroundColor: getSelectedHoverBackgroundColor(
				theme.palette.info.main,
				theme.palette.mode,
			  ),
			},
		  },
		},
		'& .super-app-theme--Filled': {
		  backgroundColor: getBackgroundColor(
			theme.palette.success.main,
			theme.palette.mode,
		  ),
		  '&:hover': {
			backgroundColor: getHoverBackgroundColor(
			  theme.palette.success.main,
			  theme.palette.mode,
			),
		  },
		  '&.Mui-selected': {
			backgroundColor: getSelectedBackgroundColor(
			  theme.palette.success.main,
			  theme.palette.mode,
			),
			'&:hover': {
			  backgroundColor: getSelectedHoverBackgroundColor(
				theme.palette.success.main,
				theme.palette.mode,
			  ),
			},
		  },
		},
		'& .super-app-theme--PartiallyFilled': {
		  backgroundColor: getBackgroundColor(
			theme.palette.warning.main,
			theme.palette.mode,
		  ),
		  '&:hover': {
			backgroundColor: getHoverBackgroundColor(
			  theme.palette.warning.main,
			  theme.palette.mode,
			),
		  },
		  '&.Mui-selected': {
			backgroundColor: getSelectedBackgroundColor(
			  theme.palette.warning.main,
			  theme.palette.mode,
			),
			'&:hover': {
			  backgroundColor: getSelectedHoverBackgroundColor(
				theme.palette.warning.main,
				theme.palette.mode,
			  ),
			},
		  },
		},
		'& .super-app-theme--Rejected': {
		  backgroundColor: getBackgroundColor(
			theme.palette.error.main,
			theme.palette.mode,
		  ),
		  '&:hover': {
			backgroundColor: getHoverBackgroundColor(
			  theme.palette.error.main,
			  theme.palette.mode,
			),
		  },
		  '&.Mui-selected': {
			backgroundColor: getSelectedBackgroundColor(
			  theme.palette.error.main,
			  theme.palette.mode,
			),
			'&:hover': {
			  backgroundColor: getSelectedHoverBackgroundColor(
				theme.palette.error.main,
				theme.palette.mode,
			  ),
			},
		  },
		},
	  }));


    
	if(!token ) {
		return (<Login  />)
	  }

	  // RETURN RENDER 

	  return (
		<div>
		<Header selected = {7}/>

		{ 
			<AddEditPodpiska open={open} onClose={handleCancel} summa={100} tid={selectedTockaId} urlshka={urlshka} />
			}

		<Box sx={{ flexGrow: 1 }} margin = {2}>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}  >

				<Grid item xs={12} sm={6} md={3}  >
					<Grid item xs={12} sm={12} md={12} >
						<Item2 elevation='0' sx={{ 
							height: '100%', 
							backgroundColor: '#eafefd',
							'&:hover': {
							backgroundColor: '#eaf5fe',
							},
							}}>
							<div>
							<Typography variant="h5" color="#000">ВЫБОР ПРАЧЕЧНОЙ</Typography>
								<br/>

								<FormControl sx={{ width:'100%' }}>
								<Select
									size='small'
									value={selectedTockaId}
									onChange={handleTockaChange}
									displayEmpty
									inputProps={{ 'aria-label': 'Without label' }}
								>
									
									{
									typeof tockas != 'undefined' ?
									tockas && tockas.map(tocka=>(
															
																
																
										<MenuItem value={tocka.id}>{tocka.tocka_address}</MenuItem>
									))
									: ''
									}
									

									
								</Select>
								<FormHelperText>Выберите из выпадающего списка</FormHelperText>
								</FormControl>
								<br/><br/>
								<Stack direction='row' spacing={2}>

								<LoadingButton 
									disableElevation={true} sx={{
									width:'50%',
									backgroundColor: '#66bb6a',
									pt:1, pb:1,
									'&:hover': {
									backgroundColor: '#388e3c',
									// opacity: [0.3, 0.4, 0.3],
									},
									}}
									// onClick={}
									loading={loading}
									onClick={()=>{
									setLoading(true)
									// setTimeEditing(true)
									fetchPodpiskasByTocka(0)
									fetchPastPodpiskasByTocka(0)
									//   setTimeout(function () {
									//     setLoading(false)
									//     // onClose();
									// }, 200);

									

									}}
									loadingPosition="center"
									variant="contained"
								><span>ПОКАЗАТЬ</span>
								</LoadingButton>

								</Stack>
							</div>
						</Item2>
						{ admin.admin_type == 1 ? 
							<Item2 elevation='0' sx={{
							mt:1,
							height: '500',
							backgroundColor: '#eafefd',
							'&:hover': {
								backgroundColor: '#eaf5fe',
							},
							}}>
							<div>
							<Typography variant="h5" color="#000">ВЫБОР ПОДПИСКИ {selectedTockaId}</Typography>
								<br/>

								
								<FormControl sx={{ width:'100%' }}>
								<Select
									size='small'
									value={selectedPodpiskaId}
									onChange={handlePodpiskaChange}
									displayEmpty
									inputProps={{ 'aria-label': 'Without label' }}
								>
									
									{
									typeof podpiskas2 != 'undefined' ?
									podpiskas2 && podpiskas2.map(podpiska=>(
															
																
																
										<MenuItem value={podpiska.id}>{podpiska.pod_name + ' [ ' + podpiska.pod_price + ' ]'}</MenuItem>
									))
									: ''
									}
									

									
								</Select>
								<FormHelperText>Выберите из выпадающего списка</FormHelperText>
								</FormControl>
								<br/><br/>

								{/* <Typography variant="h5" color="#000">РАСЧËТНЫЙ ДЕНЬ</Typography> */}
								<Stack direction='row' spacing={2} sx={{mt:2}}>
          
									<Box sx={{width:'50%'}} >
									
									<TextField 
									type="number"
									
									//inputProps={{type: 'number'}}
									InputProps={{ inputProps: { min: 0, max: 31 } }}
									sx={{width:'100%'}}
									id="outlined-required"
									label="РАСЧËТНЫЙ ДЕНЬ"
									inputRef={usetimesRef}
									defaultValue={rasDay}
									// defaultValue={selectedPromocode != null ? selectedPromocode.promocode != null ? selectedPromocode.promocode.usetimes != null ? selectedPromocode.promocode.usetimes : 0 : 0 : 0}
									// defaultValue={selectedPromocode != null ? typeof selectedPromocode.col2 != 'undefined' ? selectedPromocode.col2.promocode : 0 : 0}
									onChange={(e)=>{
    									console.log(e.target.value)
										// var curDate = moment(startDate.format('YYYY-MM-DD')).set('date',e.target.value)
										// setStartDate(curDate)
										}}
									/>
									</Box>

									<Box sx={{width:'50%'}} >
									
									<TextField 
									type="number"
									
									//inputProps={{type: 'number'}}
									InputProps={{ inputProps: { min: 0, max: 31 } }}
									sx={{width:'100%'}}
									id="outlined-required"
									label="СТОИМОСТЬ"
									inputRef={costRef}
									defaultValue={podCost}
									// defaultValue={selectedPromocode != null ? selectedPromocode.promocode != null ? selectedPromocode.promocode.usetimes != null ? selectedPromocode.promocode.usetimes : 0 : 0 : 0}
									// defaultValue={selectedPromocode != null ? typeof selectedPromocode.col2 != 'undefined' ? selectedPromocode.col2.promocode : 0 : 0}
									// onChange={(e)=>{
    								// 	console.log(e.target.value)
									// 	setPodCost(e.target.value)
									// 	// var curDate = moment(startDate.format('YYYY-MM-DD')).set('date',e.target.value)
									// 	// setStartDate(curDate)
									// 	}}
									/>
									</Box>
									
								</Stack>




								<br/><br/>


								<div >
									<Typography variant="h5" color="#000">ПЕРИОД</Typography>
									
									<Stack direction='row' spacing={2}>
									<LocalizationProvider 
									dateAdapter={AdapterDayjs}
									localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
									adapterLocale={ru}
									>
										<DemoContainer components={['DatePicker', 'DatePicker']}>
										<DatePicker2
											dayOfWeekFormatter={(day) => `${day}`}
											// calendarStartDay={1}
											size="small"
											format="DD.MM.YYYY"
											label="Начало"
											value={startDate}
											inputRef = {startDateRef}
											onChange={(newValue) => {
											// //console.log('HANY@ ' + new Date());
											var nextMonth = dayjs(moment(newValue.format('YYYY-MM-DD')).add(1,'month'))
											var rasDay = moment(newValue.format('YYYY-MM-DD')).date()
											console.log('HANY@ ' + rasDay);
											setRasDay(rasDay)
											setStartDate(newValue)
											setEndDate(nextMonth)
											}}
										/>
										</DemoContainer>
									</LocalizationProvider>
									
									<LocalizationProvider
									dateAdapter={AdapterDayjs}
									// localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
									adapterLocale='ru'
									
									>
										<DemoContainer components={['DatePicker', 'DatePicker']}>
										<DatePicker2
										disabled
											dayOfWeekFormatter={(day) => `${day}`}
											format="DD.MM.YYYY"
											label="Конец"
											value={endDate}
											inputRef={endDateRef}
											onChange={(newValue) => {
											// //console.log('HANY@ ' + new Date());
											console.log('HANY' + newValue);
											//setEndDate(newValue)
											}}
										/>
										</DemoContainer>
									</LocalizationProvider>
									</Stack>
									{/* {admin.admin_type == 1 || admin.admin_type == 3 ? 
										<LoadingButton disableElevation={true} sx={{
												backgroundColor: 'primary.light',
												mt:2,
												'&:hover': {
													backgroundColor: 'primary.main',
													// opacity: [0.3, 0.4, 0.3],
												},
												}}
												// onClick={handleExportDwa}
												loading={loading}
												
												loadingPosition="center"
												variant="contained"
												><span>ЭКСПОРТ в EXCELL ПО ВСЕМ ТОЧКАМ</span>
												</LoadingButton>
									: ''} */}
                  </div>



								<br/><br/>
								<Stack direction='row' spacing={2}>

								<LoadingButton 
									disableElevation={true} sx={{
									width:'50%',
									backgroundColor: 'primary.light',
									pt:1, pb:1,
									'&:hover': {
									backgroundColor: '#388e3c',
									// opacity: [0.3, 0.4, 0.3],
									},
									}}
									// onClick={}
									loading={loading}
									onClick={()=>{
									setLoading(true)
									// setTimeEditing(true)
									//fetchPodpiskasByTocka(0)
									//   setTimeout(function () {
									//     setLoading(false)
									//     // onClose();
									// }, 200);

										addPodpiskaToTocka()
									setLoading(false)

									}}
									loadingPosition="center"
									variant="contained"
								><span>ДОБАВИТЬ</span>
								</LoadingButton>

								</Stack>
							</div>
						</Item2>
						
						
						: ''}
					</Grid>
				</Grid>
				<Grid item xs={12} sm={6} md={9} >
					<Item elevation='0' height ={500} sx={{ height: '500', backgroundColor: '#eafefd', }}>
						<Typography variant="h5" color="#000" sx={{m:1}} align='left'>ТЕКУЩИЕ ПОДПИСКИ</Typography>
						<div style={{ height: 350, width: '100%' }}>
							{/* STYLED GRID   		*/}
							<StyledDataGrid  sx={{m:1}}
								getRowClassName={(params) => `super-app-theme--${params.row.isactive == false ? 'Rejected':''}`} 
								paginationMode="server"
								disableColumnMenu
								slots={{
									loadingOverlay: LinearProgress,
								}}
								loading = {loading}
								height={500}
								disableColumnFilter

								onCellDoubleClick = {async (params, event) => {
										//console.log('DOUBLE CLICKED ' + params.row.col1 + ' ' + params.row.col2.product_name + ' params.col4.product_id '  + ' ' + params.id);
										
									// if (admin.admin_type == 1 || admin.admin_type == 4) {
									// 	setSelectedPromocode(promocodes.filter((prod)=> { return prod.id === params.id})[0])
									// 	setSelectedPromocodeId(promocodes.filter((prod)=> { return prod.id === params.id})[0].id)
									// 	setOpen(true);
									// } else {
									// 	if (typeof selectedTocka.podpiska != 'undefined' && selectedTocka.podpiska[1] == '1' && admin.admin_type != 2) {
									// 	setSelectedPromocode(promocodes.filter((prod)=> { return prod.id === params.id})[0])
									// 	setSelectedPromocodeId(promocodes.filter((prod)=> { return prod.id === params.id})[0].id)
									// 	setOpen(true);
									// 	}
									// }

									
										

									if (!event.ctrlKey) {
										event.defaultMuiPrevented = true;
									}
									}}

									pagination
									rows={podpiskas}
									{...podpiskas}
									rowCount={rowCountState}
									
									columns={columns}
									pageSize={50}
									onPageChange={(params) => {
										//console.log("===params===", params);
									}}
									rowsPerPageOptions={[50]}
									
									pageSizeOptions={[50]}
									paginationModel={paginationModel}
									// paginationMode="server"
									onPaginationModelChange={(page)=>{
									// //console.log('page ' + page.page);
									setLoading(true);
									setPaginationModel({ 'page' : page.page, pageSize: 50})
									fetchPodpiskasByTocka(page.page + 1)
									setLoading(false);
								}}

							/> 

						</div>
					</Item>

					<Item elevation='0' height ={500} sx={{ mt: 1, height: '500', backgroundColor: '#eafefd', }}>
						<Typography variant="h5" color="#000" sx={{m:1}} align='left'>ИСТОРИЯ ПОДПИСОК</Typography>
						<div style={{ height: 350, width: '100%' }}>
							{/* STYLED GRID   		*/}
							<StyledDataGrid  sx={{m:1}}
								getRowClassName={(params) => `super-app-theme--${params.row.isactive == false ? 'Rejected':''}`} 
								paginationMode="server"
								disableColumnMenu
								slots={{
									loadingOverlay: LinearProgress,
								}}
								loading = {loading}
								height={500}
								disableColumnFilter

								onCellDoubleClick = {async (params, event) => {
										//console.log('DOUBLE CLICKED ' + params.row.col1 + ' ' + params.row.col2.product_name + ' params.col4.product_id '  + ' ' + params.id);
										
									// if (admin.admin_type == 1 || admin.admin_type == 4) {
									// 	setSelectedPromocode(promocodes.filter((prod)=> { return prod.id === params.id})[0])
									// 	setSelectedPromocodeId(promocodes.filter((prod)=> { return prod.id === params.id})[0].id)
									// 	setOpen(true);
									// } else {
									// 	if (typeof selectedTocka.podpiska != 'undefined' && selectedTocka.podpiska[1] == '1' && admin.admin_type != 2) {
									// 	setSelectedPromocode(promocodes.filter((prod)=> { return prod.id === params.id})[0])
									// 	setSelectedPromocodeId(promocodes.filter((prod)=> { return prod.id === params.id})[0].id)
									// 	setOpen(true);
									// 	}
									// }

									
										

									if (!event.ctrlKey) {
										event.defaultMuiPrevented = true;
									}
									}}

									pagination
									rows={pastPodpiskas}
									{...pastPodpiskas}
									// rows={podpiskas}
									// {...podpiskas}
									// rowCount={rowCountState}
									rowCount={rowCountStatePast}
									
									columns={columns2}
									pageSize={50}
									onPageChange={(params) => {
										//console.log("===params===", params);
									}}
									rowsPerPageOptions={[50]}
									
									pageSizeOptions={[50]}
									paginationModel={paginationModel}
									// paginationMode="server"
									onPaginationModelChange={(page)=>{
									// //console.log('page ' + page.page);
									setLoading(true);
									// setPaginationModel({ 'page' : page.page, pageSize: 50})
									// fetchPodpiskasByTocka(page.page + 1)
									setLoading(false);
								}}

							/> 

						</div>
					</Item>			
				</Grid>

			</Grid>
		</Box>
		</div>)
  }
  
  export default Podpiskas;
  