import React, { useState } from 'react';
import '../../index.scss';
import  Logo  from '../../assets/stirkalogo.svg';
import {useNavigate} from 'react-router-dom';
import swal from 'sweetalert';

async function loginUser(credentials) {
 return fetch('http://46.17.250.209:8081/tocka/login', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
   
 })
   .then(data => data.json())
   .catch(err =>  {
    swal("Ошибка", "НЕТ ПОДКЛЮЧЕНИЯ К СЕРВЕРУ", "error", {
      buttons: false,
      timer: 2000,
    })
    .then((value) => {

      // navigate('/dashboard');
    });
   })
}

const Login = (setToken)=> {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const token2 = 'mesanatoken';
  const handleSubmit = async e => {
    e.preventDefault();

    
    const cevap = await loginUser({
      phone:username,
      pass:password,
      token:token2
    });
    if (cevap !== undefined){
      setToken = cevap.message;
      if (cevap.success === 1){
        
        swal("Успех", cevap.message, "success", {
          buttons: false,
          timer: 2000,
        })
        .then((value) => {
          sessionStorage.setItem('accessToken', cevap['message']);
          sessionStorage.setItem('user', JSON.stringify(cevap['admin'][0]));
          setPassword('');
          if (cevap['admin'][0].admin_type == 3){
            window.location.href = "/dashboard/status";
          } else {
            window.location.href = "/dashboard";
          }
          
        });
      } else {
        swal("Ошибка", cevap.message, "error");
      }
    }
  }

 

  return(

     <div className="login">
    <div className="loginContainer">
    
    <div className="login-wrapper">
      

    <div className='logoMain'>
                        <img src={Logo} alt=''/>
                </div>

      <h1>Вход в личный кабинет</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Номер телефона</p>
          <input type="text" onChange={e => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Пароль</p>
          <input type="password" onChange={e => setPassword(e.target.value)} />
        </label>
        <div>
        
          <button type="submit" className='loginButton' >Войти</button>
        </div>
      </form>
    </div>
    </div>
     </div>
  )
}

export default Login;
