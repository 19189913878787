import React, { useContext, useEffect } from 'react';
import { SocketContext } from './SocketProvider';
import { SocketContext2 } from './SocketProvider2';
import Header from './Header';
import Status from "./Status";
import Settings from "./Settings";
import Logs from "./Logs";
import Login from '../Login/Login';
import Tockas from "./Tockas";
import { BrowserRouter, Route, Routes as Switch, Link } from 'react-router-dom';
import useForceUpdate from 'use-force-update';






const Dashboard = (props) => {

  const socket = useContext(SocketContext);
  const socket2 = useContext(SocketContext2);
  var selectedMenu2 = 1;


  const token = sessionStorage.getItem('accessToken');
  const admin = JSON.parse(sessionStorage.getItem('user'));

  useEffect(() => {
    socket.emit("test", 'DASHBOARD'); 
    socket2.emit("test", 'DASHBOARD'); 
    if(!token ) {
      return (<Login  />)
    } else {
    socket.emit("admin_joined", admin.id);    
    socket2.emit("admin_joined", admin.id);    
  
    } 
  });
  
  const renderSwitch = (selectedMenu)=> {
  selectedMenu2 = selectedMenu;
  }
  

  if(!token ) {
    return (<Login  />)
  } else {
  } 

  return(
    <div>
    <Header renderSwitch = {renderSwitch} selectedMenu = {selectedMenu2}/>
    <h2>Dashboard</h2>
    
    
    </div>

    
    
  );
}


export default Dashboard;