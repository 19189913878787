import React, { useContext, useEffect, useState } from 'react'
import Header from './Header';
import Login from '../Login/Login';
import axios from 'axios';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import swal from 'sweetalert2';
import { SocketContext } from './SocketProvider';
import { DataGrid, GridRowsProp, GridColDef,ruRU as ruRU2 } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Grid  from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


import { ruRU } from '@mui/x-date-pickers/locales';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker as DatePicker2 } from '@mui/x-date-pickers/DatePicker';
import { DatePicker as DatePicker2 } from '@mui/x-date-pickers/DatePicker';
import ru from 'dayjs/locale/ru';
//@material-ui/pickers
// import LinearProgress from '@mui/material/LinearProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GetAppIcon from '@mui/icons-material/GetApp';

import IconButton from '@mui/material/IconButton';
import parse from 'html-react-parser';

import AddCorrectionBill from './AddCorrectionBill';

dayjs.locale('ru')


// moment.updateLocale("ru",{
//   week:{
//     dow: 1
//   }
// });

// {/* 

// <h2 className='body2'>Список продаж</h2>

// <div className='dashboard_content'>



// <div >
//   {<Knopkalar/>}
//   <div> <h2>Итого за заданный период: {sany} чеков на сумму {summa} рублей.</h2></div>
//   {/* <table className="styled-table">
//             <thead>
//                 <tr>
//                     <th></th>
//                     <th>Дата</th>
//                     <th>Услуга</th>
//                     <th>Цена</th>
//                     <th>Оплачено</th>
//                     <th>Скидка</th>
//                     <th>Тип оплаты</th>
//                     <th>Клиент</th>
//                     <th>Промокод</th>
//                     <th>Чек</th>

//                 </tr>
//             </thead>
//             <tbody>
//                 {logs && logs.map((tocka,idx) => (<tr key={tocka.id} onClick={()=>{
//                       //handleTockaClick(tocka.id);
//                     }} 
                    
//                     >
//                         <td>{idx + 1} </td>
//                         <td>{moment(tocka.sold_at).format("DD.MM.YYYY kk:mm:ss")} </td>
//                         <td>{tocka.product_name + ' № ' + tocka.product_order}</td>
//                         <td>{tocka.product_price}</td>
//                         <td>{tocka.product_paid}</td>
//                         <td>{tocka.skidka_amount}</td>
//                         <td>{tocka.payment_type == 1 ? 'наличные' : 'безналичные' }</td>
//                         <td>{tocka.cphone}</td>
//                         <td>{tocka.cpromo}</td>
//                         <td>{tocka.equaring_bill}</td>
//                     </tr>)
                    
//                 )}
//             </tbody>
//         </table> */}

//         <div style={{ height: '100%', width: '100%', background: '#fff',  flexGrow: 1 }}>
//   <DataGrid rows={rows} columns={columns} />
// </div>
// </div>
// </div> */}


export const Status = () => {


  const socket = useContext(SocketContext);
  const admin = JSON.parse(sessionStorage.getItem('user'));
  var innerlogs = [];
  const [logs, setLogs] = useState([]);
  const token2 = 'mesanatoken';
  const token = sessionStorage.getItem('accessToken');
  const [startDate, setStartDate] = useState(dayjs(moment(new Date()).format("YYYY-MM-DD")));
  const [endDate, setEndDate] = useState(dayjs(moment(new Date()).format("YYYY-MM-DD")));
  const [tockas, setTockas] = useState([]);
  const [value, setValue] = useState();
  const [selectedTockaId,setSelectedTockaId] = useState(0);
  const [selectedTocka,setSelectedTocka] = useState();
  const [promocodes,setPromocodes] = useState([]);
  const [selectedPromocodeId, setSelectedPromocodeId] = useState(0);
  const [selectedPaymentType, setSelectedPaymentType] = useState(0);
  const [isFiltered,setIsFiltered] = useState(false);
  const [offset,setOffset] = useState(0);
  const [summa,setSumma] = useState(0);
  const [summa2,setSumma2] = useState(0);
  const [summa3,setSumma3] = useState(0);
  const [sany,setSany] = useState(0);
  const [sany2,setSany2] = useState(0);
  const [loading, setLoading] = React.useState(false);
  const [maxSan, setMaxSan] = React.useState(0);
  const [maxSumma, setMaxSumma] = React.useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 50,
  });
  const [open, setOpen] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);

  const [valuta, setValuta] = useState('P');

  const XLSX = require('sheetjs-style');

  var maxsan = 0;
  var maxsum = 0;
  
  const [rowCountState, setRowCountState] = React.useState(sany);
  const [rowCountState2, setRowCountState2] = React.useState(sany2);
  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      sany !== undefined ? sany : prevRowCountState,
    );
  }, [sany, setRowCountState]);

  React.useEffect(() => {
    setRowCountState2((prevRowCountState) =>
      sany2 !== undefined ? sany : prevRowCountState,
    );
  }, [sany2, setRowCountState2]);

  const [age, setAge] = React.useState('');

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })

  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };

  const [rows, setRows] = React.useState([]);
  const [prodycty, setProducty] = React.useState([]);
  
  
<th></th>

  const columns = [
    { field: 'col1', headerName: '№', width: 10 ,sortable: false },
    { field: 'col2', headerName: 'Дата', width: 150 ,sortable: false },
    { field: 'col3', headerName: 'Услуга', width: 350 ,sortable: false },
    { field: 'col4', headerName: 'Цена', width: 80 ,sortable: false },
    { field: 'col5', headerName: 'Оплачено', width: 100 ,sortable: false },
    { field: 'col6', headerName: 'Скидка', width: 80 ,sortable: false },
    { field: 'col7', headerName: 'Тип оплаты', width: 120 ,sortable: false },
    { field: 'col8', headerName: 'Клиент', width: 120 ,sortable: false },
    { field: 'col9', headerName: 'Промокод', width: 120 ,sortable: false },
    { field: 'col10', headerName: 'Чек', width: 20 ,sortable: false , align:'center',
  
    renderCell: (params) => (
      
		params.value.status == 1 ? 
      <IconButton color="primary" component="label" onClick={()=>{
        // //console.log('BASDYM ' + params.value.billid + ' we ' + params.value.inn);

        const newWindow = window.open('https://cheques-lk.orangedata.ru/'+params.value.inn+'/'+params.value.billid, '_blank', 'noopener,noreferrer')
          if (newWindow) newWindow.opener = null
      }}>
        
        <ReceiptIcon />
      </IconButton>
	  :
	  <IconButton color="warning" component="label" onClick={()=>{
        // //console.log('BASDYM ' + params.value.billid + ' we ' + params.value.inn);

		console.log(params)
		checkOdBillStatus(params.value.billid,selectedTockaId,params.value.billtype, params.value.billdate)
		setSelectedBill(params.value.bill)

        // const newWindow = window.open('https://cheques-lk.orangedata.ru/'+params.value.inn+'/'+params.value.billid, '_blank', 'noopener,noreferrer')
        //   if (newWindow) newWindow.opener = null
      }}>
        
        <ReceiptIcon />
      </IconButton>
    ),

  },
  ];

  const normalise = (value) => {
    var a = (value * 100);
    // //console.log('A ' + a);
    // //console.log('MAX SAN ' + maxSan);
    var b = (parseInt(maxSan) + 20);
    // //console.log('B ' + b);
    var c = a / b;
    // //console.log('C ' + c);

    var value2 = c //* 2.40;
    // //console.log('NORMOLIZE FOR ' + value + ' = ' + value2);
    return value2
  }

  const normalise2 = (value) => {
    var value2 = ((value * 100) / (parseInt(maxSumma) + 1000)) //*2.40
    // //console.log('NORMOLIZE FOR ' + value + ' = ' + value2);
    return value2
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#fff',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#66bb6a', //'#1a90ff' : '#308fe8'
    },
  }));

  const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#fff',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#ffa726', //'#1a90ff' : '#308fe8'
    },
  }));

  const columns2 = [
    { field: 'col1', headerName: 'Машинка / Услуга', width: 200 ,sortable: false },
    {
      field: 'col2',
      headerName: 'Запуски / На сумму',
      renderCell: (params) => (
        <strong>
         
          {/* { params.value.sany + ' запусков / на ' + params.value.summa + ' рублей'} */}
          <BorderLinearProgress 
          variant="determinate" 
          value={normalise(params.value.sany)} sx={{
            width: 280,
            height: 10,
            // borderRadius:5,
            // barColor: '#1a90ff',
            // backgroundColor : '#fff'
          }}/>

          <BorderLinearProgress2
          variant="determinate" 
          
          value={normalise2(params.value.summa)} sx={{
            mt:1,
                      width: 280,
                      height: 10,
                      // borderRadius:5,
                      // barColor: '#308fe8',
                      // backgroundColor : '#fff'
                    }}/>
        </strong>
      ),
      width: 300,
      height: 100
    },
    { field: 'col3', headerName: 'Запуски', width: 80 ,sortable: false, textAlign:'center'},
    { field: 'col4', headerName: 'На сумму', width: 80 ,sortable: false }
  ];


  const handleChange = (event) => {
    setValue(event.target.value);
    setSelectedTockaId(event.target.value);
	
	var id = event.target.value;
	const filtered = tockas.filter(tocka => {
		return tocka.id === id;
	  });
	setSelectedTocka(filtered)
	setPromocodes(filtered[0].promocodes)
	setValuta(parse(filtered[0].currencysign))
  };

  const handlePaymentTypeChange = (event) => {
    setValue(event.target.value);
    setSelectedPaymentType(event.target.value);
    // //console.log('SELECTED ' + event.target.value);
  };

  const handlePromocodeChange = (event) =>{
	setSelectedPromocodeId(event.target.value)
  }


  const handleFilterClicked = (event) =>{
    setIsFiltered(true);
    setOffset(0);
    setPaginationModel({'page':0,'pageSize':50});
    fetchSalesByDate2(0,selectedPaymentType);
    fetchRunsByDate(0,selectedPaymentType);
    // setPageState(old => ({ ...old, page: 1, pageSize: 50 }))
    // fetchData();
    // setLoading(true)
  }

  const handleExport = async ()=>{


    setLoading(true)

    const of = -1;

    // //console.log('OFFSET ');
    // //console.log('srtart date ' + dayjs(startDate).format("YYYY-MM-DD"));
    // //console.log('enddate date ' + dayjs(endDate).format("YYYY-MM-DD"));

    const { data } = await axios.post(
      "http://46.17.250.209:8081/get/sales/by/tocka/date/ptype/all",{
        tockaid:selectedTockaId,
        token:token2,
        startdate:dayjs(startDate).format("YYYY-MM-DD"),
        enddate:dayjs(endDate).format("YYYY-MM-DD"),
        offset:of,
        ptype: selectedPaymentType,
		promocodeid:selectedPromocodeId
      }
    )
    


    const wb = XLSX.utils.book_new();

    // //console.log('SALES GELENEDE ' + data.sales.length);

    var rowscells = []
    
    data.sales.map((row,index)=>{
      var ind = 9 + index
      var b = 'B' + ind
      rowscells.push(b)

      var c = 'C' + ind
      rowscells.push(c)

      var d = 'D' + ind
      rowscells.push(d)

      var e = 'E' + ind
      rowscells.push(e)
	  
	  var f = 'F' + ind
      rowscells.push(f)
    })

      var ind = 9 + data.sales.length
      var b = 'B' + ind
      rowscells.push(b)

      var c = 'C' + ind
      rowscells.push(c)

      var d = 'D' + ind
      rowscells.push(d)

      var e = 'E' + ind
      rowscells.push(e)

	  var f = 'F' + ind
      rowscells.push(f)

    // //console.log('ROWS CELLS ' + rowscells.length);

    var wscols = [
      { width: 10 },  // first column
      { width: 10 }, // second column
      { width: 20 }, //...
      { width: 20 },
      { width: 40 },
	  { width: 10 }
    ];

    

    const rows = data.sales.map(row => ({
      'Оплачено в рублях': row.product_paid,
	  'Тип оплаты': row.payment_type == 1 ? 'Нал.' : 'Безнал.',
      'Дата и время': moment(row.sold_at).format("DD.MM.YYYY kk:mm:ss"),
      'Услуга': row.product_name,
      '№ машинки': row.product_order,
    }));

    const ws = XLSX.utils.json_to_sheet(rows, {
      // header: ['0', '1', '2'],
       origin: 'B9' 
    },
    
    );

    XLSX.utils.sheet_add_aoa(ws, [['начало']], {origin: 'B1'});
    XLSX.utils.sheet_add_aoa(ws, [['конец']], {origin: 'C1'});
    XLSX.utils.sheet_add_aoa(ws, [[dayjs(startDate).format("DD.MM.YYYY")]], {origin: 'B2'});
    XLSX.utils.sheet_add_aoa(ws, [[dayjs(endDate).format("DD.MM.YYYY")]], {origin: 'C2'});
    XLSX.utils.sheet_add_aoa(ws, [['Итого']], {origin: 'C3'});
    XLSX.utils.sheet_add_aoa(ws, [['Сумма']], {origin: 'B4'});
    XLSX.utils.sheet_add_aoa(ws, [['Чеков']], {origin: 'B5'});
    //data.cost
    XLSX.utils.sheet_add_aoa(ws, [[data.cost]], {origin: 'C4'});
    XLSX.utils.sheet_add_aoa(ws, [[data.sany]], {origin: 'C5'});

    const merge = [
      { s: { r: 6, c: 1 }, e: { r: 6, c: 4 } }
    ];
    ws["!merges"] = merge;

    const filtered = tockas.filter(tocka => {
      return tocka.id === selectedTockaId;
    });

    // //console.log('SAYLANAN TOCKA ' + filtered[0].tocka_address);

    XLSX.utils.sheet_add_aoa(ws, [[filtered[0].tocka_address]], {origin: 'B7'});

    const cells = ['B1','C1','C3','B4','B5','B7','C4','C5']

    

    rowscells.map((cell)=>{
      //console.log('SAYLANMALY CELL ' + cell);
      ws[cell].s = { // set the style for target cell
        font: {
          // name: '宋体',
          // sz: 24,
          // bold: true,
            
          // color: {
          //   rgb: "FFFFAA00"
          // }
        },
        border: {
          top: {style: 'thin', color: {auto: 1}},
          bottom: {style: 'thin', color: {auto: 1}},
          left: {style: 'thin', color: {auto: 1}},
          right: {style: 'thin', color: {auto: 1}},
        },
        alignment: {
          vertical: 'center',
          horizontal: 'center',
          wrapText: true, // any truthy value here
        },
      };
    })



    cells.map((cell)=>{
      ws[cell].s = { // set the style for target cell
        font: {
          // name: '宋体',
          // sz: 24,
          bold: true,
            
          // color: {
          //   rgb: "FFFFAA00"
          // }
        },
        alignment: {
          vertical: 'center',
          horizontal: 'center',
          wrapText: true, // any truthy value here
        },
      };
    })

    ws["!cols"] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, 'sheet1'); // add worksheet to workbook

    XLSX.writeFile(wb, 'report_'+dayjs(startDate).format("DD.MM.YYYY")+'_'+dayjs(endDate).format("DD.MM.YYYY")+'.xlsx', { compression: true }); // write workbook
    // XLSX.writeFile(workbook, "Presidents.xlsx", { compression: true });
    setLoading(false)

  }

  const handleExportDwa = async ()=>{


    setLoading(true)

    const of = -1;

    // //console.log('OFFSET ');
    console.log('srtart date ' + dayjs(startDate).format("YYYY-MM-DD"));
    console.log('enddate date ' + dayjs(endDate).format("YYYY-MM-DD"));

    const { data } = await axios.post(
      "http://46.17.250.209:8081/get/report/for/all/tockas",{
        token:token2,
        begdate:dayjs(startDate).format("YYYY-MM-DD"),
        endate:dayjs(endDate).format("YYYY-MM-DD")
      }
    )
    


    const wb = XLSX.utils.book_new();

    console.log('SALES GELENEDE ' + data.report.length);

    var rowscells = []
    
    data.report.map((row,index)=>{
      var ind = 9 + index
      var b = 'B' + ind
      rowscells.push(b)

      var c = 'C' + ind
      rowscells.push(c)

      var d = 'D' + ind
      rowscells.push(d)

      var e = 'E' + ind
      rowscells.push(e)

	  var f = 'F' + ind
      rowscells.push(f)
    })

      var ind = 9 + data.report.length
      var b = 'B' + ind
      rowscells.push(b)

      var c = 'C' + ind
      rowscells.push(c)

      var d = 'D' + ind
      rowscells.push(d)

      var e = 'E' + ind
      rowscells.push(e)

	  var f = 'F' + ind
      rowscells.push(f)

    console.log('ROWS CELLS ' + rowscells.length);

    var wscols = [
      { width: 10 },  // first column
      { width: 50 }, // second column
      { width: 20 }, //...
      { width: 20 },
      { width: 20 },
	  { width: 20 }
    ];

    

    const rows = data.report.map(row => ({
      'Прачечная': row.tocka_name,
      'Продажи (на сумму)': row.summa == null ? 0 : row.summa,
      'Продажи (кол.)': row.sany,
      'Скидки (на сумму)': row.skidka == null ? 0 : row.skidka,
	  'Скидки (кол.)': row.skidkasany,
    }));

    const ws = XLSX.utils.json_to_sheet(rows, {
      // header: ['0', '1', '2'],
       origin: 'B9' 
    },
    
    );

    XLSX.utils.sheet_add_aoa(ws, [['начало']], {origin: 'B1'});
    XLSX.utils.sheet_add_aoa(ws, [['конец']], {origin: 'C1'});
    XLSX.utils.sheet_add_aoa(ws, [[dayjs(startDate).format("DD.MM.YYYY")]], {origin: 'B2'});
    XLSX.utils.sheet_add_aoa(ws, [[dayjs(endDate).format("DD.MM.YYYY")]], {origin: 'C2'});
    //data.cost
    // XLSX.utils.sheet_add_aoa(ws, [[data.cost]], {origin: 'C4'});
    // XLSX.utils.sheet_add_aoa(ws, [[data.sany]], {origin: 'C5'});

    const merge = [
      { s: { r: 6, c: 1 }, e: { r: 6, c: 4 } }
    ];
    ws["!merges"] = merge;


    // //console.log('SAYLANAN TOCKA ' + filtered[0].tocka_address);

	var imya = 'Общие суммы по всем прачечным'
    XLSX.utils.sheet_add_aoa(ws, [[imya]], {origin: 'B7'});

    const cells = ['B1','C1','C3','B4','B5','B7','C4','C5']

    

    rowscells.map((cell)=>{
      //console.log('SAYLANMALY CELL ' + cell);
      ws[cell].s = { // set the style for target cell
        font: {
          // name: '宋体',
          // sz: 24,
          // bold: true,
            
          // color: {
          //   rgb: "FFFFAA00"
          // }
        },
        border: {
          top: {style: 'thin', color: {auto: 1}},
          bottom: {style: 'thin', color: {auto: 1}},
          left: {style: 'thin', color: {auto: 1}},
          right: {style: 'thin', color: {auto: 1}},
        },
        alignment: {
          vertical: 'center',
          horizontal: 'center',
          wrapText: true, // any truthy value here
        },
      };
    })



    // cells.map((cell)=>{
    //   ws[cell].s = { // set the style for target cell
    //     font: {
    //       // name: '宋体',
    //       // sz: 24,
    //       bold: true,
            
    //       // color: {
    //       //   rgb: "FFFFAA00"
    //       // }
    //     },
    //     alignment: {
    //       vertical: 'center',
    //       horizontal: 'center',
    //       wrapText: true, // any truthy value here
    //     },
    //   };
    // })

    ws["!cols"] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, 'sheet1'); // add worksheet to workbook

    XLSX.writeFile(wb, 'report_all_'+dayjs(startDate).format("DD.MM.YYYY")+'_'+dayjs(endDate).format("DD.MM.YYYY")+'.xlsx', { compression: true }); // write workbook
    // XLSX.writeFile(workbook, "Presidents.xlsx", { compression: true });
    setLoading(false)

  }

  const handleCloseFilter = async ()=>{

    // //console.log('CLOSEFILTER1');
    setRows([]);
    setStartDate(dayjs(moment(new Date()).format("YYYY-MM-DD")));
    // //console.log('CLOSEFILTER2');
    setEndDate(dayjs(moment(new Date()).format("YYYY-MM-DD")));
    // //console.log('CLOSEFILTER3');
    setIsFiltered(false);
    setOffset(0);
    setSumma(0);
	setSumma2(0);
    setSany(0);setSumma3(0);
    setSelectedPaymentType(0);
  }

  useEffect(() => {
    fetchTockas();
    socket.emit("test", 'STATUS'); 
  },[]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     //console.log('ON ' + selectedPaymentType + ' - ' + pageState.page + ' - ' + selectedTockaId + ' - ' + dayjs(startDate).format("YYYY-MM-DD"))
  //     setPageState(old => ({ ...old, isLoading: true }))
  //     axios.post(
  //       "http://46.17.250.209:8081/get/sales/by/tocka/date/ptype",{
  //         tockaid:selectedTockaId,
  //         token:'mesanatoken',
  //         startdate:dayjs(startDate).format("YYYY-MM-DD"),
  //         enddate:dayjs(endDate).format("YYYY-MM-DD"),
  //         offset: pageState.page * 50,
  //         ptype: selectedPaymentType
  //       }
  //     ).then((cevap)=>{
  //       var rows2 = []
  //       //console.log('RESPONSE ' + cevap.data);  
  //       cevap.sales.map((tocka,ind) => {
  //         ind += 1;
  //         // //console.log('IND ' + ind );
  //         rows2.push({ id: tocka.id, 
  //           col1: ind, 
  //           col2: moment(tocka.sold_at).format("DD.MM.YYYY kk:mm:ss"), 
  //           col3: tocka.product_name + ' № ' + tocka.product_order, 
  //           col4: tocka.product_price,  
  //           col5: tocka.product_paid, 
  //           col6: tocka.skidka_amount,  
  //           col7: tocka.payment_type == 1 ? 'наличные' : 'безналичные', 
  //           col8: tocka.cphone, 
  //           col9: tocka.cpromo, 
  //           col10: tocka.equaring_bill });
    
            
    
  //       })

  //       setPageState(old => ({ ...old, isLoading: false, data: rows2, total: cevap.sany }))
  //     })

      
  //     // //console.log('RESPONSE ' + response.sany);

      
  //     // const json = await response.json()
      
  //   }
  //   fetchData()
  // }, [pageState.page, pageState.pageSize])

  const fetchSalesByDate = async (of3) => {
    
      setPageState(old => ({ ...old, isLoading: true }))
      var of = of3 * 50;
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/sales/by/tocka/date",{
          tockaid:selectedTockaId,
          token:token2,
          startdate:moment(startDate).format("YYYY-MM-DD"),
          enddate:moment(endDate).format("YYYY-MM-DD"),
          offset:pageState.page
        }
      )

      setPageState(old => ({ ...old, isLoading: false, data: data.sales, total: data.sany }))
      
      // setLogs(data.sales);
      // setSumma(data.cost);
      // setSany(data.sany);
      if (data.sales.length == 0){
        swal.fire("ВНИМАНИЕ", 'NO MORE ITEMS ', "warning", {
          buttons: false,
          timer: 2000,
        })
        .then((value) => {
          var of2 = of3 - 1;
          setOffset(of);
        });
      } else {
      }

      
    
  };

  const checkOdBillStatus = async (billid,tockaid,billtype, billdate) =>{

	console.log('BILLTYPE ' + billtype + ' billdate ' + billdate + ' TODAY ' + moment.utc().format("DD.MM.YYYY kk:mm:ss"));

	if (billtype == 0) { //obychniy check else correction check
		setLoading(true)
		const { data } = await axios.post(
			"http://46.17.250.209:8081/stirka/check/od/bill/status",{
			tid: tockaid,
			token: token2,
			billid: billid
		  }
		)
	
		if (data.status == 0) {
			console.log('BILL NOT PROBITED ' + moment(billdate,'DD.MM.YYYY kk:mm:ss').format('YYYY-MM-DD') + ' we ' + moment().format('YYYY-MM-DD'));
			//compare if date today equal to billdate
			var dOne = moment(billdate,'DD.MM.YYYY kk:mm:ss').format('YYYY-MM-DD');
			var dDwa = moment().format('YYYY-MM-DD');
			console.log('HANY ' + moment(dOne).isSame(dDwa));
			console.log(dOne + ' ' + dDwa);
			if (moment(dOne).isSame(dDwa)) {
				console.log('CHECK PROBILI SEGODNYA MOJNO PROBIT');

				swal.fire({
					title: 'ВНИМАНИЕ!',
					text: "СМЕНА ЗА СЕГОДНЯ ЕЩЕ НЕ ЗАКРЫТА МОЖНО ПРОБИТЬ ЧЕК СЕГОДНЯШНИМ ЧИСЛОМ!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					showDenyButton: true,
					denyButtonText: 'Пробить чек коррекции',
					denyButtonColor: '#66bb6a',
					cancelButtonText: 'Отмена',
					confirmButtonText: 'Да, пробить чек сегодняшним числом!'
				  })
				  .then((value) => {
					if (value.isConfirmed) {
						//probit obycniy cek
						console.log('PROBIT OBICNIY CEK');
					} else if (value.isDenied) {
						//probit cek korreksii
						console.log('KORREKSIYA');
					} else {
						console.log('OTMENA');
					}
				  });


			} else {
				console.log('CHECK PROBILI RANEYE NUJEN DOKUMENT DLYA PROBITIYA KORREKSIONNOGO CEKA');
				//show AddKorreksiyaCek
				setOpen(true)
			}

		} else {
			console.log('BILL PROBITED MUST UPDATE COLOR');
		}
		setLoading(false)
	} else {
		
	}

	
  }

  const fetchSalesByDate2 = async (of3,ptype) => {

    setRows([])
    setLoading(true)
    setPageState(old => ({ ...old, isLoading: true }))

    var of = 0;
    if (of3 > 0) {
      of = (of3 - 1) * 50;
    } 

    const { data } = await axios.post(
      "http://46.17.250.209:8081/get/sales/by/tocka/date/ptype",{
        tockaid:selectedTockaId,
        token:token2,
        startdate:dayjs(startDate).format("YYYY-MM-DD"),
        enddate:dayjs(endDate).format("YYYY-MM-DD"),
        offset:of,
        ptype: ptype,
		promocodeid:selectedPromocodeId
      }
    )
    
    setLogs(data.sales);


    //{ id: 1, col1: 'Hello', col2: 'World' , col3: 'Hello', col4: 'World',  col5: 'Hello', col6: 'World',  col7: 'Hello', col8: 'World', col9: 'Hello', col10: 'World' },
    /**
     * moment(tocka.sold_at).format("DD.MM.YYYY kk:mm:ss")} </td>
//                         <td>{tocka.product_name + ' № ' + tocka.product_order}</td>
//                         <td>{tocka.product_price}</td>
//                         <td>{tocka.product_paid}</td>
//                         <td>{tocka.skidka_amount}</td>
//                         <td>{tocka.payment_type == 1 ? 'наличные' : 'безналичные' }</td>
//                         <td>{tocka.cphone}</td>
//                         <td>{tocka.cpromo}</td>
//                         <td>{tocka.equaring_bill}</td>
     * 
     */

      var rows2 = [];
      var ind2 = 0;
      if (of3 > 0) {
        ind2 = (of3 - 1) * 50
      }
      
      // //console.log('LOGS COUNT ' + data.sales.length + ' IND ' + ind2);
      

    data.sales.map((tocka,ind) => {
      var ind3 = ind2 + 1 + ind;
      // //console.log('DATETIME ' + new Date(Date.UTC(moment(tocka.sold_at).year, moment(tocka.sold_at).month, moment(tocka.sold_at).day, moment(tocka.sold_at).hours, moment(tocka.sold_at).minutes, moment(tocka.sold_at).seconds)))
      
      var sagat = moment(tocka.sold_at).add(3,'hours')

      // //console.log('IND ' + ind );
      rows2.push({ id: tocka.id, 
        col1: ind3, 
        //moment.utc([2011, 10, 8, 5]).format();
        col2: moment.utc(sagat).format("DD.MM.YYYY kk:mm:ss"), 
        //col2: new Date(Date.UTC(moment(tocka.sold_at).year, moment(tocka.sold_at).month, moment(tocka.sold_at).day, moment(tocka.sold_at).hours, moment(tocka.sold_at).minutes, moment(tocka.sold_at).seconds)),
        col3: tocka.product_name + ' № ' + tocka.product_order, 
        col4: tocka.product_price,  
        col5: tocka.product_paid, 
        col6: tocka.skidka_amount,  
        col7: tocka.payment_type == 1 ? 'наличные' : 'безналичные', 
        col8: tocka.cphone, 
        col9: tocka.cpromo, 
        col10: {'billid': tocka.billid, 'inn': tocka.inn, 'status': tocka.status, 'billtype': tocka.billtype, 'billdate':moment.utc(sagat).format("DD.MM.YYYY kk:mm:ss"), 'bill':tocka}
      });

        
      

    }
    )

    setPageState(old => ({ ...old, isLoading: false, data: data.sales, total: data.sany }))
    setLoading(false)

    setRows(rows2);

    // //console.log('SET ROWS ISHLEMELI');
    //setRows(rows2);
    //setRows([]);
    // setRows([...rows, {rows2}]);
    

    setSumma(data.cost);
	setSumma2(data.cost2);
	setSumma3(data.cost3);
    setSany(data.sany);
    
    if (data.sales.length == 0){
      swal.fire("ВНИМАНИЕ", 'NO MORE ITEMS ', "warning", {
        buttons: false,
        timer: 2000,
      })
      .then((value) => {
        var of2 = of3 - 1;
        setOffset(of);
      });
    } else {
    }

    
  
};

const fetchRunsByDate = async (of3,ptype) => {
  var of = 0;
  if (of3 > 0) {
    of = (of3 - 1) * 50;
  } 
  
  setProducty([]);
  // setRows([]);

  // //console.log('OFFSET ' + of3);
  // //console.log('srtart date ' + dayjs(startDate).format("YYYY-MM-DD"));
  // //console.log('enddate date ' + dayjs(endDate).format("YYYY-MM-DD"));

  const { data } = await axios.post(
    "http://46.17.250.209:8081/get/sales/by/tocka/date/ptype/dwa",{
      tockaid:selectedTockaId,
      token:token2,
      startdate:dayjs(startDate).format("YYYY-MM-DD"),
      enddate:dayjs(endDate).format("YYYY-MM-DD"),
      offset:of,
      ptype: ptype,
	  promocodeid: selectedPromocodeId
    }
  )
  
  //setProducty(data.byproducts);

  var rows2 = [];
    var ind2 = 0;
    if (of3 > 0) {
      ind2 = (of3 - 1) * 50
    }
    
    // //console.log('LOGS COUNT222 ' + data.byproducts.length + ' IND ' + ind2);
    
    var maxSany = 0;
    var maxSummasy = 0;

  data.byproducts.map((tocka,ind) => {
    
    if (maxSany < parseInt(tocka.sany)) {
      // //console.log('HANY ');
      maxSany = tocka.sany;
    }

    if (maxSummasy < tocka.summa) {
      maxSummasy = tocka.summa;
    }

    

    rows2.push({ id: tocka.id, 
      col1: tocka.product_name3, 
      col2: {'sany':tocka.sany, 'summa':tocka.summa}, 
      col3: tocka.sany, 
      col4: tocka.summa
    });

  }
  )

  // //console.log('MAXSANY ' + maxSany + ' MAXSUMMA ' + maxSummasy);
  setMaxSan(maxSany);
  setMaxSumma(maxSummasy);
  // //console.log('MAXSANY ' + maxsan + ' MAXSUMMA ' + maxsum);



  setProducty(rows2);

  //console.log('SET ROWS ISHLEMELI2');
  //setRows(rows2);
  //setRows([]);
  // setRows([...rows, {rows2}]);
  

};

var sumToBePaid = 200
var sumPaid = 300
var sumSkidka = 500

const pinnedRows = {bottom:[{ 
	
	col1: 0, 
	col2: '', 
	col3: '', 
	col4: sumToBePaid,  
	col5: sumPaid, 
	col6: sumSkidka,  
	col7: '', 
	col8: '', 
	col9: '', 
	col10: '' }]};

	



const fetchSalesByDate3 = async (of3,ptype) => {
    
  var of = of3 * 50;
  // setRows([]);

  // //console.log('srtart date ' + dayjs(startDate).format("YYYY-MM-DD"));
  // //console.log('enddate date ' + dayjs(endDate).format("YYYY-MM-DD"));

  const { data } = await axios.post(
    "http://46.17.250.209:8081/get/sales/by/tocka/date/ptype",{
      tockaid:selectedTockaId,
      token:token2,
      startdate:dayjs(startDate).format("YYYY-MM-DD"),
      enddate:dayjs(endDate).format("YYYY-MM-DD"),
      offset:of,
      ptype: ptype
    }
  )
  
  setLogs(data.sales);

  //{ id: 1, col1: 'Hello', col2: 'World' , col3: 'Hello', col4: 'World',  col5: 'Hello', col6: 'World',  col7: 'Hello', col8: 'World', col9: 'Hello', col10: 'World' },
  /**
   * moment(tocka.sold_at).format("DD.MM.YYYY kk:mm:ss")} </td>
//                         <td>{tocka.product_name + ' № ' + tocka.product_order}</td>
//                         <td>{tocka.product_price}</td>
//                         <td>{tocka.product_paid}</td>
//                         <td>{tocka.skidka_amount}</td>
//                         <td>{tocka.payment_type == 1 ? 'наличные' : 'безналичные' }</td>
//                         <td>{tocka.cphone}</td>
//                         <td>{tocka.cpromo}</td>
//                         <td>{tocka.equaring_bill}</td>
   * 
   */

    var rows2 = [];

    // //console.log('LOGS COUNT ' + data.sales.length);

  data.sales.map((tocka,ind) => {
    ind += 1;
    // //console.log('IND ' + ind );
    rows2.push({ id: tocka.id, 
      col1: ind, 
      col2: moment(tocka.sold_at).format("DD.MM.YYYY kk:mm:ss"), 
      col3: tocka.product_name + ' № ' + tocka.product_order, 
      col4: tocka.product_price,  
      col5: tocka.product_paid, 
      col6: tocka.skidka_amount,  
      col7: tocka.payment_type == 1 ? 'наличные' : 'безналичные', 
      col8: tocka.cphone, 
      col9: tocka.cpromo, 
      col10: tocka.equaring_bill });

      

  }
  )



  setRows(rows2);

  //console.log('SET ROWS ISHLEMELI3');
  //setRows(rows2);
  //setRows([]);
  // setRows([...rows, {rows2}]);
  

  setSumma(data.cost);
  setSumma2(data.cost2);
  setSany(data.sany);setSumma3(data.cost3);
  setLoading(false);
  if (data.sales.length == 0){
    swal.fire("ВНИМАНИЕ", 'NO MORE ITEMS ', "warning", {
      buttons: false,
      timer: 2000,
    })
    .then((value) => {
      var of2 = of3 - 1;
      setOffset(of);
    });
  } else {
  }

  

};

  const fetchTockas = async () => {

    if (admin.admin_type == 1 || admin.admin_type == 3){
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/tockas/for/admin",{
          admin_id:admin.id,
          token:token2
        }
      )
      
      setTockas(data.tockas);
      
      setSelectedTockaId(data.tockas[0].id);
	  setSelectedTocka(data.tockas[0]);
	  setPromocodes(data.tockas[0].promocodes);
	  setValuta(parse(data.tockas[0].currencysign))
      
    } else if (admin.admin_type == 6) {
		const { data } = await axios.post(
			"http://46.17.250.209:8081/get/tockas/for/sub/admin/new",{
			  admin_id:admin.id,
			  token:token2
			}
		  )
		  
		  setTockas(data.tockas);
		  
		  setSelectedTockaId(data.tockas[0].id);
		  setSelectedTocka(data.tockas[0]);
		  setPromocodes(data.tockas[0].promocodes);
		  setValuta(parse(data.tockas[0].currencysign))
	}
	 else {
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/tockas/by/admin/new",{
          admin_id:admin.id,
          token:token2
        }
      )
      var tockas = data.tockas;
      setTockas(tockas);
      setSelectedTocka(data.tockas[0]);
	  setPromocodes(data.tockas[0].promocodes);
      setSelectedTockaId(tockas[0].id);
	  setValuta(parse(data.tockas[0].currencysign))
    }

    
  };

  const moveNext = (of) => {
    var of = offset+1;
    setOffset(of);
    fetchSalesByDate2(of,selectedPaymentType);
  }

  const moveBack = (of) => {
    var of = offset - 1;
    setOffset(of);
    fetchSalesByDate2(of,selectedPaymentType);
  }

  const handleCancel = () => {
	// setSelectedPromocodeId(0)
	handleFilterClicked()
	setOpen(false)
  };

  const fetchLogs = async () => {
    const { data } = await axios.post(
      "http://46.17.250.209:8081/get/logs/by/tocka/admin", {
        admin_id:admin.id,
        token:token2
      }
    )
    
    const products = data;
    
    setLogs(products.logs);
    innerlogs = products.logs;
  };

  const Knopkalar = () =>{
    
      if (isFiltered){
        if (offset > 0){
          return (<div><button className='prosto' onClick={()=>{
            moveBack(offset - 1);
          }}>пред.</button><button className='prosto' onClick={()=>{
            moveNext(offset + 1);
          }}>след.</button></div>);
        } else {
          return (<button className='prosto' onClick={()=>{
            moveNext(offset + 1);
          }}>след.</button>);
        }
      } else {
        return <div></div>;
      }
    
    return null;
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    elevation: 0,
    color: theme.palette.text.secondary,
  }));

  const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: '#501A2027',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    elevation: 0,
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    
  }));

//   const Knoka = styled(LoadingButton)(({ theme }) => ({
// 	disableElevation={true} 
// 		sx={{
// 		backgroundColor: 'primary.light',
		
// 		'&:hover': {
// 		  backgroundColor: 'primary.main',
// 		  // opacity: [0.3, 0.4, 0.3],
// 		},
// 	  }}
// 		onClick={handleExport}
// 		loading={loading}
		
// 		loadingPosition="center"
// 		variant="contained"
// 	  ><span>ЭКСПОРТ в EXCELL</span>
// }))
  

  
  if(!token ) {
    return (<Login  />)
  }

  return (
    <div>
	{null}
    <Header selected={1} />
    <h2 className='body2'>Фильтр</h2>
	{ 

		//summa, billid, billid2,ptype,billdate tid={selectedTockaId}
			<AddCorrectionBill open={open} onClose={handleCancel} bill={selectedBill} />
			}

    <Box sx={{ flexGrow: 1 }} margin = {2}>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}  >
        <Grid item xs={12}>
          
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >

          <Grid item xs={12} sm={6} md={3} >
            <Item2 elevation='0' sx={{
        
            height: '100%',
            backgroundColor: '#eafefd',
            
            variant: 'outlined',
            // opacity: [0.3, 0.4, 0.3],
            '&:hover': {
              backgroundColor: '#eaf5fe',
              // opacity: [0.3, 0.4, 0.3],
            },
          }}>
          
            <div >
            <Typography variant="h5" color="#000">ПЕРИОД</Typography>
            <br/>
            <Stack direction='row' spacing={2}>
            <LocalizationProvider 
            dateAdapter={AdapterDayjs}
            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
            adapterLocale={ru}
            >
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker2
                    dayOfWeekFormatter={(day) => `${day}`}
                    // calendarStartDay={1}
                    size="small"
                    format="DD.MM.YYYY"
                    label="Начало"
                    value={startDate}
                    onChange={(newValue) => {
                      // //console.log('HANY@ ' + new Date());
                      // //console.log('HANY' + newValue);
                      setStartDate(newValue)
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              
              <LocalizationProvider
              dateAdapter={AdapterDayjs}
              // localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
              adapterLocale='ru'
              
              >
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker2
                  dayOfWeekFormatter={(day) => `${day}`}
                    format="DD.MM.YYYY"
                    label="Конец"
                    value={endDate}
                    onChange={(newValue) => {
                      // //console.log('HANY@ ' + new Date());
                      // //console.log('HANY' + newValue);
                      setEndDate(newValue)
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Stack>
			{admin.admin_type == 1 || admin.admin_type == 3 ? 
				<LoadingButton disableElevation={true} sx={{
                          backgroundColor: 'primary.light',
                          mt:2,
                          '&:hover': {
                            backgroundColor: 'primary.main',
                            // opacity: [0.3, 0.4, 0.3],
                          },
                        }}
                          onClick={handleExportDwa}
                          loading={loading}
                          
                          loadingPosition="center"
                          variant="contained"
                        ><span>ЭКСПОРТ в EXCELL ПО ВСЕМ ТОЧКАМ</span>
                        </LoadingButton>
			: ''}
                  </div>
            </Item2>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item2 elevation='0' sx={{
        
        height: '100%',
        backgroundColor: '#eafefd',
        // opacity: [0.3, 0.4, 0.3],
        '&:hover': {
          backgroundColor: '#eaf5fe',
          // opacity: [0.3, 0.4, 0.3],
        },
      }}>
            <div>
            <Typography variant="h5" color="#000">ВЫБОР ПРАЧЕЧНОЙ</Typography>
                    <br/>

                    <FormControl sx={{ width:'100%' }}>
                      <Select
                        size='small'
                        value={selectedTockaId}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        
                        {
                          tockas && tockas.map(tocka=>(
                                                  
                                                    
                                                    
                            <MenuItem value={tocka.id}>{tocka.tocka_address}</MenuItem>
                          ))
                        }
                        

                        
                      </Select>
                      <FormHelperText>Выберите из выпадающего списка</FormHelperText>
                    </FormControl>
                      <br/><br/>
                      <Stack spacing={2} direction="row">
                      <LoadingButton disableElevation={true} sx={{
                          backgroundColor: 'success.light',
                          
                          '&:hover': {
                            backgroundColor: 'success.main',
                            // opacity: [0.3, 0.4, 0.3],
                          },
                        }}
                          onClick={handleFilterClicked}
                          loading={pageState.isLoading}
                          
                          loadingPosition="center"
                          variant="contained"
                        ><span>ПОКАЗАТЬ</span></LoadingButton>

                        <LoadingButton disableElevation={true} sx={{
                          backgroundColor: 'warning.light',
                          
                          '&:hover': {
                            backgroundColor: 'warning.main',
                            // opacity: [0.3, 0.4, 0.3],
                          },
                        }}
                          onClick={handleCloseFilter}
                          // loading={loading}
                          
                          loadingPosition="center"
                          variant="contained"
                        ><span>ОТМЕНИТЬ ФИЛЬТР</span></LoadingButton>

                        <LoadingButton disableElevation={true} sx={{
                          backgroundColor: 'primary.light',
                          
                          '&:hover': {
                            backgroundColor: 'primary.main',
                            // opacity: [0.3, 0.4, 0.3],
                          },
                        }}
                          onClick={handleExport}
                          loading={loading}
                          
                          loadingPosition="center"
                          variant="contained"
                        ><span>ЭКСПОРТ в EXCELL</span>
                        </LoadingButton>
                      </Stack>
                    </div>
            </Item2>
          </Grid>
			<Grid item xs={12} sm={6} md={3}>
				<Item2 elevation='0' sx={{
			
					height: '100%',
					backgroundColor: '#eafefd',
					// opacity: [0.3, 0.4, 0.3],
					'&:hover': {
					backgroundColor: '#eaf5fe',
					// opacity: [0.3, 0.4, 0.3],
					},
				}}>
				<div>

					<Typography variant="h5" color="#000">ТИП ОПЛАТЫ</Typography>
							<br/>

							<FormControl sx={{ width:'100%' , mt:-2}}>
							<Select
								size='small'
								value={selectedPaymentType}
								onChange={handlePaymentTypeChange}
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
							>
								
								<MenuItem value={0}>Все</MenuItem>
									<MenuItem value={1}>Наличные</MenuItem>
									<MenuItem value={2}>Безналичные</MenuItem>
									<MenuItem value={3}>Со скидкой</MenuItem>
								
								
							</Select>
							<FormHelperText>Выберите из выпадающего списка</FormHelperText>
							</FormControl>

						
				</div>

				<div >

					<Typography variant="h5" color="#000" sx={{mt:1}}>ПО ПРОМОКОДУ</Typography>
							<br/>

							<FormControl sx={{ width:'100%', mt:-2 }}>
							<Select
								size='small'
								value={selectedPromocodeId}
								onChange={handlePromocodeChange}
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
							>
									<MenuItem value={0}>Все</MenuItem>
								{
									
									promocodes && promocodes.map(promocode=>{

										var promocodetxt = ' ' + moment(promocode.startdate).format('DD.MM.YYYY') + '-' + moment(promocode.expiredate).format('DD.MM.YYYY') + ' на ' + promocode.value;
										var currency = typeof selectedTocka != 'undefined' ? typeof selectedTocka.currency != 'undefined' ? parse(selectedTocka.currencysign) : '' : ''
										var nn = promocode.promocodetype == 0 ? ' % ' : ' ' + currency;// 
										promocodetxt = promocodetxt + nn;
										return (
											<MenuItem value={promocode.id}><span style={{ fontWeight: 'bold', fontSize: 20, color: promocode.isactive == false ? 'red' : 'black' }}>{promocode.promocode}</span>&nbsp;&nbsp;&nbsp;{promocodetxt}</MenuItem>)
										} 
									)
								}

								
							</Select>
							<FormHelperText>Выберите из выпадающего списка</FormHelperText>
							</FormControl>

						
				</div>

				</Item2>
			</Grid>

			<Grid item xs={12} sm={6} md={3}>
				<Item2 elevation='0' sx={{
			
					height: '100%',
					backgroundColor: '#eafefd',
					// opacity: [0.3, 0.4, 0.3],
					'&:hover': {
					backgroundColor: '#eaf5fe',
					// opacity: [0.3, 0.4, 0.3],
					},
				}}>
				<Item2 elevation='0' sx={{
        
					height: '100%',
					backgroundColor: '#eafefd',
					
					variant: 'outlined',
					// opacity: [0.3, 0.4, 0.3],
					'&:hover': {
					backgroundColor: '#eaf5fe',
					// opacity: [0.3, 0.4, 0.3],
					},
				}}>
				<Typography xs={12} sm={12} md={12} variant="h6" color="#000">Итого за данный период: <br/><span>Чеков: {sany}<br/>На сумму: {summa2 + ' ' + valuta} <br/>Оплачено: {summa + ' ' + valuta }<br/>Скидка: {summa3 + ' ' + valuta} </span> </Typography>
				
				</Item2>

				</Item2>
			</Grid>

		  
          
        </Grid>
        </Grid>

        {/* <Grid item xs={12} sm={12} md={8} minHeight={60} height='100%'>
        
        </Grid> */}
        
        <Grid item xs={12} sm={12} md={8} height={650}>
          <Item elevation='0' 
              sx={{
            
                height: '100%',
                backgroundColor: '#eafefd',
                // opacity: [0.3, 0.4, 0.3],
                // '&:hover': {
                //   backgroundColor: 'primary.main',
                  // opacity: [0.3, 0.4, 0.3],
                // },
              }}
          >
            <LocalizationProvider 
            dateAdapter={AdapterDayjs}
            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
            adapterLocale={ru}
            >
<DataGrid 
                paginationMode="server"
                disableColumnMenu
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading = {loading}
                height={500}
                disableColumnFilter

				localeText={ruRU2.components.MuiDataGrid.defaultProps.localeText}
				
                pagination
                rows={rows}
                {...rows}
                rowCount={rowCountState}
                pinnedRows={pinnedRows}
                columns={columns}
                pageSize={50}
                onPageChange={(params) => {
                  //console.log("===params===", params);
                }}



                // rows={rows} 
                // columns={columns} 
                
                //   rowCount={sany}

                //   pagination={true}
                //   paginationMode="server"
                  rowsPerPageOptions={[50]}
                  // pageSize={50}
                //   // page={page}
                //   handlePageChange={(page2)=>{
                //     //console.log('handel dwa');
                //   }}
                //   onPageChange={(page) => {
                //     //console.log("Current Page: ", page);
                //     setPage(page);
                //   }}
                  
                  pageSizeOptions={[50]}
                  paginationModel={paginationModel}
                  // paginationMode="server"
                  onPaginationModelChange={(page)=>{
                    // //console.log('page ' + page.page);
                    setLoading(true);
                    setPaginationModel({ 'page' : page.page, pageSize: 50})
                    fetchSalesByDate2(page.page + 1,selectedPaymentType)
                    setLoading(false);
                  }}

                  // // rows={pageState.data}
                  // // rowCount={sany} 
                  // // loading={pageState.isLoading}
                  
                  
                  // rowsPerPageOptions={[50]}
                  // pagination
                  // page={page}
                  // pageSize={50}



                  // paginationMode="server"
                  // onPageChange={(newPage) => {
                  //   // setPageState(old => ({ ...old, page: newPage + 1 }))
                  //   setPage(newPage + 1)
                  //   //console.log('NEWPAGE ' + newPage);
                  // }}
                  // onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
            
            />


            </LocalizationProvider>
            
          
          
          
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={4} height={650}>
        <Item elevation='0' 
              sx={{
            
                height: '100%',
                backgroundColor: '#eafefd',
                // opacity: [0.3, 0.4, 0.3],
                // '&:hover': {
                //   backgroundColor: 'primary.main',
                  // opacity: [0.3, 0.4, 0.3],
                // },
              }}
          >
            
            <DataGrid 
                // paginationMode="server"
                disableColumnMenu
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading = {loading}
                height={500}
                disableColumnFilter

				localeText={ruRU2.components.MuiDataGrid.defaultProps.localeText}

                pagination = 'false'
                rows={prodycty}
                {...prodycty}
                rowCount={rowCountState2}
                
                columns={columns2}
                pageSize={rowCountState2}
                // onPageChange={(params) => {
                //   //console.log("===params===", params);
                // }}



                // rows={rows} 
                // columns={columns} 
                
                //   rowCount={sany}

                //   pagination={true}
                //   paginationMode="server"
                  // rowsPerPageOptions={[50]}
                  // pageSize={50}
                //   // page={page}
                //   handlePageChange={(page2)=>{
                //     //console.log('handel dwa');
                //   }}
                //   onPageChange={(page) => {
                //     //console.log("Current Page: ", page);
                //     setPage(page);
                //   }}
                  
                  pageSizeOptions={[100]}
                  // paginationModel={paginationModel}
                  // paginationMode="server"
                  // onPaginationModelChange={(page)=>{
                  //   //console.log('page ' + page.page);
                  //   setLoading(true);
                  //   setPaginationModel({ 'page' : page.page, pageSize: 50})
                  //   fetchSalesByDate2(page.page + 1,selectedPaymentType)
                  //   setLoading(false);
                  // }}

                  // // rows={pageState.data}
                  // // rowCount={sany} 
                  // // loading={pageState.isLoading}
                  
                  
                  // rowsPerPageOptions={[50]}
                  // pagination
                  // page={page}
                  // pageSize={50}



                  // paginationMode="server"
                  // onPageChange={(newPage) => {
                  //   // setPageState(old => ({ ...old, page: newPage + 1 }))
                  //   setPage(newPage + 1)
                  //   //console.log('NEWPAGE ' + newPage);
                  // }}
                  // onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
            
            />
          
          
          
          </Item>
        </Grid>
      </Grid>
    </Box>

   
    </div>
  )
}

export default Status;
