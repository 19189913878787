import React ,{ useState ,useEffect,useRef} from 'react'; // , { useState }
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import Status from "./components/Dashboard/Status";
import Settings from "./components/Dashboard/Settings";
import Logs from "./components/Dashboard/Logs";
import Tockas from "./components/Dashboard/Tockas";
import Clients from "./components/Dashboard/Clients";
import Promos from "./components/Dashboard/Promos";
import Warns from "./components/Dashboard/Warns";
import Podpiskas from "./components/Dashboard/Podpiskas";
import Contracts from "./components/Dashboard/Contracts";
import Login from './components/Login/Login';
import {SocketContext, socket} from './components/Dashboard/SocketProvider';
import {SocketContext2, socket2} from './components/Dashboard/SocketProvider2';

function App() {

  const token = sessionStorage.getItem('accessToken');

  if(!token ) {
    return (<Login  />)
  }
  return(
    
    <SocketContext.Provider value={socket}>
      <SocketContext2.Provider value={socket2}>
      <BrowserRouter>
        <Switch>
          
          <Route exact path="/" element={<Tockas  />}>
          
          </Route>
          <Route exact path="/login" element={<Login />}>
          
          </Route>
          <Route path="/dashboard" element={<Tockas />}>
          </Route>
          <Route path="/dashboard/tockas" element={<Tockas  />}>
          </Route>
          <Route path="/dashboard/clients" element={<Clients  />}>
          </Route>
          <Route path="/dashboard/settings" element={<Settings />}>
          </Route>
          <Route path="/dashboard/status" element={<Status  />}>
          </Route>
          <Route path="/dashboard/logs" element={<Logs  />}>
          </Route>
          <Route path="/dashboard/promos" element={<Promos  />}>
          </Route>
          <Route path="/dashboard/warns" element={<Warns  />}>
          </Route>
		  <Route path="/dashboard/podpiskas" element={<Podpiskas  />}>
          </Route>
		  <Route path="/dashboard/contracts" element={<Contracts  />}>
          </Route>
          
          <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>There's nothing here!</p>
                  </main>
                }
              />
        </Switch>
    
      </BrowserRouter>
      </SocketContext2.Provider>
    </SocketContext.Provider>
    
    
    
  
  );
}

export default App;
