import React, {memo,useEffect,useState,useRef, createRef} from "react";


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';
import { DatePicker as DatePicker2 } from '@mui/x-date-pickers/DatePicker';
import ru from 'dayjs/locale/ru';
import swal from 'sweetalert';
import dayjs from 'dayjs';
import axios from 'axios';
import BoltIcon from '@mui/icons-material/Bolt';
import { styled } from '@mui/material/styles';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


import { ruRU } from '@mui/x-date-pickers/locales';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import  moment  from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import parse from 'html-react-parser';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,ToggleButtonGroup,ToggleButton,
  Button, IconButton,Chip,OutlinedInput,ListItem,Paper,
} from "@mui/material";
import { height, margin } from "@mui/system";

const AddEditPodpiska = ({open, onClose, summa, tid, urlshka}) => { //{ open, onClose, selectedPromocode, selectedTocka, selectedPromocodeId, genPromocode, adminid }

const [loading, setLoading] = React.useState(false);



    
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8; 
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  return (
    <Dialog
        PaperProps={{ onClick: e => e.stopPropagation() }}
        onBackdropClick={e => e.stopPropagation()}
        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 800, height:'80%' } }}
        maxWidth="xs"
        
        // TransitionProps={{ onEntering: handleEntering }}
        open={open}
        // {...other}
      >
        <DialogTitle>ОПЛАТА</DialogTitle>
        <DialogContent dividers sx={{ margin:0, padding:0, border:0}}>
          
		  
		<iframe src={"https://qr.nspk.ru/"+ urlshka} width={'100%' } height={'100%'} margin={'-10'} padding={0}/>
		  
       
    
          
        </DialogContent>
        <DialogActions sx={{pt:2, pb:2, pr:3}}>
        <LoadingButton disableElevation={true} sx={{
                          backgroundColor: 'warning.light',
                          pt:1, pb:1,
                          '&:hover': {
                            backgroundColor: 'warning.main',
                            // opacity: [0.3, 0.4, 0.3],
                          },
                        }}
                          onClick={()=>{
                            // setDenNedeli(days)
                            // setPromocodeType(0)
                            // setPromocodeValue(0)
                            // setPromocodeFor(0)
                            // setPromocodeReuseable(0)
                            // setStartDate(dayjs(moment.utc(new Date()).format("YYYY-MM-DD")))
                            // setEndDate(dayjs(moment.utc(new Date()).format("YYYY-MM-DD")))
                            onClose()
                          }}
                          // loading={loading}
                          
                          loadingPosition="center"
                          variant="contained"
                        ><span>ОТМЕНИТЬ</span></LoadingButton>

                        {/* <LoadingButton disableElevation={true} sx={{
                          backgroundColor: 'primary.light',
                          pt:1, pb:1,
                          '&:hover': {
                            backgroundColor: 'primary.main',
                            // opacity: [0.3, 0.4, 0.3],
                          },
                        }}
                          // onClick={}
                          loading={loading}
                          onClick={()=>{
                              
                            //create 00011
                            var nolnolodin = ''
                            days.map((day)=>{
                              if (denNedeli.indexOf(day) === -1) {
                                nolnolodin = nolnolodin + '0';
                              } else {
                                nolnolodin = nolnolodin + '1';
                              }
                            })

                            console.log('NOLNOLODIN ' + nolnolodin);

                            console.log('PROMOS ' + promocodeRef.current.value);

                            //detect insert or update
                            if (selectedPromocode == null) {
                              //insert
                              console.log('insert');
                              setLoading(true)

                              axios.post(
                                "http://46.17.250.209:8081/create/own/promocode",{
                                  token:'mesanatoken',
                                  promocodestring: promocodeRef.current.value,
                                  promocodetype: promocodeType,
                                  promocodevalue: valueRef.current.value,
                                  startdate: dayjs(startDate).format("YYYY-MM-DD") + ' 00:00:00',
                                  enddate: dayjs(endDate).format("YYYY-MM-DD") + ' 23:59:59',
                                  usetimes: usetimesRef.current.value,
                                  promocodefor: promocodeFor,
                                  reuseable: promocodeReusable,
                                  workingdays: nolnolodin,
                                  adminid: adminid,
                                  tockaid: selectedTocka.id
                                }
                              ).then((data)=>{
                                console.log(data);
                                if (data.data.success === 1){
                                  swal("УСПЕХ", 'ДАННЫЕ НОВОГО ПРОКОДА ДОБАВЛЕНЫ ', "success", {
                                    buttons: false,
                                    timer: 2000,
                                  })
                                  .then((value) => {
                                    setTimeout(function () {
                                      setLoading(false)
                                      onClose();
                                  }, 200);
                                  });
                                } else {
                                  setLoading(false)
                                  swal("ОШИБКА", 'ОШИБКА ПРИ ДОБАВЛЕНИИ НОВОГО ПРОМОКОДА ', "error", {
                                    buttons: false,
                                    timer: 2000,
                                  })
                                  .then((value) => {
                                  });
                                }
                              })
                              .catch((ex)=>{
                                setLoading(false)
                                swal("ОШИБКА", 'ОШИБКА ПРИ ДОБАВЛЕНИИ НОВОГО ПРОМОКОДА ', "error", {
                                  buttons: false,
                                  timer: 2000,
                                })
                                .then((value) => {
                                });
                              })
                            } else {
                              //update
                              console.log('UPDATE');
                              setLoading(true)


                            }

                        
                            
                            
                            
                            

                            

                          }}
                          loadingPosition="center"
                          variant="contained"
                        ><span>СОХРАНИТЬ</span>
                        </LoadingButton> */}
        </DialogActions>
      </Dialog>
  );
};
 
export default memo(AddEditPodpiska);