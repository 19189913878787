import React, {memo,useEffect,useState,useRef, createRef} from "react";


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';
import { DatePicker as DatePicker2 } from '@mui/x-date-pickers/DatePicker';
import ru from 'dayjs/locale/ru';
import swal from 'sweetalert';
import dayjs from 'dayjs';
import axios from 'axios';
import BoltIcon from '@mui/icons-material/Bolt';
import { styled } from '@mui/material/styles';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


import { ruRU } from '@mui/x-date-pickers/locales';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import  moment  from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import parse from 'html-react-parser';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,ToggleButtonGroup,ToggleButton,
  Button, IconButton,Chip,OutlinedInput,ListItem,Paper,
} from "@mui/material";

const AddEditPromocode = ({ open, onClose, selectedPromocode, selectedTocka, selectedPromocodeId, genPromocode, adminid }) => {


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8; 
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

    // var selectedProductTypeId2 = selectedProductTypeId;
    // const [sliderValue, setSliderValue] = useState(typeof selectedProduct != 'undefined' ? selectedProduct.product_stock_limit : 30);
    // const [isHiddenBox, setIsHiddenBox] = useState(selectedProductTypeId == 3 || selectedProductTypeId == 4 ? true : false);
    const [loading, setLoading] = React.useState(false);
    const [promocodeType, setPromocodeType] = useState(selectedPromocode != null ? typeof selectedPromocode.promocodetype != 'undefined' ? selectedPromocode.promocodetype != null ? selectedPromocode.promocodetype : 0 : 0 : 0)
    const [promocodeValue, setPromocodeValue] = useState(selectedPromocode != null ? typeof selectedPromocode.promocode.value != 'undefined' ? selectedPromocode.promocode.value : 0 : 0)
    const [promocodeFor, setPromocodeFor] = useState(selectedPromocode != null ? typeof selectedPromocode.promocode != 'undefined' ? selectedPromocode.promocode.promocodefor != null ? selectedPromocode.promocode.promocodefor : 0 : 0 : 0)
    const [promocodeReusable,setPromocodeReuseable] = useState(selectedPromocode != null ? typeof selectedPromocode.promocode != 'undefined' ? selectedPromocode.promocode.reuseable != null ? selectedPromocode.promocode.reuseable : 0 : 0 : 0)

    const [isReuseable,setIsReuseable] = useState(selectedPromocode != null ? typeof selectedPromocode.promocode != 'undefined' ? selectedPromocode.promocode.reuseable != null ? selectedPromocode.promocode.reuseable == 0 ? false : true : false : false : false)


    const [generated,setGenerated] = useState(0);

    const days = [
      'ПН','ВТ','СР','ЧТ','ПТ','СБ','ВС'
      // {name:'Понедельник',name2:'ПН',order:0},
      // {name:'Вторник',name2:'ВТ',order:1},
      // {name:'Среда',name2:'СР',order:2},
      // {name:'Четверг',name2:'ЧТ',order:3},
      // {name:'Пятница',name2:'ПТ',order:4},
      // {name:'Суббота',name2:'СБ',order:5},
      // {name:'Воскресенье',name2:'ВС',order:6},
      
    ];

    const [denNedeli, setDenNedeli] = useState(days);

    const [startDate, setStartDate] = useState(selectedPromocode != null ? typeof selectedPromocode.promocode.startdate != 'undefined' ? dayjs(moment.utc(moment(selectedPromocode.promocode.startdate).add(3,'hours')).format("YYYY-MM-DD")) : dayjs(moment(new Date()).format("YYYY-MM-DD")) : dayjs(moment(new Date()).format("YYYY-MM-DD")));
    const [endDate, setEndDate] = useState(selectedPromocode != null ? typeof selectedPromocode.promocode.expiredate != 'undefined' ? dayjs(moment.utc(moment(selectedPromocode.promocode.expiredate).add(3,'hours')).format("YYYY-MM-DD")) : dayjs(moment(new Date()).format("YYYY-MM-DD")) : dayjs(moment(new Date()).format("YYYY-MM-DD")));
    
    const selectedProductTypeId2 = useRef(null);
    // const [productType, setProductType] = useState(selectedProductTypeId);
    // const [rascyot, setRascyot] = useState(typeof selectedProduct != 'undefined' ? ~~(selectedProduct.product_volume/selectedProduct.product_unit) : 0);

    const ListItem = styled('li')(({ theme }) => ({
      margin: theme.spacing(0.5),
    }));

    const genRef = useRef(null);
    const sanyRef = useRef(1);

    const promocodeRef = useRef();
    const valueRef = useRef();
    const usetimesRef = useRef(0);
    const limitRef = useRef(0);
    const unitRef = useRef(0);
    const stockRef = useRef(0);
    // setProductType(prdtype);

    useEffect(()=>{
      
    //   selectedProductTypeId2.current = productType; //typeof productType != 'undefined' || productType == 0 ? productType : selectedProductTypeId;
       //console.log('SELECTED TOCKA ISLEDI ' + selectedProductTypeId);
    //   setProductType(selectedProductTypeId)
        // console.log(typeof selectedPromocode.promocode);
        // console.log(typeof selectedPromocode);
        if (selectedPromocode != null && selectedPromocodeId != 0){
          console.log('SET PROMOCODE WNUTRI USEEFFECT ' + selectedPromocodeId + ' we ' + selectedPromocode.col2.promocode + ' SELECTEDTOCKA ' + parse(selectedTocka.currencysign) + ' we ' + moment(selectedPromocode.promocode.startdate).format("YYYY-MM-DD"));//+ typeof selectedPromocode.
          var days2 = []
          for (var i = 0; i < selectedPromocode.promocode.workingdays.length; i++) {
            if (selectedPromocode.promocode.workingdays[i] == '1'){
              days2.push(days[i])
            }
          }
          setDenNedeli(days2)
          setIsReuseable(selectedPromocode.promocode.reuseable == 0 ? false : true)
          setPromocodeType(selectedPromocode.promocodetype)
          setPromocodeValue(selectedPromocode.promocode.value)
          setPromocodeFor(selectedPromocode.promocode.promocodefor)
          setPromocodeReuseable(selectedPromocode.promocode.reuseable)
          setStartDate(dayjs(moment.utc(moment(selectedPromocode.promocode.startdate).add(3,'hours')).format("YYYY-MM-DD")))
          setEndDate(dayjs(moment.utc(moment(selectedPromocode.promocode.expiredate).add(3,'hours')).format("YYYY-MM-DD")))
        } else {
          console.log('SELECTED PROMOCODE NULL GELDI ');
          setIsReuseable(false)
          setDenNedeli(days)
          setPromocodeReuseable(0)
          setPromocodeFor(0)
          setPromocodeType(0)
          setPromocodeValue(0)
          setStartDate(dayjs(moment.utc(new Date()).format("YYYY-MM-DD")))
          setEndDate(dayjs(moment.utc(new Date()).format("YYYY-MM-DD")))
        }
      //  promocode != 'undefined' ? selectedPromocode.promocode : 'UNDEFINED');

      
    },[selectedPromocodeId, generated]);

    const handleDenChange = (e,values)=>{

      console.log(values);
      // let pp = (e.target.value).filter( (ele, ind) => ind === (e.target.value).findIndex( elem => elem.name === ele.name))
      let pp = values;

      // if (denNedeli.findIndex(object => {
      //   return object.name === pp.name;
      // }) === -1) {
      //   //goshmaly
        
      // } else {
      //   //ayrmali
      //   pp.slice(denNedeli.findIndex(object => {
      //     return object.name === e.value.target.name;
      //   }),1)
      //   //denNedeli
      // }

      

        console.log('HANY PP ' );
        console.log(pp);
      //   console.log('DEN NEDELI ' + denNedeli);
      //   console.log(e);
        setDenNedeli(values)
    }

    // useEffect(() => {
      
    //     if (typeof selectedTocka != 'undefined' && typeof selectedTocka.podpiska != 'undefined') {
    //       if (selectedTocka.podpiska.length > 0 && selectedTocka.podpiska[selectedTocka.podpiska-1] == '1'){
          
    //         if (productType == 3 || productType == 4 ? true : false) {
    //           setIsHiddenBox(true)
    //         } else {
    //           setIsHiddenBox(false)
    //         }
    //       } else {
    //         setIsHiddenBox(false)
    //       }
    //     }
      
      
      
    // },[productType,selectedProductTypeId]);


    // const handleProductTypeChange = (e) => {
    //    //console.log(e.target.value + ' ' + selectedProduct.product_has_limit + ' PODPISKA ' );
      
    //   setProductType(e.target.value);
    //   selectedProduct.col2.product_type = e.target.value;
      
    //   // selectedProductTypeId2 = e.target.value;
    //   //  //console.log('SELECTED PRODUCT TYPE ' + productType);
    //   // if (selectedTocka.podpiska.length > 0 && selectedTocka.podpiska[selectedTocka.podpiska-1] == '1'){
        
    //   //   if (e.target.value == 3 || e.target.value == 4) {
    //   //     setIsHiddenBox(true)
    //   //   } else {
    //   //     setIsHiddenBox(false)
    //   //   }
    //   // } else {
    //   //   setIsHiddenBox(false)
    //   // }
      
    // }

     

    // const handleSliderChange = (e) => {
    //    //console.log('e ' + e);
    //   setSliderValue(e.target.value)
    // }

    const handlePromocodeTypeChange = (e)=>{
      console.log('PROMOCODE TYPE CHANGED ' + e.target.value);
      setPromocodeType(e.target.value);
    }

    const handlePromocodeForChange = (e)=>{
      setPromocodeFor(e.target.value)
    }

    const handlePromocodeReuseableChange = (e) =>{
      if (e.target.value == 0) {
        setIsReuseable(false)
      } else {
        setIsReuseable(true)
      }
      setPromocodeReuseable(e.target.value)
    }

    const MyToggleButton = styled(ToggleButton)(({ selectedColor }) => ({
      "&.Mui-selected": {
        color: "white",
        backgroundColor: selectedColor,
        fontWeight: 'bold'
      },
      "&.Mui-selected:hover":{
        backgroundColor: '#29b6f6',
      }
    }));

  return (
    <Dialog
        PaperProps={{ onClick: e => e.stopPropagation() }}
        onBackdropClick={e => e.stopPropagation()}
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 600 } }}
        maxWidth="xs"
        
        // TransitionProps={{ onEntering: handleEntering }}
        open={open}
        // {...other}
      >
        <DialogTitle>{selectedPromocodeId == 0 ? 'ДОБАВЛЕНИЕ ПРОМОКОДА' : 'РЕДАКТИРОВАНИЕ ПРОМОКОДА'}</DialogTitle>
        <DialogContent dividers>
          
        <Stack direction='row' spacing={2}>
        <TextField
            // type="number"
            // InputProps={{
            //   startAdornment: <InputAdornment position="start">кг</InputAdornment>,
            //   min: 4, max: 10 
            // }}
            
            // inputProps={{type: 'number'}}
            sx={{width:'50%'}}
            id="outlined-required"
            label="Промокод"
            inputRef={promocodeRef}
            ref={genRef}
            defaultValue={selectedPromocode != null ? typeof selectedPromocode.col2 != 'undefined' ? selectedPromocode.col2.promocode : 0 : 0}
        />

        {selectedPromocode != null ? 
        <TextField disabled
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end" sx={{mr:1, ml:3}}><IconButton disabled sx={{ backgroundColor: '#4fc3f7',
                      color:'#fff',
                      '&:hover': {
                        backgroundColor: '#29b6f6',
                        // opacity: [0.3, 0.4, 0.3],
                      },}}
              aria-label="toggle password visibility"
              onClick={()=>{
                console.log('GENEEATE ' + sanyRef.current.value + ' SHTUK')
                // genRef.current.value = genPromocode()
                var proms = genPromocode(sanyRef.current.value)
                var promsall = '';
                console.log('IND ' + proms)
                proms.map((prom,ind)=>{
                  console.log('IND ' + ind + ' ' + prom)
                  var sony = ind == (proms.length - 1) ? '' : ', '
                  promsall = promsall + '' + prom + '' + sony
                })
                console.log('IND2 ' + promsall)

                promocodeRef.current.value = proms.length > 1 ? promsall : proms[0]
              }}
              // onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              <BoltIcon/>
            </IconButton>
            </InputAdornment>,
          
        }}
        
        inputProps={{type: 'number'}}
        sx={{width:'50%'}}
        id="outlined-required"
        label="Генерация"
        inputRef={sanyRef}
        // ref={genRef}
        defaultValue={1}
        min={1}
    />  
        :
        <TextField
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end" sx={{mr:1, ml:3}}><IconButton sx={{ backgroundColor: '#4fc3f7',
                          color:'#fff',
                          '&:hover': {
                            backgroundColor: '#29b6f6',
                            // opacity: [0.3, 0.4, 0.3],
                          },}}
                  aria-label="toggle password visibility"
                  onClick={()=>{
                    console.log('GENEEATE ' + sanyRef.current.value + ' SHTUK')
                    // genRef.current.value = genPromocode()
                    var proms = genPromocode(sanyRef.current.value)
                    var promsall = '';
                    console.log('IND ' + proms)
                    proms.map((prom,ind)=>{
                      console.log('IND ' + ind + ' ' + prom)
                      var sony = ind == (proms.length - 1) ? '' : ', '
                      promsall = promsall + '' + prom + '' + sony
                    })
                    console.log('IND2 ' + promsall)

                    promocodeRef.current.value = proms.length > 1 ? promsall : proms[0]
                  }}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  <BoltIcon/>
                </IconButton>
                </InputAdornment>,
              
            }}
            
            inputProps={{type: 'number'}}
            sx={{width:'50%'}}
            id="outlined-required"
            label="Генерация"
            inputRef={sanyRef}
            // ref={genRef}
            defaultValue={1}
            min={1}
        />  
        }


        </Stack>
          
        <Stack direction='row' spacing={2}>
          <FormControl sx={{mt:2,width:'50%'}}>
            <InputLabel id="demo-simple-select-label">Тип промокода</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={promocodeType}
              // defaultValue={selectedPromocode != null ? typeof selectedPromocode.promocodetype != 'undefined' ? selectedPromocode.promocodetype != null ? selectedPromocode.promocodetype : 0 : 0 : 0}
              label="Тип промокода"
              onChange={handlePromocodeTypeChange}
            >
              <MenuItem value={0}>Процент</MenuItem>
              <MenuItem value={1}>Сумма</MenuItem>
              
            </Select>
          </FormControl>

          
          <Box sx={{width:'50%', mt:2}}>
            <TextField
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">{promocodeType == 0 ? '%' : typeof selectedTocka != 'undefined' ? 
                typeof selectedTocka.currencysign != 'undefined' ?
                parse(selectedTocka.currencysign) : 'Р' 
                : 'Р'}</InputAdornment>,
                min: 0, max: 100 
              }}
              
              inputProps={{type: 'number'}}
              sx={{width:'100%',mt:2}}
              id="outlined-required"
              label="Значение"
              inputRef={valueRef}
              defaultValue={selectedPromocodeId == 0 ? 0 : promocodeValue}
              // defaultValue={selectedPromocode != null ? typeof selectedPromocode.col2 != 'undefined' ? selectedPromocode.col2.promocode : 0 : 0}
          />
          </Box>

        </Stack>
        <LocalizationProvider 
              dateAdapter={AdapterDayjs}
              localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
              adapterLocale={ru}
            >
        <Stack direction='row' spacing={2} sx={{mt:2}}>

            <DatePicker2 sx={{width:'50%'}}
              dayOfWeekFormatter={(day) => `${day}`}
              // calendarStartDay={1}
              size="small"
              format="DD.MM.YYYY"
              label="Начало"
              value={startDate}
              onChange={(newValue) => {
                // //console.log('HANY@ ' + new Date());
                // //console.log('HANY' + newValue);
                setStartDate(newValue)
              }}
            />
          
      
            <DatePicker2 sx={{width:'50%'}}
              dayOfWeekFormatter={(day) => `${day}`}
              format="DD.MM.YYYY"
              label="Конец"
              value={endDate}
              onChange={(newValue) => {
                // //console.log('HANY@ ' + new Date());
                // //console.log('HANY' + newValue);
                setEndDate(newValue)
              }}
            />
              
        </Stack>
          
        </LocalizationProvider>

        <Stack direction='row' spacing={2} sx={{mt:2}}>
          
          <Box sx={{width:'50%'}} >
          {isReuseable == true ? 
          <TextField 
          type="number"
          
          inputProps={{type: 'number'}}
          sx={{width:'100%'}}
          id="outlined-required"
          label="Кол. использования N раз"
          inputRef={usetimesRef}
          defaultValue={selectedPromocode != null ? selectedPromocode.promocode != null ? selectedPromocode.promocode.usetimes != null ? selectedPromocode.promocode.usetimes : 0 : 0 : 0}
          // defaultValue={selectedPromocode != null ? typeof selectedPromocode.col2 != 'undefined' ? selectedPromocode.col2.promocode : 0 : 0}
      /> : 
          <TextField disabled
              type="number"
              
              inputProps={{type: 'number'}}
              sx={{width:'100%'}}
              id="outlined-required"
              label="Кол. использования N раз"
              inputRef={usetimesRef}
              defaultValue={selectedPromocode != null ? selectedPromocode.promocode != null ? selectedPromocode.promocode.usetimes != null ? selectedPromocode.promocode.usetimes : 0 : 0 : 0}
              // defaultValue={selectedPromocode != null ? typeof selectedPromocode.col2 != 'undefined' ? selectedPromocode.col2.promocode : 0 : 0}
          />
          }
            
          </Box>
          <FormControl sx={{width:'50%'}}>
            <InputLabel id="demo-simple-select-label">Услуги</InputLabel>
            <Select 
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={promocodeFor}
              // defaultValue={selectedPromocode != null ? typeof selectedPromocode.promocodetype != 'undefined' ? selectedPromocode.promocodetype != null ? selectedPromocode.promocodetype : 0 : 0 : 0}
              label="Услуга"
              onChange={handlePromocodeForChange}
            >
              <MenuItem value={0}>Все услуги</MenuItem>
              <MenuItem value={1}>Стирка</MenuItem>
              <MenuItem value={2}>Сушка</MenuItem>
			  <MenuItem value={3}>Стирка + Сушка</MenuItem>
              <MenuItem value={5}>Стирка Обуви</MenuItem>
              <MenuItem value={6}>Сушка обуви</MenuItem>
			  <MenuItem value={4}>Стирка + Сушка (обуви)</MenuItem>
			  <MenuItem value={7}>Стирка + Стирка (обуви)</MenuItem>
			  <MenuItem value={8}>Сушка + Сушка (обуви)</MenuItem>
			  <MenuItem value={9}>Стирка/Сушка + Стирка/Сушка (обуви)</MenuItem>
              
            </Select>
          </FormControl>
        </Stack>

        <Stack direction='row' spacing={2} sx={{mt:2}}>
          
          <FormControl sx={{width:'50%',pr:1}}>
            <InputLabel id="demo-simple-select-label">Использование</InputLabel>
            <Select 
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={promocodeReusable}
              // defaultValue={selectedPromocode != null ? typeof selectedPromocode.promocodetype != 'undefined' ? selectedPromocode.promocodetype != null ? selectedPromocode.promocodetype : 0 : 0 : 0}
              label="Использование"
              onChange={handlePromocodeReuseableChange}
            >
              <MenuItem value={0}>Одноразовое</MenuItem>
              <MenuItem value={1}>Многоразовое</MenuItem>
              
              
            </Select>
          </FormControl>
        </Stack>



        {/* DNI NEDELI */}
         <FormControl sx={{ mt: 1, width: '100%',display:'flex', alignItems:'center' }}>
          <Typography sx={{fontWeight:'medium',fontSize:13, color:'#7B7B7B'}} >Выбранные дни недели</Typography>
          <ToggleButtonGroup
            value={denNedeli}
            onChange={handleDenChange}
            aria-label="device"
            sx={{mt:1}}
          >
            {days.map((day)=>{
              return(<MyToggleButton value={day} aria-label={day} selectedColor='#4fc3f7'>
              {day}
              </MyToggleButton>)
            })}

          </ToggleButtonGroup>

          
          

          {/* <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={denNedeli}
            onChange={handleDenChange}
            input={<OutlinedInput id="select-multiple-chip" label="Дни недели" />}
            renderValue={(selected) => {
              console.log('HANY33 ' + typeof selected);
              console.log(selected);
              console.log(denNedeli);
              return(
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((name) => {
                    console.log(name);
                    return (
                    
                      <Chip key={name} label={name} />
                    )
                  })}
                </Box>
              )
            }}
            MenuProps={MenuProps}
          >
            {days.map((day) => (
              <MenuItem
                key={day.name2}
                value={day.name2}
                style={{fontWeight: denNedeli.findIndex(object => {
                  return object.order === day.order;
                }) === -1
                  ? 'regular'
                  : 'bold'}}
                // style={getStyles(name, denNedeli, theme)}
                
                  
                        
                  
                              
              >
                {day.name}
              </MenuItem>
            ))}
          </Select> */}
        </FormControl>
          
        </DialogContent>
        <DialogActions sx={{pt:2, pb:2, pr:3}}>
        <LoadingButton disableElevation={true} sx={{
                          backgroundColor: 'warning.light',
                          pt:1, pb:1,
                          '&:hover': {
                            backgroundColor: 'warning.main',
                            // opacity: [0.3, 0.4, 0.3],
                          },
                        }}
                          onClick={()=>{
                            setDenNedeli(days)
                            setPromocodeType(0)
                            setPromocodeValue(0)
                            setPromocodeFor(0)
                            setPromocodeReuseable(0)
                            setStartDate(dayjs(moment.utc(new Date()).format("YYYY-MM-DD")))
                            setEndDate(dayjs(moment.utc(new Date()).format("YYYY-MM-DD")))
                            onClose()
                          }}
                          // loading={loading}
                          
                          loadingPosition="center"
                          variant="contained"
                        ><span>ОТМЕНИТЬ</span></LoadingButton>

                        <LoadingButton disableElevation={true} sx={{
                          backgroundColor: 'primary.light',
                          pt:1, pb:1,
                          '&:hover': {
                            backgroundColor: 'primary.main',
                            // opacity: [0.3, 0.4, 0.3],
                          },
                        }}
                          // onClick={}
                          loading={loading}
                          onClick={()=>{
                              
                            //create 00011
                            var nolnolodin = ''
                            days.map((day)=>{
                              if (denNedeli.indexOf(day) === -1) {
                                nolnolodin = nolnolodin + '0';
                              } else {
                                nolnolodin = nolnolodin + '1';
                              }
                            })

                            console.log('NOLNOLODIN ' + nolnolodin);

                            console.log('PROMOS ' + promocodeRef.current.value);

                            //detect insert or update
                            if (selectedPromocode == null) {
                              //insert
                              console.log('insert PROMOCODEFOR ' + promocodeFor);
                              setLoading(true)

                              axios.post(
                                "http://46.17.250.209:8081/create/own/promocode",{
                                  token:'mesanatoken',
                                  promocodestring: promocodeRef.current.value,
                                  promocodetype: promocodeType,
                                  promocodevalue: valueRef.current.value,
                                  startdate: dayjs(startDate).format("YYYY-MM-DD") + ' 00:00:00',
                                  enddate: dayjs(endDate).format("YYYY-MM-DD") + ' 23:59:59',
                                  usetimes: usetimesRef.current.value,
                                  promocodefor: promocodeFor,
                                  reuseable: promocodeReusable,
                                  workingdays: nolnolodin,
                                  adminid: adminid,
                                  tockaid: selectedTocka.id
                                }
                              ).then((data)=>{
                                console.log(data);
                                if (data.data.success === 1){
                                  swal("УСПЕХ", 'ДАННЫЕ НОВОГО ПРОКОДА ДОБАВЛЕНЫ ', "success", {
                                    buttons: false,
                                    timer: 2000,
                                  })
                                  .then((value) => {
                                    setTimeout(function () {
                                      setLoading(false)
                                      onClose();
                                  }, 200);
                                  });
                                } else {
                                  setLoading(false)
                                  swal("ОШИБКА", 'ОШИБКА ПРИ ДОБАВЛЕНИИ НОВОГО ПРОМОКОДА ', "error", {
                                    buttons: false,
                                    timer: 2000,
                                  })
                                  .then((value) => {
                                  });
                                }
                              })
                              .catch((ex)=>{
                                setLoading(false)
                                swal("ОШИБКА", 'ОШИБКА ПРИ ДОБАВЛЕНИИ НОВОГО ПРОМОКОДА ', "error", {
                                  buttons: false,
                                  timer: 2000,
                                })
                                .then((value) => {
                                });
                              })
                            } else {
                              //update
                              console.log('UPDATE promocodefor ' + promocodeFor);
                              setLoading(true)

                              axios.post(
                                "http://46.17.250.209:8081/update/own/promocode",{
                                  token:'mesanatoken',
                                  promocodestring: promocodeRef.current.value,
                                  promocodetype: promocodeType,
                                  promocodevalue: valueRef.current.value,
                                  startdate: dayjs(startDate).format("YYYY-MM-DD") + ' 00:00:00',
                                  enddate: dayjs(endDate).format("YYYY-MM-DD") + ' 23:59:59',
                                  usetimes: usetimesRef.current.value,
                                  promocodefor: promocodeFor,
                                  reuseable: promocodeReusable,
                                  workingdays: nolnolodin,
                                  adminid: adminid,
                                  tockaid: selectedTocka.id,
                                  promocodeid: selectedPromocodeId
                                }
                              ).then((data)=>{
                                console.log(data);
                                if (data.data.success === 1){
                                  swal("УСПЕХ", 'ДАННЫЕ ПРОКОДА ОБНОВЛЕНЫ ', "success", {
                                    buttons: false,
                                    timer: 2000,
                                  })
                                  .then((value) => {
                                    setTimeout(function () {
                                      setLoading(false)
                                      onClose();
                                  }, 200);
                                  });
                                } else {
                                  setLoading(false)
                                  swal("ОШИБКА", 'ОШИБКА ПРИ ДОБАВЛЕНИИ НОВОГО ПРОМОКОДА ', "error", {
                                    buttons: false,
                                    timer: 2000,
                                  })
                                  .then((value) => {
                                  });
                                }
                              })
                              .catch((ex)=>{
                                setLoading(false)
                                swal("ОШИБКА", 'ОШИБКА ПРИ ДОБАВЛЕНИИ НОВОГО ПРОМОКОДА ', "error", {
                                  buttons: false,
                                  timer: 2000,
                                })
                                .then((value) => {
                                });
                              })

                            }

                        
                            
                            
                            
                            

                            

                          }}
                          loadingPosition="center"
                          variant="contained"
                        ><span>СОХРАНИТЬ</span>
                        </LoadingButton>
        </DialogActions>
      </Dialog>
  );
};
 
export default memo(AddEditPromocode);