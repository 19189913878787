import React, { useContext, useEffect, useState } from 'react'
import Header from './Header';
import Login from '../Login/Login';
import axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import swal from 'sweetalert';
import { SocketContext } from './SocketProvider';
import { SocketContext2 } from './SocketProvider2';



export const Logs = (props) => {

  const socket = useContext(SocketContext);
  const socket2 = useContext(SocketContext2);
  const admin = JSON.parse(sessionStorage.getItem('user'));
  var innerlogs = [];
  const [logs, setLogs] = useState([]);
  const token2 = 'mesanatoken';
  const token = sessionStorage.getItem('accessToken');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [tockas, setTockas] = useState([]);
  const [value, setValue] = useState();
  const [selectedTockaId,setSelectedTockaId] = useState();
  const [isFiltered,setIsFiltered] = useState(false);
  const [offset,setOffset] = useState(0);


  const handleChange = (event) => {
    setValue(event.target.value);
    setSelectedTockaId(event.target.value);
  };


  const handleFilterClicked = (event) =>{
    setIsFiltered(true);
    setOffset(0);

    
      fetchTockasByDate(0);
    

    

  }

  const handleCloseFilter = ()=>{

    setStartDate(new Date);
    setEndDate(new Date);
    setIsFiltered(false);
    setOffset(0);
    fetchLogs();
  }

  useEffect(() => {
    fetchLogs();
    fetchTockas();
    
    socket.emit("test", 'LOGS'); 
    socket2.emit("test", 'LOGS'); 

    socket.on('get_logs_pulse', (tabuid,instid,tabdtime,aciton) =>{

      if (isFiltered){
        //console.log('filter is active will not add in realtime');
      } else {
        (async function() {
          try {
    
            
            setLogs(innerlogs);
            fetchLogs();
            
          } catch (e) {
            console.error(e);
          }
        })();
      }

      
    })

    socket2.on('get_logs_pulse', (tabuid,instid,tabdtime,aciton) =>{

      if (isFiltered){
        //console.log('filter is active will not add in realtime');
      } else {
        (async function() {
          try {
    
            
            setLogs(innerlogs);
            fetchLogs();
            
          } catch (e) {
            console.error(e);
          }
        })();
      }

      
    })
    return () => {
      socket.off('get_logs_pulse');
      socket2.off('get_logs_pulse');
   };

   
  },[]);


  


  const fetchTockasByDate = async (of3) => {
      var of = of3 * 50;
      var isc = 0;

      if (checked) {
        isc = 1;
      }

      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/logs/by/tocka/date",{
          tockaid:selectedTockaId,
          token:token2,
          startdate:moment(startDate).format("YYYY-MM-DD"),
          enddate:moment(endDate).format("YYYY-MM-DD"),
          offset:of,
          iscritical:isc
        }
      )
      
      setLogs(data.logs);
      if (data.logs.length == 0){
        swal("ВНИМАНИЕ", 'NO MORE ITEMS ', "warning", {
          buttons: false,
          timer: 2000,
        })
        .then((value) => {
          var of2 = of3 - 1;
          setOffset(of);
        });
      } else {
      }

      
    
  };

  const fetchTockas = async () => {

    if (admin.admin_type == 1 || admin.admin_type == 2){
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/tockas/for/admin",{
          admin_id:admin.id,
          token:token2
        }
      )
      
      setTockas(data.tockas);
    } else {
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/tockas/by/admin",{
          admin_id:admin.id,
          token:token2
        }
      )
      var tockas = data.tockas;
      setTockas(tockas);
      setSelectedTockaId(tockas[0].id);
    }

    
  };

  const moveNext = (of) => {
    var of = offset+1;
    setOffset(of);
    fetchTockasByDate(of);
  }

  const moveBack = (of) => {
    var of = offset - 1;
    setOffset(of);
    fetchTockasByDate(of);
  }

  const fetchLogs = async () => {
    const { data } = await axios.post(
      "http://46.17.250.209:8081/get/logs/by/tocka/admin",{
        admin_id:admin.id,
        token:token2
      }
    )
    
    const products = data;
    
    setLogs(products.logs);
    innerlogs = products.logs;
  };

  const [checked, setChecked] = React.useState(false);

  const handleCheckBoxChange = () => {
    setChecked(!checked);
  };

  const Knopkalar = () =>{
    
      if (isFiltered){
        if (offset > 0){
          return (<div><button className='prosto' onClick={()=>{
            moveBack(offset - 1);
          }}>пред.</button><button className='prosto' onClick={()=>{
            moveNext(offset + 1);
          }}>след.</button></div>);
        } else {
          return (<button className='prosto' onClick={()=>{
            moveNext(offset + 1);
          }}>след.</button>);
        }
      } else {
        return <div></div>;
      }
    
    return null;
  }
  

  
  if(!token ) {
    return (<Login  />)
  }

  return (
    <div>
    <Header selected = {6} />
    <h2 className='body2'>Фильтр</h2>
    <div className='filter-body-container'>
    <div className='filter-body'>
      <div className='filter-body-date'>
      От: <DatePicker 
      locale="ru-RU"
      dateFormat="dd.MM.yyyy"
      closeOnScroll={(e) => e.target === document} selected={startDate} onChange={(date) => setStartDate(date)} />
      До: <DatePicker
      locale="ru-RU"
      dateFormat="dd.MM.yyyy"
      closeOnScroll={(e) => e.target === document} selected={endDate} onChange={(date) => setEndDate(date)} />
      </div>
      <div className='filter-body-date-dwa'>
        <div>
        выбор прачечной<br/>
          <select value={selectedTockaId} onChange={handleChange} style={{width:250}}>

            {tockas && tockas.map(tocka=>(<option value={tocka.id}>{tocka.tocka_address}</option>)
              
            )}

          </select>
          <br/><br/>
          <button className='prosto' onClick={handleFilterClicked}>ПОКАЗАТЬ</button>
          <pre>
          
            </pre>
          <label>
            <input
              type="checkbox"
              checked={checked}
              onChange={handleCheckBoxChange}
            />
                   {'               '}Показать только критичные логи
          </label>
          {/* <button className='prosto' onClick={handleCloseFilter}>ОТМЕНИТЬ ФИЛЬТР</button> */}
        </div>
        
    </div>
      

    </div>
    </div>
    
    <h2 className='body2'>Список логов</h2>
    
    <div className='dashboard_content'>
      
    <div >
      {<Knopkalar/>}
      <div className='table-wrap'>
      <table className="styled-table3">
                <thead>
                    <tr>
                        <th></th>
                        <th>tocka_id</th>
                        <th>Лог</th>
                        <th>Дата</th>
                    </tr>
                </thead>
                <tbody>
                    {logs && logs.map((tocka,idx) => (<tr key={tocka.id} onClick={()=>{
                          //handleTockaClick(tocka.id);
                        }} 
                        >
                            <td>{idx + 1} </td>
                            <td>{tocka.tablet_id} </td>
                            <td>{tocka.action_description}</td>
                            <td>{
                            moment(tocka.action_dtime_server).format("DD.MM.YYYY kk:mm:ss")
                            
                            }</td>
                        </tr>)
                        
                    )}
                </tbody>
            </table>
      </div>
      
    </div>
    </div>
    </div>
  )
}

export default Logs;
