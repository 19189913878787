import React, {useState,useEffect,useContext,useRef} from 'react'
import Login from '../Login/Login';
import Header from './Header';
import axios from 'axios';
import { SocketContext } from './SocketProvider';
import { SocketContext2 } from './SocketProvider2';
import Swal from 'sweetalert2';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import AddEditProduct from './AddEditProduct';

import {CChart} from '@coreui/react-chartjs'
// import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Chart.register(ChartDataLabels);

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Grid  from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import dayjs from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ruRU } from '@mui/x-date-pickers/locales';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as DatePicker2 } from '@mui/x-date-pickers/DatePicker';
// import LinearProgress from '@mui/material/LinearProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ReceiptIcon from '@mui/icons-material/Receipt'; 
import GetAppIcon from '@mui/icons-material/GetApp';
import parse from 'html-react-parser';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ButtonGroup from '@mui/material/ButtonGroup';
import MuiToggleButton from "@mui/material/ToggleButton";

import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import TextField from '@mui/material/TextField';
import BoltIcon from '@mui/icons-material/Bolt';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BuildIcon from '@mui/icons-material/Build';
import Switch from '@mui/material/Switch';
// import IconButton from '@mui/material/IconButton';

import PowerOffOutlinedIcon from '@mui/icons-material/PowerOffOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';

import WifiTetheringOffOutlinedIcon from '@mui/icons-material/WifiTetheringOffOutlined';
import WifiTetheringOutlinedIcon from '@mui/icons-material/WifiTetheringOutlined';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';

import DomainDisabledRoundedIcon from '@mui/icons-material/DomainDisabledRounded';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';

import ExtensionOffRoundedIcon from '@mui/icons-material/ExtensionOffRounded';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

// import { LineChart } from '@mui/x-charts/LineChart';

import {Tab, Tabs, ToggleButton, ToggleButtonGroup} from '@mui/material';

const showSecond = true;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';


 
 

const Tockas = () => {


  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setProductyLoaded(false);
  };

  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
	color:'white',
	
	backgroundColor:'#42a5f5',
	"&.Mui-selected, &.Mui-selected:hover,&:hover": {
	  color: "black",
	  backgroundColor: selectedColor
	}
  }));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    elevation: 0,
    color: theme.palette.text.secondary,
  }));

  const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: '#501A2027',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    elevation: 0,
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    
  }));

  
//   interface StyledTabsProps {
//     children?: React.ReactNode;
//     value: number;
//     onChange: (event: React.SyntheticEvent, newValue: number) => void;
//   }
  
  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  });
  
//   interface StyledTabProps {
//     label: string;
//   }
  
  const StyledTab = styled((props) => (
    <Tab disableRipple {...props} />
  ))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }));


  const token2 = 'mesanatoken';
  const token = sessionStorage.getItem('accessToken');

  const inputCertFile = useRef(null);
  const inputCertFileInput = useRef(null);
  const inputCertFileInput2 = useRef(null);
  const inputCertFileInput3 = useRef(null);
  const inputCertFileInput4 = useRef(null);
  const inputKeyFile = useRef(null);
  const inputCaFile = useRef(null);
  const inputPrivateKeyFile = useRef(null);
  const newPrice = useRef(null);
  const tgNameRef = useRef('@');

  const adminPhone = useRef(null);
  const adminPass = useRef(null);
  const noInet = useRef(null);
  const noPower = useRef(null);
  const noArduino = useRef(null);

  const prdname = useRef(null);
  const prdtype = useRef(null);
  const prdcapacity = useRef(null);
  const newIpName = useRef(null);
  const newInn = useRef(null);

  const newAdress = useRef(null);
  const newMesto = useRef(null);
  const newVendingNo = useRef(null);
  const newTaxSystem = useRef(null);
  const newSendEmail = useRef(null);

  const warnOne = useRef(null);
  const warnDwa = useRef(null);
  const warnTri = useRef(null);
  var tockaLocalSales = [];
  const [maxValue, setMaxValue] = useState(500);
//   var maxValue = 500;

  const [selectedTockaId,setSelectedTockaId] = useState(0);
  const [value, setValue] = useState(0);
  const [timeEditing, setTimeEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [productyLoaded, setProductyLoaded] = useState(false);
  const [reportNumber,setReportNumber] = useState(0);
  const [chartLabels,setChartLabels] = useState([]);
  const [salesData,setSalesData] = useState([]);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  // const [tabValue, setTabValue] = useState(0);
  

  const startWorkTime = useRef(null);
  const endWorkTime = useRef(null);
  var innertockas = [];
  var innerproducts = [];
  const [tockas, setTockas] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedTocka,setSelectedTocka] = useState([]);
  const [selectedProduct,setSelectedProduct] = useState([]);
  const [selectedProductTypeId, setSelectedProductTypeId] = useState(0);
  const [selectedProductId,setSelectedProductId] = useState();
  const [newProductPrice,setNewProductPrice] = useState();
  const [orange,setOrange] = useState([]);
  const [inputFileName,setInputFileName] = useState();
  const [innName,setInn] = useState();
  const [ipName,setIpName] = useState();
  const socket = useContext(SocketContext);
  const socket2 = useContext(SocketContext2);
  const [selectedButton,setSelectedButton] = useState(0);
  const selectedProductId2 = useRef(null);
  const selectedTockaId2 = useRef(null);
  const [isLodaded,setIsLoaded] = useState(false);
  const [isAdding,setIsAdding] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [prodycty, setProducty] = React.useState([]);
  const [isHiddenBox, setIsHiddenBox] = useState(false);
  const [warns, setWarns] = useState('000');
  const [tockaSales,setTockaSales] = useState({gunu: new Date(),datasy:null});
  const [tockaPodpiskas,setTockaPodpidkas] = useState({gunu: new Date(),datasy:[]});
  const [oplata, setOplata] = useState(0)

  useEffect(() => { 
       
    selectedProductId2.current = typeof selectedProduct != 'undefined' ?  selectedProduct.id : null;
    }, [selectedProduct]);

	useEffect(()=>{
		console.log("ISHLEDIM GENSALES UYGANDE");
		//genSalesData(reportNumber)
		genSalesData(reportNumber)
	},[reportNumber]);

  useEffect(()=>{
    selectedTockaId2.current = typeof selectedTockaId != 'undefined' ? selectedTockaId : null;
    //console.log('SELECTED TOCKA ISLEDI');
    fetchSelectedTockaData(selectedTockaId);
    fetchSelectedTockaProducts(selectedTockaId)
	fetchSelectedTockaSales(selectedTockaId)
	fetchSelectedTockaPodpiskas(selectedTockaId)
  }, [selectedTockaId]);

  useEffect(() => {

    fetchProducts();

    socket2.on('get_tockas_power_pulse', (data,name) =>{
      //console.log('get_tockas_power_pulse2 ' + data + ', ' + name);
      (async function() {
        try {
          //console.log('SELECTED TOCKA ID ' + selectedTockaId2.current + ' we ' + selectedTocka.id);
          var numone = 0;
          numone = Number(data);
          var numtwo = 0;
          numtwo = selectedTockaId2.current;
          var numtri = Number(numtwo);
          //fetchProductsByTocka(data);
          //console.log('HANY HANY HANY ' + numone + ' WE ' + numtwo + ' WE ' + numtri);
          if (numone === numtri){
            fetchSelectedTockaData(data);
            fetchSelectedTockaProducts(data)
			fetchSelectedTockaSales(data)
          } else {
            //console.log('UPDATE NOT FOR THIS TOCKA');
          }
          
          
        } catch (e) {
          console.error(e);
        }
      })();
    })



    return () => {
      socket.off('get_tockas_pulse');
      socket.off('get_tockas_power_pulse');
      socket2.off('get_tockas_pulse');
      socket2.off('get_tockas_power_pulse');
   };
  },[]);


  if(!token ) {
    return (<Login  />)
  }
  const admin = JSON.parse(sessionStorage.getItem('user'));
  const handleTockaClick = (tockaid) => {
    const tc = getTocka(tockaid);
    
    setSelectedTocka(tc);
    fetchProductsByTocka(tockaid);
  }

  const handleProductClick = (pid) => {
     const pd = getProduct(pid);
     setSelectedProductId(pid);
     setSelectedProduct(pd);
     setNewProductPrice(pd.product_price);
  }

  const handleProductDeletClick = (product) => {
    Swal.fire({
      title: "Вы уверены что хотите удалить выбранный продукт?",
      text: "После удаления добавление продукции объязательно перезагрузите ПО для того что бы изменения вступили в силу!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) 
        {

            axios.post(
              'http://46.17.250.209:8081/delete/tocka/product',
              {
                pid:product.id,
                token:token2,
                tockaid:selectedTocka.id
              }
            ).then(res => {
              if (res.data.success == 1){
                  Swal.fire("УСПЕХ","Данные успешно удалены!", "success",{
                    button:'OK'
                  })
                  .then((value) => {
                    fetchProductsByTocka(selectedTocka.id);
                  });
              } else {
                  Swal.fire("ОШИБКА", res.data.message, "error", {
                    button: 'OK'
                  });
                  
              }
            })
        } else 
        {
        //Swal.fire("Your imaginary file is safe!");
        }
      });
  }

  const handleProductRemontClick = (product) => {
    if (selectedTocka.tocka_online){
      socket.emit('remont_mashynka',selectedTocka.id,selectedTocka.tocka_tablet_id,product.id, !product.product_on_repair);
      socket2.emit('remont_mashynka',selectedTocka.id,selectedTocka.tocka_tablet_id,product.id, !product.product_on_repair);
      fetchProducts();
    } else {
      Swal.fire("ОШИБКА", 'LAUNDRY OFFLINE ', "error", {
        buttons: false,
        timer: 2000,
      })
      .then((value) => {
      });
    }
  }

  //handleTockaResetOstatokClick
  const handleTockaResetOstatokClick = (tocka) =>{
    //console.log('RESTART ' + tocka.id + ' we ' + tocka.tocka_tablet_id);
    //socket.emit('reset_ostatok',tocka.id,tocka.tocka_tablet_id);
    //socket2.emit('reset_ostatok',tocka.id,tocka.tocka_tablet_id);

	axios.post(
		'http://46.17.250.209:8085/reset/ostatok',
		{
		  tockaid:selectedTocka.id,
		  token:'mesanatoken',
		  
		}
	)
	.then(res => {
		if (res.data.success == 1){
		  Swal.fire("УСПЕХ", 'Данные успешно обновлены ! ', "success", {
			button: 'OK'
		  })
		  .then((value) => {
			//fetchProductsByTocka(selectedTocka.id);
			// selectedTocka.tocka_start_time = startTime
			// selectedTocka.tocka_end_time = endTime
			setSelectedTockaId(selectedTocka.id)
			// setTimeEditing(false)
		  });
		} else {
		  Swal.fire("ОШИБКА", 'ЧТО ТО ПОШЛО НЕ ТАК', "error", {
			button: 'OK'
		  })
		  .then((value) => {
		  });
		}
	})
	.catch(err => {
		//////console.log(err);
	})




	var actionString = admin.admin_phone + ' | Обнуление остатка на ' + selectedTocka.tocka_name + ' из ЛК'
	console.log('HANY LOG YAZMAK ' + actionString + ' we ' + selectedTocka.tocka_name);
	socket2.emit('lklog',selectedTocka.tocka_tablet_id,actionString);
	socket.emit('lklog',selectedTocka.tocka_tablet_id,actionString);
  }


  const handleTockaRestartClick = (tocka) =>{
    //console.log('RESTART ' + tocka.id + ' we ' + tocka.tocka_tablet_id);
    socket.emit('restart_tocka',tocka.id,tocka.tocka_tablet_id);
    socket2.emit('restart_tocka',tocka.id,tocka.tocka_tablet_id);

	var actionString = admin.admin_phone + ' | Перезагрузка ПО на ' + selectedTocka.tocka_name + ' из ЛК'
	console.log('HANY LOG YAZMAK ' + actionString + ' we ' + selectedTocka.tocka_name);
	socket2.emit('lklog',selectedTocka.tocka_tablet_id,actionString);
	socket.emit('lklog',selectedTocka.tocka_tablet_id,actionString);
  }

  const handleTockaSettingsClick = (tocka) =>{
    
    //console.log('SETTINGS ' + tocka.id + ' we ' + tocka.tocka_tablet_id);
      socket.emit('settings_tocka',tocka.id,tocka.tocka_tablet_id,!tocka.tocka_is_active);
      socket2.emit('settings_tocka',tocka.id,tocka.tocka_tablet_id,!tocka.tocka_is_active);
      
    
  }

  //handleTockaSettingsClick

  const handleTockaBlockClick = (tocka) =>{
    
    
    socket.emit('block_tocka',tocka.id,tocka.tocka_tablet_id,!tocka.tocka_is_active);
    socket2.emit('block_tocka',tocka.id,tocka.tocka_tablet_id,!tocka.tocka_is_active);
    
  
}


  const handleProductStartClick = async (product) => {
    const startMethod = Array.isArray(product.product_methods) && product.product_methods.find(el => el.ptype === 4 && el.ptype2 === 1);
    if (selectedTocka.tocka_online){

		console.log('PRODUCT TYOE');
		console.log(product.product_type);
		if (product.product_type == 51) {

			console.log('4 ivermeku');
			const powerMethod = Array.isArray(product.product_methods) && product.product_methods.find(el => el.ptype === 1 && el.ptype2 === 1);
			const modeMethod = Array.isArray(product.product_methods) && product.product_methods.find(el => el.ptype === 2 && el.ptype2 === 1);
			const tempMethod = Array.isArray(product.product_methods) && product.product_methods.find(el => el.ptype === 3 && el.ptype2 === 1);
			const startMethod = Array.isArray(product.product_methods) && product.product_methods.find(el => el.ptype === 4 && el.ptype2 === 1);

			socket.emit('start_mashynka',selectedTocka.tocka_tablet_id,powerMethod.method_char);
			socket2.emit('start_mashynka',selectedTocka.tocka_tablet_id,powerMethod.method_char);

			await sleep(1000);

			socket.emit('start_mashynka',selectedTocka.tocka_tablet_id,modeMethod.method_char);
			socket2.emit('start_mashynka',selectedTocka.tocka_tablet_id,modeMethod.method_char);

			await sleep(1000);

			socket.emit('start_mashynka',selectedTocka.tocka_tablet_id,tempMethod.method_char);
			socket2.emit('start_mashynka',selectedTocka.tocka_tablet_id,tempMethod.method_char);

			
			await sleep(1000);

			socket.emit('start_mashynka',selectedTocka.tocka_tablet_id,startMethod.method_char);
			socket2.emit('start_mashynka',selectedTocka.tocka_tablet_id,startMethod.method_char);


		} else {
			socket.emit('start_mashynka',selectedTocka.tocka_tablet_id,startMethod.method_char);
			socket2.emit('start_mashynka',selectedTocka.tocka_tablet_id,startMethod.method_char);
		}


      
    } else {
      Swal.fire("ОШИБКА", 'LAUNDRY OFFLINE ', "error", {
        buttons: false,
        timer: 2000,
      })
      .then((value) => {
      });
    }
    
  }

  function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const getTocka=(tockaid)=>{
    const tocka = Array.isArray(tockas) && tockas.find(el => el.id === tockaid);
    return tocka
  }

  const getProduct=(tockaid)=>{
    const tocka = Array.isArray(products) && products.find(el => el.id === tockaid);
    return tocka
  }

  const fetchProductsByTocka2 = async (tockaid2) => {
    const { data } = await axios.post(
      "http://46.17.250.209:8081/get/tocka/data",{
        tockaid:tockaid2,
        token:token2
      }
    )
    
    const products2 = data.tocka[0].products;
    const orange2 = data.tocka[0].orange_data;
    setProducts(products2);
    setOrange(orange2);
    innerproducts = products2;
    setSelectedTocka(data.tocka[0]);
    const tocka = Array.isArray(tockas) && tockas.find(el => el.id === data.tocka[0].id);
    const newPeople = tockas.filter((person) => person.id !== data.tocka[0].id);
    newPeople.push(data.tocka[0]);
    setTockas(newPeople);
    setSelectedTockaId(data.tocka[0].id);
    
    if (selectedProductId2.current != undefined){
      const tocka = Array.isArray(products2) && products2.find(el => el.id === selectedProductId2.current);
      setSelectedProductId(selectedProductId2.current);
      setSelectedProduct(tocka);
      
    } else {
      //////console.log('SLEECTED PRODUCT YOK' + selectedProductId2.current);
    }
      

  }



  const fetchProductsByTocka = async (tockaid2) => {
    axios.post(
      "http://46.17.250.209:8081/get/tocka/data",{
        tockaid:tockaid2,
        token:token2
      }
    ).then(cevap => {
      const products2 = cevap.data.tocka[0].products;
      const orange2 = cevap.data.tocka[0].orange_data;
      //console.log('SELECTED TOCKAID ' + selectedTockaId + ' we tockaid2 ' + tockaid2);
      setProducts(products2);
      setOrange(orange2);
      innerproducts = products2;
      //console.log('SELECTED TOCKAID ' + selectedTockaId + ' we tockaid2 ' + tockaid2);
      if (typeof selectedTocka != 'undefined' || selectedTockaId === tockaid2) {
        //console.log('BURDAYIM');
        // setSelectedTocka(cevap.data.tocka[0]);
      } else {
        //console.log('SELECTED TOCKA BASHKA YUTGEMELI DAL');
      }
      
      // fetchProducts();
      if (selectedProductId2.current != undefined){
        const tocka = Array.isArray(products2) && products2.find(el => el.id === selectedProductId2.current);
        setSelectedProductId(selectedProductId2.current);
        setSelectedProduct(tocka);
        
      } else {
        //////console.log('SLEECTED PRODUCT YOK' + selectedProductId2.current);
      }
    })
    
    
      

  }

  const onTodoChange = (value,pid) =>{
    const tocka = Array.isArray(products) && products.find(el => el.id === pid);
    if (tocka != undefined){
      tocka.product_price = value;
    } else {
      //////console.log('tocka null geldi ');
    }
    
}



const fetchSelectedTockaData = async (tid) =>{
  //console.log('SELECTED TOCKAD ID _ ' + tid);
  const { data } = await axios.post(
    "http://46.17.250.209:8081/get/selected/tocka/data",{
      tockaid:tid,
      token:token2
    }
  )
  //console.log(data.tocka_data);
  setSelectedTocka(data.tocka_data)
	setWarns(data.tocka_data.twarns);
  
}

const fetchSelectedTockaProducts = async (tid) =>{

  //console.log('FETCH TOCKA PRODUCTS ISHLEDI TID ' + tid);
  const { data } = await axios.post(
    "http://stirka.me:8081/get/selected/tocka/products",{
      tockaid:tid,
      token:token2
    }
  )
  //console.log(data);
  var rows2 = [];
  data.tocka_products.map((product,ind) => {
    
    //console.log('HANY  ' + product.product_name);

    rows2.push({ 
      id: product.id, 
      col1: product.product_type == 8 ? product.product_order2 : product.product_order, 
      col2: {'product_name': product.product_name, 'product_name2': product.product_name2, 'product_type': product.product_type, 'product_capacity': product.product_capacity, product_stock: product.product_stock, product_volume: product.product_volume, product_unit: product.product_unit,product_stock_limit:product.product_stock_limit} , 
      col3: {'product_price':product.product_price + '  ' + parse(typeof selectedTocka != 'undefined' ? typeof selectedTocka.currencysign != 'undefined' ? selectedTocka.currencysign : '' : ''), product_stock: product.product_stock, product_type: product.product_type}, 
      col4: {'product_id': product.id, 'product_order': product.product_order, 'product_price' : product.product_price, 'product_type' : product.product_type},
      col5: {'product_id': product.id, 'product_order': product.product_order, 'product_on_repair': product.product_on_repair, 'product_type' : product.product_type},
      col6: {'product_id': product.id, 'product_order': product.product_order, 'product_type' : product.product_type},
      product_has_limit: product.product_has_limit,
      product_stock: product.product_stock,
      product_stock_limit: product.product_stock_limit,
      product_volume: product.product_volume,
      product_unit: product.product_unit
    });

  }
  )

  setProducty(rows2)
  
  //console.log('SET PRODUCTY LOADED TRUE');
}

const fetchSelectedTockaSales = async (tid) =>{
	setLoading2(true)
	setChartLabels([])
	setSalesData([])
	setReportNumber(0)
	//console.log('FETCH TOCKA PRODUCTS ISHLEDI TID ' + tid);
	const { data } = await axios.post(
	  "http://stirka.me:8081/get/selected/tocka/sales",{
		tid:tid,
		token:token2
	  }
	)
	console.log('SALES DATA GELDI ');
	console.log(data);
	tockaLocalSales = data;

	// setTockaSales(prevTockaSales => ([prevTockaSales, data]));

	setTockaSales((prevState) => ({
		...prevState,
		datasy: data,
		wagty: new Date()
	  }));
	//setTockaSales(data)
	//setTockaSales(tockaSales)
	setLoading2(false)
	setReportNumber(1)
	
  }


  const fetchSelectedTockaPodpiskas = async (tid) =>{
	setLoading2(true)
	console.log('FETCH TOCKA PODPISKAS ' + tid);
	const { data } = await axios.post(
	  "http://stirka.me:8081/get/selected/tocka/podpiskas",{
		tid:tid,
		token:token2
	  }
	)
	console.log('PODPISKAS DATA GELDI ');
	console.log(data);
	// tockaPodpiskas = data;

	// setTockaSales(prevTockaSales => ([prevTockaSales, data]));

	setTockaPodpidkas((prevState) => ({
		...prevState,
		datasy: data.podpiskas,
		wagty: new Date()
	  }));
	//setTockaSales(data)
	//setTockaSales(tockaSales)


	var isBlocked = false;
	var mustPay = false;
	console.log('PODS SANY@ ' + data.podpiskas.length);
	if (data.podpiskas.length == 0) {
		console.log('pods sany 0');
			setOplata(4)
	} else {
		var pods = typeof data.podpiskas != 'undefined' ? data.podpiskas.filter((podpiska)=>{return podpiska.is_main == true && podpiska.cost != podpiska.paid}) : []
		if (pods.length > 0) {
			pods.map((pod)=>{
				console.log('POD datasy ' + pod.startdate + ' we bugun ' + moment().format('YYYY-MM-DD'));
				console.log('ARATAPAWUT ' + moment(pod.startdate).diff(moment(), 'hours'));
				var tapawut = moment(pod.startdate).diff(moment(), 'hours');
				if (tapawut > 0 && tapawut <= 24) {
					// jeltiy nado oplatit
					console.log('ARATAPAWUT NADO OPLATIT JELTIY');
					setOplata(1)
				} else if (tapawut >= -24 && tapawut < 0) {
					//bloked
					console.log(' ARATAPAWUT NADO OPLATIT KRASNIY');
					setOplata(2)
				}
				else if (tapawut < -24) {
					//bloked
					console.log(' ARATAPAWUT BLOKIROWAN');
					setOplata(3)
				} else {
					console.log('ARATAPAWUT NORMALNO POKA');
					setOplata(0)
				}
			})
		} else {
			console.log('pods sany 0');
			setOplata(0)
		}
	}
	




	setLoading2(false)
	
  }

// const [sany2,setSany2] = useState(0);
// const [rowCountState2, setRowCountState2] = React.useState(sany2);
  
const fetchProducts = async () => {
    if (admin.admin_type == 1 || admin.admin_type == 2 || admin.admin_type == 4){
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/tockas/for/admin/new",{
          admin_id:admin.id,
          token:token2
        }
      )
      const products = data;
      setTockas(products.tockas);
      setSelectedTockaId(data.tockas[0].id);
      if (products.tockas.length == 1){
        setSelectedTocka(products.tockas[0]);
      }
      innertockas = products.tockas;
      
    } else if (admin.admin_type == 5 || admin.admin_type == 6){
		const { data } = await axios.post(
		  "http://46.17.250.209:8081/get/tockas/for/sub/admin/new",{
			admin_id:admin.id,
			token:token2
		  }
		)
		const products = data;
		setTockas(products.tockas);
		setSelectedTockaId(data.tockas[0].id);
		if (products.tockas.length == 1){
		  setSelectedTocka(products.tockas[0]);
		}
		innertockas = products.tockas;
		
	  } 
    else {

      axios.post(
        "http://46.17.250.209:8081/get/tockas/by/admin/new",{
          admin_id:admin.id,
          token:token2
        }
      ).then(res=>{
        const products = res.data;
        innertockas = products.tockas;
        setTockas(products.tockas);
        setSelectedTockaId(products.tockas[0].id);
        setIsLoaded(true);
          if (products.tockas.length > 0){
            setSelectedTocka(products.tockas[0]);
            setProducts(products.tockas[0].products);
            setOrange(products.tockas[0].orange_data);
          } 
          
        }

      )
     }

    
  };

  const onButtonClickCert = () => {
    // `current` points to the mounted file input element
    inputCertFile.current.click();

  };

  const onButtonClickKey = () => {
    // `current` points to the mounted file input element
    inputKeyFile.current.click();

  };

  const onButtonClickCa = () => {
    // `current` points to the mounted file input element
    inputCaFile.current.click();

  };

  const onButtonClickPrivateKey = () => {
    // `current` points to the mounted file input element
    inputPrivateKeyFile.current.click();

  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    inputCertFileInput.current.value = 'готово к загрузке ' + file.name;
  };

  const handleChange2 = (event) => {
    const file = event.target.files[0];
    inputCertFileInput2.current.value = 'готово к загрузке ' + file.name;
  };

  const handleChange3 = (event) => {
    const file = event.target.files[0];
    inputCertFileInput3.current.value = 'готово к загрузке ' + file.name;
  };

  const handleChange4 = (event) => {
    const file = event.target.files[0];
    inputCertFileInput4.current.value = 'готово к загрузке ' + file.name;
  };

  const handleChangeType = (event) => {
    prdtype.current.value = event.target.value;
  };

  const handleChangeTaxSystem = (event) => {
    newTaxSystem.current.value = event.target.value;
  };

  const handleIpNameChange = (event) => {
    setIpName(event.target.value);
  }

  const handleInnChange = (event) => {
    setInn(event.target.value);
  }


  const tax_systems = ['Общая, ОСН','Упрощенная доход, УСН доход','Упрощенная доход минус расход, УСН доход - расход','Единый налог на вмененный доход, ЕНВД', 'Единый сельскохозяйственный налог, ЕСН','Патентная система налогообложения, Патент'];


  const product_types = ['','Стиральная машина','Сушильная машина','Гель для стирки', 'Кондиционер для стирки','Стирка обуви', 'Сушка обуви'];


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabChanged = (event,value)=>{
    setTimeout(() => {
      setValue(value)
      //console.log('Hello, World!')
    }, 200);
    
  }

//   interface TabPanelProps {
//     children?: React.ReactNode;
//     index: number;
//     value: number;
//   }

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleCancel = () => {
    // onClose();
    //console.log('ONCLOSE ' + selectedProduct.col2.product_type + ' we price ' + selectedProduct.col4.product_price);
    // prodycty.push(selectedProduct);
    fetchSelectedTockaProducts(selectedTockaId)
    


    setOpen(false)
  };

  const handleOk = () => {
    // onClose(value);
    setOpen(false)
  };

  const showChangeProductDialog = (pid) => {
    //console.log('GELEN PID ' + pid + ' open ' + open); 
    // var selectedProduct = prodycty.map.filter((prod)=> prod.id === pid)
    setOpen(true)
    //console.log('GELEN PID ' + pid + ' open ' + open); 

    return (<AddEditProduct />);
  }
  
  const columns = [
    { field: 'col1', headerName: '№', width: 2 ,sortable: false , align:'center'},
    { field: 'col2', headerName: 'Наименование', width: 150 ,sortable: false,
     renderCell: (params) => (
      <Stack direction='column' spacing={-0.5}>
        <Typography sx={{fontSize:15}}>{ params.value.product_type == 1 ? params.value.product_name + ' ' + params.value.product_capacity + ' кг.' : params.value.product_name}</Typography>
        <Typography sx={{fontSize:13}}>{params.value.product_name2}</Typography>
        { (typeof selectedTocka != 'undefined' && typeof selectedTocka.podpiska != 'undefined' && selectedTocka.podpiska.length > 0 && selectedTocka.podpiska[0] == '1') ?
         (params.value.product_type == 3 || params.value.product_type == 4 || params.value.product_type == 101 || params.value.product_type == 8) ?  
          params.value.product_stock <= ~~(((params.value.product_volume/params.value.product_unit)*params.value.product_stock_limit)/100) ? 
            <Box >
            <BorderLinearProgress2
              variant="determinate" 
              value={normalise(params.value.product_stock,params.value.product_volume,params.value.product_unit)} sx={{
                width: 140,
                height: 10,
                mt:0.5,
                
                // borderRadius:5,
                // barColor: '#1a90ff',
                // backgroundColor : '#fff'
              }}/>
            </Box>
            : <Box >
            <BorderLinearProgress 
              variant="determinate" 
              value={normalise(params.value.product_stock,params.value.product_volume,params.value.product_unit)} sx={{
                width: 140,
                height: 10,
                mt:0.5,
                
                // borderRadius:5,
                // barColor: '#1a90ff',
                // backgroundColor : '#fff'
              }}/>
            </Box>
            : ''
          :''
          }
        
        
      </Stack>
        
      )
    },
    { field: 'col3', headerName: 'Цена', width: 80 ,sortable: false, align:'center',
    renderCell: (params) => (
      <Stack direction='column' spacing={0}>
          <Typography sx={{fontSize:15, fontWeight:'bold', textAlign:'center'}}>{params.value.product_type == 7 ? '' : params.value.product_price}</Typography>
          { (typeof selectedTocka != 'undefined' && typeof selectedTocka.podpiska != 'undefined' && selectedTocka.podpiska.length > 0 && selectedTocka.podpiska[0] == '1') ? 
            (params.value.product_type == 3 || params.value.product_type == 4 || params.value.product_type == 4 || params.value.product_type == 8) ? 
            <Typography sx={{fontSize:13, textAlign:'center'}}>{params.value.product_stock}</Typography> 
            : ''
          : ''
          }
          </Stack>
        )},
    { field: 'col4', headerName: '', width: 5 ,sortable: false , align:'center',
  
    renderCell: (params) => (
      
       params.value.product_type == 7 ? '' : 
		<IconButton 
			sx={{
				backgroundColor:'#ffa726',
				'&:hover':{
				backgroundColor:'#ffa726'
				}
			}}
			color="primary" component="label" onClick={()=>{
			// //console.log('BASDYM ' + params.value.billid + ' we ' + params.value.inn);
			//console.log('ONREPAIR' + params.value.product_id);
			Swal.fire({
				title: "Вы уверены?",
				text: "Вы уверены что хотите ЗАПУСТИТЬ машинку под номером № "+params.value.product_order+" !",
				icon: "question",
				showDenyButton: true,
				confirmButtonText: 'Да',
				denyButtonText: 'Отмена',
			})
			.then((willDelete) => {
				if (willDelete.isConfirmed) 
				{
					//handleTockaSettingsClick(selectedTocka);
					socket2.emit('start_mashynka_dwa',selectedTockaId,params.value.product_order);
					socket.emit('start_mashynka_dwa',selectedTockaId,params.value.product_order);
					//console.log('SOGLASEN');
				} else 
				{
					

				//console.log('OTMENA');
				}
				});
			
			}}>
	  
			<BoltIcon sx={{color:'#fff' , fontSize: 15, width:20,height:20 }}/>
		</IconButton>
	
    ),},
    { field: 'col5', headerName: '', width: 5 ,sortable: false , align:'center',
  
    renderCell: (params) => (
      
		params.value.product_type == 7 ? '' : 
      <IconButton  
        sx={{
          backgroundColor: params.value.product_on_repair == true ? '#f57c00' : '#66bb6a',
          '&:hover':{
            backgroundColor: params.value.product_on_repair == true ? '#f57c00' : '#66bb6a'
          }
      , fontSize: 30
        }
      
      }
        color="primary" component="label" onClick={()=>{
        // //console.log('BASDYM ' + params.value.billid + ' we ' + params.value.inn);
        Swal.fire({
          title: "Вы уверены?",
          text: params.value.product_on_repair == true ? "Вы уверены что хотите вывести ИЗ РЕМОНТА машинку под номером № "+params.value.product_order+" ?" : "Вы уверены что хотите поставить В РЕМОНТ машинку под номером № "+params.value.product_order+" ?",
          icon: "question",
          showDenyButton: true,
          confirmButtonText: 'Да',
          denyButtonText: 'Отмена',
        })
        .then((willDelete) => {
          if (willDelete.isConfirmed) 
            {
              //handleTockaSettingsClick(selectedTocka);
              socket.emit('remont_mashynka',selectedTocka.id,selectedTocka.tocka_tablet_id, params.value.product_id, !params.value.product_on_repair);
              socket2.emit('remont_mashynka',selectedTocka.id,selectedTocka.tocka_tablet_id,params.value.product_id, !params.value.product_on_repair);
              fetchSelectedTockaProducts(selectedTockaId);
              //console.log('SOGLASEN');
            } else 
            {
            //console.log('OTMENA');
            }
          });
        
      }}>
        
        <BuildIcon sx={{color:'#fff' , fontSize: 15, width:20,height:20 }}/>
      </IconButton>
    ),},
    { field: 'col6', headerName: '', width: 5 ,sortable: false , align:'center',
  
	renderCell: (params) => (
      
		params.value.product_type == 7 ? '' : 
      <IconButton hidden
        sx={{
          backgroundColor:'#f57c00',
          '&:hover':{
            backgroundColor:'#f57c00'
          }
        }}
        color="primary" component="label" onClick={()=>{
        // //console.log('BASDYM ' + params.value.billid + ' we ' + params.value.inn);
        Swal.fire({
          title: "Вы уверены?",
          text: "Вы уверены что хотите УДАЛИТЬ машинку под номером № "+params.value.product_order+" !",
          icon: "question",
          showDenyButton: true,
          confirmButtonText: 'Да',
          denyButtonText: 'Отмена',
        })
        .then((willDelete) => {
          if (willDelete.isConfirmed) 
            {
              //handleTockaSettingsClick(selectedTocka);
              //console.log('SOGLASEN');
              axios.post(
                'http://46.17.250.209:8081/delete/tocka/product',
                {
                  pid:params.value.product_id,
                  token:token2,
                  tockaid:selectedTockaId
                }
              ).then(res => {
                if (res.data.success == 1){
                    Swal.fire("УСПЕХ","Данные успешно удалены!", "success",{
                      button:'OK'
                    })
                    .then((value) => {
                      //fetchProductsByTocka(selectedTocka.id);
                      fetchSelectedTockaProducts(selectedTockaId);
                    });
                } else {
                    Swal.fire("ОШИБКА", res.data.message, "error", {
                      button: 'OK'
                    });
                    
                }
              })
            } else 
            {
            //console.log('OTMENA');
            }
          });
        
      }}>
        
        <RemoveCircleIcon sx={{color:'#fff' , fontSize: 15, width:20,height:20 }}/>
      </IconButton>
    ),},

  ];

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#fff',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#66bb6a', //'#1a90ff' : '#308fe8'
    },
  }));

  const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#fff',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#ffa726', //'#1a90ff' : '#308fe8'
    },
  }));

  const normalise = (value,wsego,unit) => {
    var wsego2 = ~~(wsego / unit)
    var a = ~~(value * 100) / wsego2;
    //console.log('BOLMALY PROSNET ' + a);
    return a
  }

  const columns2 = [
    { field: 'col1', headerName: '№', width: 2 ,sortable: false , align:'center'},
    { field: 'col2', headerName: 'Наименование', width: 180 ,sortable: false,
     renderCell: (params) => (
      <Stack direction='column' spacing={-0.5}>
        <Typography sx={{fontSize:15}}>{params.value.product_name}</Typography>
        <Typography sx={{fontSize:13}}>{params.value.product_name2}</Typography>
        { (typeof selectedTocka != 'undefined' && typeof selectedTocka.podpiska != 'undefined' && selectedTocka.podpiska.length > 0 && selectedTocka.podpiska[0] == '1') ? 
        (params.value.product_type == 3 || params.value.product_type == 4 || params.value.product_type == 101) ?  
        <Box >
        <BorderLinearProgress 
          variant="determinate" 
          value={normalise(params.value.product_stock,params.value.product_volume,params.value.product_unit)} sx={{
            width: 140,
            height: 10,
            mt:0.5,
            
            // borderRadius:5,
            // barColor: '#1a90ff',
            // backgroundColor : '#fff'
          }}/>
        </Box>
        : ''
        : ''}
      </Stack>
        
      )
    },
    { field: 'col3', headerName: 'Цена', width: 80 ,sortable: false, align:'center',
    renderCell: (params) => (
      <Stack direction='column' spacing={0}>
      <Typography sx={{fontSize:15, fontWeight:'bold', textAlign:'center'}}>{params.value.product_type == 7 ? '' : params.value.product_price}</Typography>
      {(typeof selectedTocka != 'undefined' && typeof selectedTocka.podpiska != 'undefined' && selectedTocka.podpiska.length > 0 && selectedTocka.podpiska[0] == '1') ? 
        (params.value.product_type == 3 || params.value.product_type == 4 || params.value.product_type == 101 || params.value.product_type == 8) ? 
        <Typography sx={{fontSize:13, textAlign:'center'}}>{params.value.product_stock}</Typography> 
        : ''
      : ''
      }
      </Stack>
    )},
    { field: 'col4', headerName: '', width: 5 ,sortable: false , align:'center',
  
    renderCell: (params) => (
		params.value.product_type == 7 || admin.admin_type == 6 ? '' : 
      <IconButton 
        sx={{
          backgroundColor:'#ffa726',
          '&:hover':{
            backgroundColor:'#ffa726'
          }
        }}
        color="primary" component="label" onClick={()=>{
        // //console.log('BASDYM ' + params.value.billid + ' we ' + params.value.inn);
        //console.log('ONREPAIR' + params.value.product_id);
        Swal.fire({
          title: "Вы уверены?",
          text: "Вы уверены что хотите ЗАПУСТИТЬ машинку под номером № "+params.value.product_order+" !",
          icon: "question",
          showDenyButton: true,
          confirmButtonText: 'Да',
          denyButtonText: 'Отмена',
        })
        .then((willDelete) => {
          if (willDelete.isConfirmed) 
            {
              //handleTockaSettingsClick(selectedTocka);
              socket2.emit('start_mashynka_dwa',selectedTockaId,params.value.product_order);
              socket.emit('start_mashynka_dwa',selectedTockaId,params.value.product_order);
              //console.log('SOGLASEN');
            } else 
            {
            //console.log('OTMENA');
            }
          });
        
      }}>
        
        <BoltIcon sx={{color:'#fff' , fontSize: 15, width:20,height:20 }}/>
      </IconButton>
    ),},
    { field: 'col5', headerName: '', width: 5 ,sortable: false , align:'center',
  
    renderCell: (params) => (
		params.value.product_type == 7 || admin.admin_type == 6 ? '' : 
      <IconButton  
        sx={{
          backgroundColor: params.value.product_on_repair == true ? '#f57c00' : '#66bb6a',
          '&:hover':{
            backgroundColor: params.value.product_on_repair == true ? '#f57c00' : '#66bb6a'
          }
      , fontSize: 30
        }
      
      }
        color="primary" component="label" onClick={()=>{
        // //console.log('BASDYM ' + params.value.billid + ' we ' + params.value.inn);
        Swal.fire({
          title: "Вы уверены?",
          text: params.value.product_on_repair == true ? "Вы уверены что хотите вывести ИЗ РЕМОНТА машинку под номером № "+params.value.product_order+" ?" : "Вы уверены что хотите поставить В РЕМОНТ машинку под номером № "+params.value.product_order+" ?",
          icon: "question",
          showDenyButton: true,
          confirmButtonText: 'Да',
          denyButtonText: 'Отмена',
        })
        .then((willDelete) => {
          if (willDelete.isConfirmed) 
            {
              //handleTockaSettingsClick(selectedTocka);
              socket.emit('remont_mashynka',selectedTocka.id,selectedTocka.tocka_tablet_id, params.value.product_id, !params.value.product_on_repair);
              socket2.emit('remont_mashynka',selectedTocka.id,selectedTocka.tocka_tablet_id,params.value.product_id, !params.value.product_on_repair);
              fetchSelectedTockaProducts(selectedTockaId);
              //console.log('SOGLASEN');
            } else 
            {
            //console.log('OTMENA');
            }
          });
        
      }}>
        
        <BuildIcon sx={{color:'#fff' , fontSize: 15, width:20,height:20 }}/>
      </IconButton>
    ),},
    

  ];

  const genSalesData = (gunu) => {
	var labes = []
	var datasy = []

	if (tockaSales != null) {
		console.log('GENERATE ICINDE');
		console.log(tockaSales);
		console.log(tockaLocalSales);
		// console.log(tockaSales.success);
		const tc = tockaSales.datasy;//tockaSales.datasy;
	
		if (gunu == 1) {
			var sales = tc.today;
			sales.map((dt)=>{
				console.log(dt.gunu);
				labes.push(dt.gunu)
				datasy.push(dt.summa)
				console.log('HANY ' + maxValue + ' WE ' + dt.summa);
				if (maxValue <= dt.summa ) {
					setMaxValue(dt.summa + 100)
				}
			})
		} else if (gunu == 2) {
			
			var sales = tc.nedelya;
			if (typeof sales != 'undefined') {
				sales.map((dt)=>{
				
					labes.push(dt.gunu)
					datasy.push(dt.summa)
					if (maxValue < dt.summa ) {
						setMaxValue(dt.summa + 1000)
					}
				})
			}
			
		} else if (gunu == 3) {
			var sales = tc.mesyas;
			sales.map((dt)=>{
				labes.push(dt.gunu)
				datasy.push(dt.summa)
				if (maxValue < dt.summa ) {
					setMaxValue(dt.summa + 1000)
				}
			})
		} else if (gunu == 4) {
			var sales = tc.god;
			sales.reverse().map((dt)=>{
				labes.push(dt.gunu)
				datasy.push(dt.summa)
				if (maxValue < dt.summa ) {
					setMaxValue(dt.summa + 1000)
				}
			})
		}
	
		console.log(labes);
		console.log(datasy);
	
		setChartLabels(labes)
		setSalesData(datasy)
		
	}

	console.log('GUNU ' + gunu + ' REPORT NUMBER ' + reportNumber);
	
  }

  const handleTockaChange = (event) => {
    // setValue(event.target.value);
    
    setSelectedTocka(tockas.filter((tocka)=>{return tocka.id === event.target.value})[0]);
    setSelectedTockaId(event.target.value);
	
	

  };

  const handleProductTypeChange = (event) => {
    //console.log('SAYLANAN ' + event.target.value);
  }

  const getVersionText=()=>{

	if (typeof tockas != 'undefined' && typeof tockas[0] != 'undefined' && typeof selectedTocka != 'undefined' && selectedProductId != 0) {
		if (admin.admin_type == 1) {
		return selectedTocka.tversion + ' ' + selectedTocka.id ; }
		else {
			return 'v.'+ selectedTocka.tversion
		}
	} else {
		return '-'
	}
  }

  const getTockaOplata = () => {
	console.log('ARATAPAWUT OPLATA' + oplata);
	switch (oplata) {
		case 1:
			return 'ЕСТЬ НЕОПЛАЧЕННЫЕ ПОДПИСКИ'
			break;
		case 2:
			return 'ЕСТЬ НЕОПЛАЧЕННЫЕ ПОДПИСКИ'
			
			break;
		case 3:
			return 'ИМЕЮТСЯ, НЕОПЛАЧЕННЫЕ ПОДПИСКИ'
			break;
		
		case 4:
			return 'НЕ НАСТРОЕНЫ ПОДПИСКИ'
			break;
	
		default:
			return ''
			break;
	}
	//oplata == 3 ? '#ffa726' : '#eafefd'
	//return '#ffa726'
  }

  const getTockaBackground = () => {
	console.log('ARATAPAWUT OPLATA' + oplata);
	switch (oplata) {
		case 1:
			return '#ffa726'
			break;
		case 2:
			return '#f8ed62'
			
			break;
		case 3:
			return '#d32f2f'
			break;
		
		case 4:
			return '#f8ed62'
			break;
	
		default:
			return '#eafefd'
			break;
	}
	//oplata == 3 ? '#ffa726' : '#eafefd'
	//return '#ffa726'
  }

  const getTockaData = (wich_data) => {

	if (typeof tockas != 'undefined' && typeof tockas[0] != 'undefined' && typeof selectedTocka != 'undefined') {
		if (selectedTockaId != 0) {
			switch (wich_data) {
				case 'tocka_name':
					return tockas.filter((toc)=>{ return toc.id === selectedTockaId })[0].tocka_name 
					break;
				case 'tocka_phone':
					return tockas.filter((toc)=>{ return toc.id === selectedTockaId })[0].tocka_phone 
					break;
			
				default:
					break;
			}
		} else {
			switch (wich_data) {
				case 'tocka_name':
					return tockas[0].tocka_name 
					break;
				case 'tocka_phone':
					return tockas[0].tocka_phone 
					break;
			
				default:
					break;
			}
			
		}
	} else {
		return '-'
	}
  }


  return (
    
    <div><Header selected = {0} />
      {/* <h2 className='body2'>Список прачечных</h2> */}

      <Box sx={{ flexGrow: 1 }} margin = {2}>

      {productyLoaded == true ? <AddEditProduct open={open} onClose={handleCancel} selectedProduct={selectedProduct} selectedTocka={selectedTocka} selectedProductTypeId={selectedProductTypeId} socket={socket} socket2={socket2}/> : '' }
      
      
      

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}  >

      	<Grid item xs={12} sm={6} md={3}  >
            

                  <Grid item xs={12} sm={12} md={12} >
                    <Item2 elevation='0' sx={{
                
                        height: '100%',
                        backgroundColor: '#eafefd',
                        // opacity: [0.3, 0.4, 0.3],
                        '&:hover': {
                          backgroundColor: '#eaf5fe',
                          // opacity: [0.3, 0.4, 0.3],
                        },
                      }}>
                    <div>
                    <Typography variant="h5" color="#000">ВЫБОР ПРАЧЕЧНОЙ</Typography>
                            <br/>

                            <FormControl sx={{ width:'100%' }}>
                              <Select
                                size='small'
                                value={selectedTockaId}
                                onChange={handleTockaChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                              >
                                
                                {
                                  tockas && tockas.map(tocka=>(
                                                          
                                                            
                                                            
                                    <MenuItem value={tocka.id}>{tocka.tocka_address}</MenuItem>
                                  ))
                                }
                                

                                
                              </Select>
                              <FormHelperText>Выберите из выпадающего списка</FormHelperText>
                            </FormControl>
                              <br/><br/>
                              
                            </div>
                    </Item2>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} mt={1}>
                      <Item2 elevation='0' sx={{
                  
                      height: '100%',
                      backgroundColor:   getTockaBackground() 
						 
					   ,
                      
                      variant: 'outlined',
                      // opacity: [0.3, 0.4, 0.3],
                      '&:hover': {
                        backgroundColor: '#eaf5fe',
                        // opacity: [0.3, 0.4, 0.3],
                      },
                    }}>
          {/* sx = {{fontFamily : ['Helvetica Neue']}}  #29b6f6 8-800-700-32-70  */}
                    <Stack direction='column' sx={{
                      spacing: -5
                    }}>

					<Box sx={{width:'100%', 
                    backgroundColor:'transparent',
                    borderRadius: 1,
                    pl:1,
                    pr:1,
                    
                    }}>
                      
                      <Typography sx={{fontWeight: 'bold', fontSize:20}} variant="h4" color="#000"> {
                    
                    	getTockaOplata()
                      
                      } 
                      </Typography></Box>


                    <Box sx={{width:'100%', 
                    backgroundColor:'transparent',
                    borderRadius: 1,
                    pl:1,
                    pr:1,
                    
                    }}>
                      
                      <Typography sx={{fontWeight: 'bold', fontSize:35}} variant="h4" color="#000"> {
                    
                    	getTockaData('tocka_name')
                      
                      } 
                      </Typography></Box>
                      <Stack direction='row'>
                      <Box sx={{width:'50%', 
							backgroundColor:'transparent',
							borderRadius:1,
							pl:1,
							pr:1,
							pt:-5,
							mt:-0.7
							}}>
							
							<Typography sx={{fontStyle: 'bold'}} variant="h6" color="#000"> {
							
								getTockaData('tocka_phone')
							
							} 
                      </Typography></Box>
                      <Box sx={{width:'50%', alignItems:'right', justifyContent:'right', display:'flex', align:'right', 
                      
						backgroundColor:'transparent'}}>
							<Box sx={{ 
							align:'right',
							width:'100%',
							backgroundColor:'transparent',
							borderRadius:1,
							pl:1,
							pr:0,
							pt:-5,
							mt:-0.7
							}}>
                      
							<Typography textAlign="right" sx={{fontWeight: 'bold', fontSize:17}} variant="h6" color="#000"> {
							getVersionText()		
								
							} 
							</Typography>
						
					  </Box></Box>
                      
                      </Stack>
                    </Stack>
                    
                    
                      <br/>
					  
                      <Typography variant="h2" color="#000" textAlign="right" sx={{fontWeight: 'bold', display: 
					  admin.admin_type == 1 || admin.admin_type == 6 || admin.admin_type == 0 ?  'block' : 'none'}}>
					  
                      {
                    
                        typeof tockas != 'undefined' ? 
                        
                          typeof tockas[0] != 'undefined' ? 
                            typeof selectedTocka != 'undefined' ? 
                              selectedTockaId != 0 ?
                        
                                typeof selectedTocka.summa != 'undefined' ? 
                                  selectedTocka.summa == null ?
                                  0 + '  ' + parse(typeof selectedTocka.currencysign != 'undefined' ? selectedTocka.currencysign : 'P')//parse(selectedTocka.currencysign) 
                                  : selectedTocka.summa + '  ' + parse(typeof selectedTocka.currencysign != 'undefined' ? selectedTocka.currencysign : 'P')//parse(selectedTocka.currencysign) 
                                    //: selectedTocka.summa + '  ' + parse(selectedTocka.currencysign) 
                                
                                    : 0  + '  ' + parse(typeof selectedTocka.currencysign != 'undefined' ? selectedTocka.currencysign : 'P')//parse(selectedTocka.currencysign)  
                        
                              : '-'
                        
                            : '-' 
                          : '-'
                    
                        : '-'

                      
                      
                      } 
                      
                      
                      </Typography>
                      <Stack direction='row' xs={12} sm={12} md={12} >
                        <Stack direction = 'row' spacing={1} xs={12} sm={12} md={12} mt={1} 
                          sx={{ height:70}}>
                            
                          <Item2 xs={1} sm={1} md={1} mt={1}
                          //f44336 // d32f2f
                          
                            elevation='0' sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justify: 'center',
                                  width: 60,
                                  height: '100%',
                                  backgroundColor:  typeof selectedTocka != 'undefined' ? selectedTocka.tocka_online == true ? '#66bb6a' : '#f44336' : '#f44336',
                                  // opacity: [0.3, 0.4, 0.3],
                                  '&:hover': {
                                    backgroundColor: typeof selectedTocka != 'undefined' ? selectedTocka.tocka_online == true ? '#388e3c' : '#d32f2f' : '#d32f2f',
                                    // opacity: [0.3, 0.4, 0.3],
                                  },
                                }}>
                                  <Stack direction='column'>
                                  { typeof selectedTocka != 'undefined' ? selectedTocka.tocka_online == true ? <WifiTetheringOutlinedIcon sx={{fontSize: 40, color:'#fff'}}/> : <WifiTetheringOffOutlinedIcon sx={{fontSize: 40, color:'#fff'}}/> : <WifiTetheringOffOutlinedIcon sx={{fontSize: 40, color:'#fff'}}/>}

                                  <Typography sx={{color:'#fff', fontSize:12, textAlign:'center', fontWeight: 'bold'}}>связь</Typography>
                                  </Stack>

                                
                                  
                          </Item2>

                          <Item2 xs={1} sm={1} md={1} mt={1}
                          elevation='0' sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justify: 'center',
                                  width: 60,
                                  height: '100%',
                                  backgroundColor:  typeof selectedTocka != 'undefined' ? selectedTocka.tocka_arduino == true ? '#66bb6a' : '#f44336' : '#f44336',
                                  // opacity: [0.3, 0.4, 0.3],
                                  '&:hover': {
                                    backgroundColor: typeof selectedTocka != 'undefined' ? selectedTocka.tocka_arduino == true ? '#388e3c' : '#d32f2f' : '#d32f2f',
                                    // opacity: [0.3, 0.4, 0.3],
                                  },
                                }}>

                                <Stack direction='column'  sx={{justify:'center'}}>
                                { typeof selectedTocka != 'undefined' ? selectedTocka.tocka_arduino == true ? <DomainRoundedIcon sx={{fontSize: 40, color:'#fff', justify:'center'}}/> : <DomainDisabledRoundedIcon sx={{fontSize: 40, color:'#fff', justify:'center'}}/> : <DomainDisabledRoundedIcon sx={{fontSize: 40, color:'#fff', justify:'center'}}/>}

                                <Typography sx={{color:'#fff', fontSize:12, textAlign:'center', fontWeight: 'bold'}}>плата</Typography>
                                </Stack>
                                  
                          </Item2>

                          <Item2 xs={1} sm={1} md={1} mt={1}
                          elevation='0' sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justify: 'center',
                                  width: 60,
                                  height: '100%',
                                  backgroundColor:  typeof selectedTocka != 'undefined' ? selectedTocka.tocka_power == true ? '#66bb6a' : '#f44336' : '#f44336',
                                  // opacity: [0.3, 0.4, 0.3],
                                  '&:hover': {
                                    backgroundColor: typeof selectedTocka != 'undefined' ? selectedTocka.tocka_power == true ? '#388e3c' : '#d32f2f' : '#d32f2f',
                                    // opacity: [0.3, 0.4, 0.3],
                                  },
                                }}>

                              <Stack direction='column'  sx={{display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justify: 'center',}}>
                              { typeof selectedTocka != 'undefined' ? selectedTocka.tocka_power == true ? <PowerOutlinedIcon sx={{fontSize: 40, color:'#fff'}}/> : <PowerOffOutlinedIcon sx={{fontSize: 40, color:'#fff'}}/> : <PowerOffOutlinedIcon sx={{fontSize: 40, color:'#fff'}}/>}

                              <Typography sx={{color:'#fff', fontSize:12, textAlign:'center', fontWeight: 'bold'}}>питание</Typography>
                              </Stack>

                                  
                          </Item2>

                        </Stack>

                        <Stack direction = 'row' spacing={2} xs={12} sm={12} md={12} mt={1} 
                          sx={{ 
                            display: 'flex',
                            alignItems:'right',
                            width:'100%',
                            height:70, 
                            justifyContent:'flex-end',
                            justify:'end'
                          }}>

{ admin.admin_type == 6 ? '' : 
						

						<ButtonGroup 
                            variant="contained" 
                            aria-label="outlined primary button group"
                            sx={{justify:'right'}}>

							

                            <Button   sx={{ color:'blue'}} onClick={()=>{

                                  Swal.fire({
                                    title: "Вы уверены?",
                                    text: "Вы уверены что хотите переключится в НАСТРОЙКИ в приложении!",
                                    icon: "question",
                                    showDenyButton: true,
                                    confirmButtonText: 'Да',
                                    denyButtonText: 'Отмена',
                                  })
                                  .then((willDelete) => {
                                    if (willDelete.isConfirmed) 
                                      {
                                        handleTockaSettingsClick(selectedTocka);
                                        //console.log('SOGLASEN');
                                      } else 
                                      {
                                      //console.log('OTMENA');
                                      }
                                    });

                            }}>
                              <SettingsOutlinedIcon sx={{fontSize: 40, color:'#fff'}}/>
                              </Button>
                            <Button onClick={()=>{
                              Swal.fire({
                                title: "Вы уверены?",
                                text: "Вы уверены что хотите ПЕРЕЗАГРУЗИТЬ приложении!",
                                icon: "question",
                                
                                showDenyButton: true,
                                confirmButtonText: 'Да',
                                denyButtonText: 'Отмена',
                              })
                              .then((willDelete) => {
                                if (willDelete) 
                                  {
                                    handleTockaRestartClick(selectedTocka);
                                      //console.log('SOGLASEN');
                                  } else 
                                  {
                                  //console.log('OTMENA');
                                  }
                                });
                            }}>
                              
                              <RestartAltOutlinedIcon sx={{fontSize: 40, color:'#fff'}}/>
                            </Button>
                          </ButtonGroup>

						  
						
}
                            
                            

                        </Stack>
						
                      </Stack>
					  <Stack direction = 'row' spacing={2} xs={12} sm={12} md={12} mt={1} 
                          sx={{ 
                            display: 'flex',
                            alignItems:'right',
                            width:'100%',
                            height:40, 
                            justifyContent:'flex-end',
                            justify:'end'
                          }}>
							<Button sx={{ color:'black',backgroundColor:'orange'}} 
							onClick={()=>{
                              Swal.fire({
                                title: "Вы уверены?",
                                text: "Вы уверены что хотите ОБНУЛИТЬ ОСТАТОК на терминале!",
                                icon: "question",
                                
                                showDenyButton: true,
                                confirmButtonText: 'Да',
                                denyButtonText: 'Отмена',
                              })
                              .then((willDelete) => {
                                if (willDelete) 
                                  {
                                    //handleTockaRestartClick(selectedTocka);
									handleTockaResetOstatokClick(selectedTocka);
                                      //console.log('SOGLASEN');
                                  } else 
                                  {
                                  //console.log('OTMENA');
                                  }
                                });
							}}
							
							>ОБНУЛИТЬ ОСТАТОК</Button>
						  </Stack>
                      
            
                    </Item2>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} mt={1}>
                  {/* <Item2 elevation='0' sx={{
                
                    height: '100%',
                    backgroundColor: '#eafefd',
                    // opacity: [0.3, 0.4, 0.3],
                    '&:hover': {
                      backgroundColor: '#eaf5fe',
                      // opacity: [0.3, 0.4, 0.3],
                    },
                  }}> */}
                  {/* <Box sx={{ width: '100%' }}>


      
                  </Box> */}

                  <Box elevation='0' sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius:"3px",
                    backgroundColor: '#eafefd',
                    // opacity: [0.3, 0.4, 0.3],
                    '&:hover': {
                      backgroundColor: '#eaf5fe',
                      // opacity: [0.3, 0.4, 0.3],
                    },
                  }}>
                    <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth" 
                    
                    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
                    
                    sx={{
                      '& .MuiTabs-indicator': {
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'transparent',
                      },
                      '& .MuiTabs-indicatorSpan': {
                        maxWidth: 40,
                        width: '100%',
                        backgroundColor: '#000',
                      },
                    }}>
                      <Tab {...a11yProps(0)}
                        wrapped label="Список машинок" disableRipple sx={{
                        textTransform: 'none',
                        fontSize: 14,
                        color: 'rgba(0, 0, 0, 0.5)',
                        '&.Mui-selected': {
                          color: '#000',
                        },
                        '&.Mui-focusVisible': {
                          backgroundColor: 'rgba(100, 95, 228, 0.32)',
                        },
                        marginRight: 1,
                      }}/>
					  {
	admin.admin_type == 6 ? '' : 

                      <Tab {...a11yProps(1)}
                        wrapped label="Орандж Дата" disableRipple sx={{
                        textTransform: 'none',
                        fontSize: 14,
                        color: 'rgba(0, 0, 0, 0.5)',
                        '&.Mui-selected': {
                          color: '#000',
                        },
                        '&.Mui-focusVisible': {
                          backgroundColor: 'rgba(100, 95, 228, 0.32)',
                        },
                        marginRight: 1,}}/>
					  }


                      <Tab {...a11yProps(2)}
                        wrapped label="Рабочее время" disableRipple sx={{
                        textTransform: 'none',
                        fontSize: 14,
                        color: 'rgba(0, 0, 0, 0.5)',
                        '&.Mui-selected': {
                          color: '#000',
                        },
                        '&.Mui-focusVisible': {
                          backgroundColor: 'rgba(100, 95, 228, 0.32)',
                        },
                        marginRight: 1,}}/>

                      
					  	{
							admin.admin_type < 2 
							? 
								<Tab {...a11yProps(2)}
								wrapped label="Админи- стратор" disableRipple sx={{
								textTransform: 'none',
								fontSize: 14,
								color: 'rgba(0, 0, 0, 0.5)',
								'&.Mui-selected': {
								color: '#000',
								},
								'&.Mui-focusVisible': {
								backgroundColor: 'rgba(100, 95, 228, 0.32)',
								},
								marginRight: 1,}}/>
							: ''
						}
					
                    </Tabs>
                    <Box sx={{ p: 0 }} />
                    <TabPanel value={tabIndex} index={0}>
                    <Box sx={{
                        height:550,
                        
                        
                      }}>
                      
                      <DataGrid 

                          onCellDoubleClick={  async (params, event) => {
                            //console.log('DOUBLE CLICKED ' + params.row.col1 + ' ' + params.row.col2.product_name + ' params.col4.product_id '  + ' ' + params.id);
                            if (admin.admin_type != 6 ) {
								if (typeof prodycty != 'undefined') {
                              //console.log(prodycty[0]);

                              //console.log('TAPDYM ' + prodycty.filter((prod)=> { return prod.id === params.id})[0].col2.product_name);
                              setSelectedProduct(prodycty.filter((prod)=> { return prod.id === params.id})[0])
                              //console.log("SET SELECTED PRODUCT TYPE ID " + prodycty.filter((prod)=> { return prod.id === params.id})[0].col2.product_type);
                              setSelectedProductTypeId(prodycty.filter((prod)=> { return prod.id === params.id})[0].col2.product_type)
                            } else {
                              //console.log('PRODYCTY NULL');
                            }
                            setProductyLoaded(true)
                            setOpen(true);

                            if (!event.ctrlKey) {
                              event.defaultMuiPrevented = true;
                            }
							}
                          }}

                        // paginationMode="server"
                        disableColumnMenu
                        slots={{
                          loadingOverlay: LinearProgress,
                        }}
                        loading = {loading}
                        height={550}
                        disableColumnFilter
                        hideFooterPagination
                        hideFooter
                        pagination = 'false'
                        rows={prodycty}
                        {...prodycty}
                        rowCount={typeof prodycty != 'undefined' ? prodycty.length : 0}
                        
                        columns={admin.admin_type != 1 ? columns2 : columns}
                        pageSize={typeof prodycty != 'undefined' ? prodycty.length : 0}
                        pageSizeOptions={[100]}
                    
                    />
                      
                      
                      </Box>
                    </TabPanel>
                    
					{ admin.admin_type == 6 ? '' : 
						<TabPanel value={tabIndex} index={1}>
                    <Box sx={{
                        height:350,
                        
                        
                      }}>Item Two</Box>
                      
                    </TabPanel>}

                    <TabPanel value={tabIndex} index={admin.admin_type == 6 ? 1 : 2}>
                      
                      <Box sx={{
                        height:350,
                        p:1
                        
                      }}>
                        
                        <LocalizationProvider 
                        localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                        dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['MobileTimePicker', 'MobileTimePicker']} sx={{m:0,width:'100%'}} spacing={2}>

                            <DemoItem label="Время открытия" sx={{width:'50%',}} >
                            { timeEditing == true ? 
                              <MobileTimePicker 
                                
                                sx={{
                                  
                                  input:'#000',
                                  '&:disabled':{
                                    input:'#000'
                                  }
                                }}
                                inputRef = {startWorkTime}
                                defaultValue={dayjs(typeof selectedTocka != 'undefined' ? '2022-04-17T'+selectedTocka.tocka_start_time : '2022-04-17T09:00:00' )} ampm={false} ampmInClock={false}/>
                             : 
                             <MobileTimePicker 
                                disabled
                                inputRef = {startWorkTime}
                                sx={{
                                  
                                  input:'#000',
                                  '&:disabled':{
                                    input:'#000'
                                  }
                                }}
                                defaultValue={dayjs(typeof selectedTocka != 'undefined' ? '2022-04-17T'+selectedTocka.tocka_start_time : '2022-04-17T09:00:00' )} ampm={false} ampmInClock={false}/>
                             } 
                              
                            </DemoItem>
                            <DemoItem label="Время закрытия" sx={{width:'50%',}}>
                              { timeEditing == true ? 
                                <MobileTimePicker
                                inputRef = {endWorkTime}
                                defaultValue={dayjs(typeof selectedTocka != 'undefined' ? '2022-04-17T'+selectedTocka.tocka_end_time : '2022-04-17T18:00:00' )} ampm={false} ampmInClock={false}/>
                               : 
                               <MobileTimePicker
                                disabled
                                inputRef = {endWorkTime}
                                defaultValue={dayjs(typeof selectedTocka != 'undefined' ? '2022-04-17T'+selectedTocka.tocka_end_time : '2022-04-17T18:00:00' )} ampm={false} ampmInClock={false}/>}
                              </DemoItem>

                              </DemoContainer>
                              </LocalizationProvider>

                              
                              { timeEditing == true ? 
                                <Stack direction='row' spacing = {2} sx={{width:'100%',mt:2}}>
                                <LoadingButton 
                                    disableElevation={true} sx={{
                                    width:'50%',
                                    backgroundColor: 'warning.light',
                                    pt:1, pb:1,
                                    '&:hover': {
                                      backgroundColor: 'warning.main',
                                      // opacity: [0.3, 0.4, 0.3],
                                    },
                                  }}
                                    //onClick={onClose}
                                    // loading={loading}
                                    onClick={()=>{
                                      setTimeEditing(false)
                                    }}
                                    
                                    loadingPosition="center"
                                    variant="contained"
                                  ><span>ОТМЕНИТЬ</span>
                                </LoadingButton> 

                               
									<LoadingButton 
                                    disableElevation={true} sx={{
                                      width:'50%',
                                    backgroundColor: 'primary.light',
                                    pt:1, pb:1,
                                    '&:hover': {
                                      backgroundColor: 'primary.main',
                                      // opacity: [0.3, 0.4, 0.3],
                                    },
                                  }}
                                    // onClick={}
                                    loading={loading}
                                    onClick={()=>{
                                      setLoading(true)

                                      //console.log('WAGT ' + startWorkTime.current.value)
                                      var startTime = startWorkTime.current.value + ':00'
                                      var endTime = endWorkTime.current.value + ':00'
                                      const isDateValid = (date = "") => {     
                                      const dateRegex = /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?$/ ;///^(00|[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])$/;
                                            var today = new Date();
                                            var date2 = today.getFullYear() + '-0' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + date;
                                          var parts = date.split(':');
                                          let parsedDate = new Date(today.getFullYear(),today.getMonth(),today.getDate(),parts[0],parts[1],parts[2]);   
                                          return parsedDate.toString() !== "Invalid Date"; 
                                          }

                                  if (isDateValid(startTime) && isDateValid(endTime)){
                                    var formData = new FormData();
                                    formData.append('endtime',endTime);
                                    formData.append('starttime',startTime);
                                    formData.append('tockaid',selectedTocka.id);
                                    formData.append('token','mesanatoken');
                                    formData.append('tabletid',selectedTocka.tocka_tablet_id);
                                      
                                    //console.log('BURDAMYSYN ABI');
                          
                                    axios.post(
                                        'http://46.17.250.209:8085/update/tocka/work/time',
                                        {
                                          tockaid:selectedTocka.id,
                                          token:'mesanatoken',
                                          starttime:startTime,
                                          endtime:endTime,
                                          tabletid:selectedTocka.tocka_tablet_id
                                        }
                                    )
                                    .then(res => {
                                        if (res.data.success == 1){
                                          Swal.fire("УСПЕХ", 'Данные успешно обновлены ! ', "success", {
                                            button: 'OK'
                                          })
                                          .then((value) => {
                                            //fetchProductsByTocka(selectedTocka.id);
                                            selectedTocka.tocka_start_time = startTime
											selectedTocka.tocka_end_time = endTime
                                            setSelectedTockaId(selectedTocka.id)
                                            setTimeEditing(false)
                                          });
                                        } else {
                                          Swal.fire("ОШИБКА", 'ЧТО ТО ПОШЛО НЕ ТАК', "error", {
                                            button: 'OK'
                                          })
                                          .then((value) => {
                                          });
                                        }
                                    })
                                    .catch(err => {
                                        //////console.log(err);
                                    })
                                  } else {
                                    Swal.fire("ОШИБКА", 'НЕВЕРНЫЙ ФОРМАТ ВРЕМЕНИ', "error", {
                                      button: 'OK'
                                    })
                                    .then((value) => {
                                      
                                    });
                                  }


                                      
                                      setTimeout(function () {
                                        setLoading(false)
                                        // onClose();
                                    }, 200);

                                      

                                    }}
                                    loadingPosition="center"
                                    variant="contained"
                                  ><span>СОХРАНИТЬ</span>
                                  </LoadingButton>
                                
								
								</Stack>
                              
                               :
                               <Stack direction='row' spacing = {2} sx={{width:'100%',mt:2}}>
                                <Box sx={{width:'50%'}}></Box>
								{
									admin.admin_type == 6 ? '' : 
                                <LoadingButton 
                                    disableElevation={true} sx={{
                                      width:'50%',
                                    backgroundColor: '#66bb6a',
                                    pt:1, pb:1,
                                    '&:hover': {
                                      backgroundColor: '#388e3c',
                                      // opacity: [0.3, 0.4, 0.3],
                                    },
                                  }}
                                    // onClick={}
                                    loading={loading}
                                    onClick={()=>{
                                      setLoading(true)
                                      setTimeEditing(true)
                                      setTimeout(function () {
                                        setLoading(false)
                                        // onClose();
                                    }, 200);

                                      

                                    }}
                                    loadingPosition="center"
                                    variant="contained"
                                  ><span>ИЗМЕНИТЬ</span>
                                  </LoadingButton>
								}
                                </Stack> 
                              
                              }



                            
                            
                        
                        


                      </Box>
                    </TabPanel>
					{
							admin.admin_type < 2 
							? 
							<TabPanel value={tabIndex} index={3} sx={{pb:2}}>
                      
								<Box sx={{
									height:380,
									
									
								}}>

								<Stack direction='row' spacing={2} sx={{m:2}}>
								<TextField
											sx={{width:'50%'}}
											id="outlined-required"
											label="Пользователь"
											disabled
											// inputRef={promocodeRef}
											// ref={genRef}
											defaultValue={typeof selectedTocka != 'undefined' ? selectedTocka.admin_phone : ''}
										/>

								<TextField
											sx={{width:'50%'}}
											id="outlined-required"
											label="Пароль"
											disabled
											// inputRef={promocodeRef}
											// ref={genRef}
											defaultValue={typeof selectedTocka != 'undefined' ? selectedTocka.admin_pass : ''}
										/>
								</Stack>

								<Typography h6 sx={{ml:2,mt:3}}>Уведомлять в Телеграм боте:</Typography>

								<Stack direction='row' width={'100%'} sx={{mt:0,alignContent:'center',alignItems:'center'}}>
									<Typography sx={{ml:2,width:'80%',fontSize:16,fontWeight:'bold',height:'100%'}}>О Продажах</Typography>
									<Switch checked={typeof warns != 'undefined' ? warns[0] == 1 ? true : false : false }  color="warning" sx={{alignContent:'end'}} 
										onChange={ (e)=>{
											console.log(e.target.value);
											if (e.target.checked == true ) {
												setWarns('1' + warns[1] + warns[2]+ warns[3] + warns[4])
											} else {
												setWarns('0' + warns[1] + warns[2]+ warns[3] + warns[4])
											}

										} }/>
									
								</Stack>
								<Stack direction='row' width={'100%'} sx={{mt:-1,alignContent:'center',alignItems:'center'}}>
									<Typography sx={{ml:2,width:'80%',fontSize:16,fontWeight:'bold',height:'100%'}}>О Сбоях (Связь, Плата, Питание)</Typography>
									<Switch checked={typeof warns != 'undefined' ? warns[1] == 1 ? true : false : false }  color="warning" sx={{alignContent:'end'}} 
										onChange={ (e)=>{
											console.log(e.target.value);
											if (e.target.checked == true ) {
												setWarns(warns[0] + '1' + warns[2] + warns[3] + warns[4])
											} else {
												setWarns(warns[0] + '0' + warns[2] + warns[3] + warns[4])
											}

										} }/>
								</Stack>

								<Stack direction='row' width={'100%'} sx={{mt:-1,alignContent:'center',alignItems:'center'}}>
									<Typography sx={{ml:2,width:'80%',fontSize:16,fontWeight:'bold',height:'100%'}}>О непробитии чека</Typography>
									<Switch checked={typeof warns != 'undefined' ? warns[3] == 1 ? true : false : false }  color="warning" sx={{alignContent:'end'}} 
										onChange={ (e)=>{
											console.log(e.target.value);
											if (e.target.checked == true ) {
												setWarns(warns[0] + warns[1] + warns[2] + '1' + warns[4])
											} else {
												setWarns(warns[0] + warns[1] + warns[2] + '0' + warns[4])
											}

										} }/>
								</Stack>

								<Stack direction='row' width={'100%'} sx={{mt:-1,alignContent:'center',alignItems:'center'}}>
									<Typography sx={{ml:2,width:'80%',fontSize:16,fontWeight:'bold',height:'100%'}}>О снижении уровня заряда</Typography>
									<Switch checked={typeof warns != 'undefined' ? warns[4] == 1 ? true : false : false }  color="warning" sx={{alignContent:'end'}} 
										onChange={ (e)=>{
											console.log(e.target.value);
											if (e.target.checked == true ) {
												setWarns(warns[0] + warns[1] + warns[2] + warns[3] + '1')
											} else {
												setWarns(warns[0] + warns[1] + warns[2] + warns[3] + '0')
											}

										} }/>
								</Stack>

								{ typeof selectedTocka != 'undefined' ? 
									typeof selectedTocka.podpiska != 'undefined' ?
									selectedTocka.podpiska.length > 0 ?
									selectedTocka.podpiska[0] == 1 ? 
									<Stack direction='row' width={'100%'} sx={{mt:-1,alignContent:'center',alignItems:'center'}}>
									<Typography sx={{ml:2,width:'80%',fontSize:16,fontWeight:'bold',height:'100%'}}>О Лимитах</Typography>
									<Switch checked={typeof warns != 'undefined' ? warns[2] == 1 ? true : false : false }   color="warning" sx={{alignContent:'end'}} 
										onChange={ (e)=>{
										console.log(e.target.value);
										if (e.target.checked == true ) {
											setWarns(warns[0] + warns[1] + '1' + warns[3] + warns[4])
										} else {
											setWarns(warns[0] + warns[1] + '0' + warns[3] + warns[4])
										}

									} }

									/>
									</Stack>
									: ''
									: ''
									: ''
									: ''
									}

								

								<Stack spacing={2} direction='row'  sx={{m:2}}>
									<TextField
										// type="number"
										InputProps={{
										startAdornment: <InputAdornment position="start">@</InputAdornment>,
										min: 4, max: 10 
										}}
										
										// inputProps={{type: 'number'}}
										sx={{width:'50%'}}
										id="outlined-required"
										label="Имя пользователя в Телеграме"
										inputRef={tgNameRef}
										// ref={tgNameRef}
										defaultValue={typeof selectedTocka != 'undefined' ? selectedTocka.tgname : ''}
									/>
								<LoadingButton disableElevation={true} sx={{
									backgroundColor: 'primary.light',
									
									'&:hover': {
										backgroundColor: 'primary.main',
										// opacity: [0.3, 0.4, 0.3],
									},
									width:'50%'
									}}
									// onClick={}
									loading={loading}
									onClick={()=>{
										console.log('WANR ONE ' + warns + ' TGNAME ' + tgNameRef.current.value);
										var tgname = selectedTocka.tgname;
										selectedTocka.tgname = tgNameRef.current.value;
										setLoading(true)
										

										axios.post(
											"http://46.17.250.209:8081/set/own/warns",{
											token:'mesanatoken',
											warns: warns,
											tgname: tgNameRef.current.value,
											tockaid: selectedTocka.id,
											adminid: selectedTocka.tocka_admin_id
											}
										).then((data)=>{
											console.log(data);
											if (data.data.success === 1){
												Swal.fire({
												title: "УСПЕХ",
												text: "ДАННЫЕ ДОБАВЛЕНЫ, НЕ ЗАБУДЬТЕ ПОДПИСАТЬСЯ НА ТЕЛЕГРАМ БОТ ПО ССЫЛКЕ",
												icon: "success",
												showDenyButton: false,
												confirmButtonText: 'Хорошо',
												denyButtonText: 'Отмена',
											})
											.then(()=>{
												setLoading(false)
											})
												
											} else {
												selectedTocka.tgname = tgname;
											setLoading(false)
											Swal.fire({
												title: "ОШИБКА",
												text: "ОШИБКА",
												icon: "error",
												showDenyButton: false,
												confirmButtonText: 'Хорошо',
												denyButtonText: 'Отмена',
											})
											.then(()=>{
												setLoading(false)
											})
											}
										})
										.catch((ex)=>{
											selectedTocka.tgname = tgname;
											setLoading(false)
											Swal.fire({
												title: "ОШИБКА",
												text: "ОШИБКА",
												icon: "error",
												showDenyButton: false,
												confirmButtonText: 'Хорошо',
												denyButtonText: 'Отмена',
											})
											.then(()=>{
												setLoading(false)
											})
										})
										
										
										
										

										

									}}
									loadingPosition="center"
									variant="contained"
									><span>СОХРАНИТЬ</span>
									</LoadingButton>
								</Stack>
								

								<Link href="https://t.me/stirka_notify_bot" underline="hover" sx={{m:2}}>
									{'Ссылка на бот Уведомления'}
								</Link>
								
								</Box>
							</TabPanel>
							: ''
					}
					  
                  </Box>

                  
                  </Grid>
          
		</Grid>
		<Grid item xs={12} sm={6} md={9}>
			<Item2 elevation='0' sx={{
					
					height: '100%',
					backgroundColor: '#eafefd',
					// opacity: [0.3, 0.4, 0.3],
					'&:hover': {
					backgroundColor: '#eaf5fe',
					// opacity: [0.3, 0.4, 0.3],
					},
				}}>
				<Stack direction={'row'} >
					<Typography variant="h5" color="#000" sx={{width:'100%'}}>ГРАФИК ПРОДАЖ</Typography>

					<Stack direction = 'row' spacing={2} xs={12} sm={12} md={12} mt={1} 
                          sx={{ 
                            display: 'flex',
                            alignItems:'right',
                            width:'100%',
                            height:30, 
                            justifyContent:'flex-end',
                            justify:'end'
                          }}>
                            
                            <ToggleButtonGroup 
							sx={{borderWidth: 2, justify:'right',
								borderColor:'black',}}
								exclusive
								value={reportNumber}
								onChange={(e)=>{
										console.log('onchange')
										console.log(e.target.value)
										setReportNumber(e.target.value)
										
										genSalesData(e.target.value)
									}}
								variant="contained" 
								aria-label="outlined primary button group"
								>
								<ToggleButton selectedColor='#ffb74d'
									value={1}
									selected = {reportNumber == 1 ? true : false}
									>СЕГОДНЯ
								
									</ToggleButton>
									<ToggleButton selectedColor='#ffb74d'
										value={2}
										selected = {reportNumber == 2 ? true : false}
									>ЗА НЕДЕЛЮ
									
									
									</ToggleButton>
									<ToggleButton selectedColor='#ffb74d'
										value={3}
										selected = {reportNumber == 3 ? true : false}
									>ЗА МЕСЯЦ
									
									
									</ToggleButton>
									<ToggleButton selectedColor='#ffb74d'
										value={4}
										selected = {reportNumber == 4 ? true : false}
									>ЗА ГОД
									
									
									</ToggleButton>
                          </ToggleButtonGroup>

                        </Stack>
				</Stack>
				<div>
				<div hidden={!loading2} sx={{width:'100%',mt:2}}>
				<LinearProgress hidden={!loading2} sx={{width:'100%',mt:2}}/>
				</div>
				
				<CChart
					type="line" 
					data={{
						labels: chartLabels,
						datasets: [
						{
							label: "Продажи",
							backgroundColor: "rgba(255, 0, 0, 0.2)",
							borderColor: "rgba(2550, 0, 0, 1)",
							pointBackgroundColor: "rgba(255, 0, 0, 1)",
							pointBorderColor: "#000",
							pointRadius: 7,
							data: salesData,
							// tension: 0.2
						}
						],
					}}
					customTooltips={false}
					options={{
						aspectRatio: 2.5,
						customTooltips:false,
						plugins: {
							datalabels: {
								backgroundColor: function(context) {
								return context.dataset.backgroundColor;
								},
								borderRadius: 4,
								color: 'white',
								font: {
								weight: 'bold'
								},
								formatter: Math.round,
								padding: 6
							},
						legend: {
							labels: {
							color: 'rgba(220, 220, 220, 1)'//getStyle('--cui-body-color'),
							}
						}
						},
						scales: {
						x: {
							grid: {
							color: 'rgba(0, 0, 0, 0.1)'//getStyle('--cui-border-color-translucent'),
							},
							ticks: {
							color: 'rgba(0, 0, 0, 1)'//getStyle('--cui-body-color'),
							},
						},
						y: {
							suggestedMax: maxValue,
            				suggestedMin: 0,
							grid: {
							color: 'rgba(0, 0, 0, 0.1)'//getStyle('--cui-border-color-translucent'),
							},
							ticks: {
							color: 'rgba(0, 0, 0, 1)'//getStyle('--cui-body-color'),
							},
						},
						},
					}}
					/>
				</div>
				
					
			</Item2>
	  </Grid>

      
      </Grid>
	  

      </Box>


      
    </div>
  )
}

export default Tockas