import React, {useState,useEffect,useContext,useRef} from 'react'
import Login from '../Login/Login';
import Header from './Header';
import axios from 'axios';
import moment from 'moment';

import { SocketContext } from './SocketProvider';
import swal from 'sweetalert';
import 'rc-time-picker/assets/index.css';



const Promos = () => { 


    const socket = useContext(SocketContext);
    const admin = JSON.parse(sessionStorage.getItem('user'));
    var innerlogs = [];
    const [logs, setLogs] = useState([]);
    const token2 = 'mesanatoken';
    const token = sessionStorage.getItem('accessToken');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [tockas, setTockas] = useState([]);
    const [value, setValue] = useState();
    const [selectedTockaId,setSelectedTockaId] = useState();
    const [isFiltered,setIsFiltered] = useState(false);
    const [offset,setOffset] = useState(0);
  
  
    const handleChange = (event) => {
      setValue(event.target.value);
      setSelectedTockaId(event.target.value);
    };
  
  
    const handleFilterClicked = (event) =>{
      setIsFiltered(true);
    }
  
    const handleCloseFilter = ()=>{
      setIsFiltered(false);
    }
  
    useEffect(() => {
      fetchTockas();
      return () => {
     };
    },[]);
    const fetchTockas = async () => {
  
      if (admin.admin_type == 1){
        const { data } = await axios.post(
          "http://46.17.250.209:8081/get/tockas/for/admin",{
            admin_id:admin.id,
            token:token2
          }
        )
        
        setTockas(data.tockas);
      } else {
        const { data } = await axios.post(
          "http://46.17.250.209:8081/get/tockas/by/admin",{
            admin_id:admin.id,
            token:token2
          }
        )
        var tockas = data.tockas;
        setTockas(tockas);
        setSelectedTockaId(tockas[0].id);
      }
    };
  
  
    const fetchLogs = async () => {
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/clients/by/tocka",{
          tocka_id:selectedTockaId,
          token:token2
        }
      )
      
      const products = data;
      
      setLogs(products.clients);
      innerlogs = products.clients;
    };
    if(!token ) {
      return (<Login  />)
    }
  
    return (
      <div>
      <Header />
      <h2 className='body2'>Фильтр</h2>
      <div className='filter-body-container'>
      <div className='filter-body'>
        <div className='filter-body-date-dwa'>
          <div>
          выбор прачечной<br/>
            <select value={selectedTockaId} onChange={handleChange} style={{width:250}}>
  
              {tockas && tockas.map(tocka=>(<option value={tocka.id}>{tocka.tocka_name + ' ' + tocka.tocka_address}</option>)
                
              )}
  
            </select>
            <br/><br/>
            <button className='prosto' onClick={handleFilterClicked}>ПОКАЗАТЬ</button>
  
            <button className='prosto' onClick={handleCloseFilter}>ОТМЕНИТЬ ФИЛЬТР</button>
          </div>
          
      </div>
        
  
      </div>
      </div>
      
      <h2 className='body2'>Настройка промоакций</h2>
      
      <div className='dashboard_content'>
        
      <div >
        {/* {<Knopkalar/>} */}
        <table className="styled-table">
                  <thead>
                      <tr>
                          <th></th>
                          <th>Каждая</th>
                          <th>Услуга</th>
                          <th></th>
                          <th>Активность</th>
                          
                      </tr>
                  </thead>
                  <tbody>
                      <tr key='stirka' onClick={()=>{
                            //handleTockaClick(tocka.id);
                          }} 
                            
                          >
                              <td></td>
                              <td>10</td>
                              <td>СТИРКА</td>
                              <td></td>
                              
                          </tr>
                          <tr key='sushka' onClick={()=>{
                            //handleTockaClick(tocka.id);
                          }} 
                            
                          >
                              <td></td>
                              <td>10</td>
                              <td>СУШКА</td>
                              <td></td>
                              
                          </tr>
                          <tr key='gel' onClick={()=>{
                            //handleTockaClick(tocka.id);
                          }} 
                            
                          >
                              <td></td>
                              <td>10</td>
                              <td>ГЕЛЬ ДЛЯ СТИРКИ</td>
                              <td></td>
                              
                          </tr>
                          
                      
                  </tbody>
              </table>
      </div>
      </div>
      </div>
    )

}

export default Promos;