import React, { useContext, useEffect, useState } from 'react'
import Header from './Header';
import Login from '../Login/Login';
import axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import swal from 'sweetalert';

import { SocketContext } from './SocketProvider'; 

export const Warns = (props) => {

    const socket = useContext(SocketContext);
    const admin = JSON.parse(sessionStorage.getItem('user'));
    var innerlogs = [];
    const [logs, setLogs] = useState([]);
    const token2 = 'mesanatoken';
    const token = sessionStorage.getItem('accessToken');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [tockas, setTockas] = useState([]);
    const [value, setValue] = useState();
    const [selectedTockaId,setSelectedTockaId] = useState();
    const [isFiltered,setIsFiltered] = useState(false);
    const [offset,setOffset] = useState(0);

    const handleChange = (event) => {
      setValue(event.target.value);
      setSelectedTockaId(event.target.value);
    };
  
  
    const handleFilterClicked = (event) =>{
      setIsFiltered(true);
      setOffset(0);
      fetchLogs();
  
    }
  
    const handleCloseFilter = ()=>{
  
      setStartDate(new Date);
      setEndDate(new Date);
      setIsFiltered(false);
      setOffset(0);
      fetchLogs();
    }
  
    useEffect(() => {
      fetchTockas();
      socket.emit("test", 'CLIENTS'); 
      return () => {
     };
    },[]);
    const fetchTockasByDate = async (of3) => {
        var of = of3 * 50;
        const { data } = await axios.post(
          "http://46.17.250.209:8081/get/logs/by/tocka/date",{
            tockaid:selectedTockaId,
            token:token2,
            startdate:moment(startDate).format("YYYY-MM-DD"),
            enddate:moment(endDate).format("YYYY-MM-DD"),
            offset:of
          }
        )
        
        setLogs(data.logs);
        if (data.logs.length == 0){
          swal("ВНИМАНИЕ", 'NO MORE ITEMS ', "warning", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            var of2 = of3 - 1;
            setOffset(of);
          });
        } else {
          
        }
    };
  
    const fetchTockas = async () => {
  
      if (admin.admin_type == 1 || admin.admin_type == 3){
        const { data } = await axios.post(
          "http://46.17.250.209:8081/get/tockas/for/admin",{
            admin_id:admin.id,
            token:token2
          }
        )
        
        setTockas(data.tockas);
        setSelectedTockaId(data.tockas[0].id);
      } else {
        const { data } = await axios.post(
          "http://46.17.250.209:8081/get/tockas/by/admin",{
            admin_id:admin.id,
            token:token2
          }
        )
        var tockas = data.tockas;
        setTockas(tockas);
        setSelectedTockaId(tockas[0].id);
        fetchLogs();
      }
  
      
    };
  
    const moveNext = (of) => {
      var of = offset+1;
      setOffset(of);
      fetchTockasByDate(of);
    }
  
    const moveBack = (of) => {
      var of = offset - 1;
      setOffset(of);
      fetchTockasByDate(of);
    }
  
    const fetchLogs = async () => {
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/clients/by/tocka",{
          tocka_id:selectedTockaId,
          token:token2
        }
      )
      
      const products = data;
      
      setLogs(products.clients);
      innerlogs = products.clients;
    };
  
    const Knopkalar = () =>{
      
        if (isFiltered){
          if (offset > 0){
            return (<div><button className='prosto' onClick={()=>{
              moveBack(offset - 1);
            }}>пред.</button><button className='prosto' onClick={()=>{
              moveNext(offset + 1);
            }}>след.</button></div>);
          } else {
            return (<button className='prosto' onClick={()=>{
              moveNext(offset + 1);
            }}>след.</button>);
          }
        } else {
          return <div></div>;
        }
      
      return null;
    }
    
  
    
    if(!token ) {
      return (<Login  />)
    }
  
    return (
      <div>
      <Header selected = {5}/>
      <h2 className='body2'>Фильтр</h2>
      <div className='filter-body-container'>
      <div className='filter-body'>
        <div className='filter-body-date-dwa'>
          <div>
          выбор прачечной<br/>
            <select value={selectedTockaId} onChange={handleChange} style={{width:250}}>
  
              {tockas && tockas.map(tocka=>(<option value={tocka.id}>{tocka.tocka_name + ' ' + tocka.tocka_address}</option>)
                
              )}
  
            </select>
            <br/><br/>
            <button className='prosto' onClick={handleFilterClicked}>ПОКАЗАТЬ</button>
  
            <button className='prosto' onClick={handleCloseFilter}>ОТМЕНИТЬ ФИЛЬТР</button>
          </div>
          
      </div>
        
  
      </div>
      </div>
      
      <h2 className='body2'>Список уведомлений</h2>
      
      <div className='dashboard_content'>
        
      <div >
        
      </div>
      </div>
      </div>
    )
  }
  
  export default Warns;
  