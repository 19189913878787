import React from 'react';
import { useEffect, useRef, useState } from "react";
import  Logo  from "../../assets/dashboardlogo2.svg"; 
import {useNavigate,Link} from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import { styled } from "@mui/material/styles";
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import swal from 'sweetalert';

const OwnToggleButton = styled(ToggleButton)(({ selectedColor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "black",
      backgroundColor: selectedColor
    }
  }));

//   const badgeRef = useRef<HTMLInputElement>(null);
// const [position, setPosition] = useState(0);

const menu = [
    
    {
        title: 'Мои точки',
        link:'tockas',
        order: 0
    },
    {
        title: 'Продажи',
        link:'status',
        order: 1
    },
	
	{
        title: 'Подписки',
        link:'podpiskas',
        order: 7
    },
	{
        title: 'Договоры',
        link:'contracts',
        order: 8
    },
    {
        title: 'Клиенты',
        link:'clients',
        order: 2
    },
    {
        title: 'Промокоды',
        link:'settings',
        order: 3
    },
    {
        title: 'Уведомления',
        link:'warns',
        order: 5
    },
    
    {
        title: 'Логи',
        link:'logs',
        order: 6
    },
]

const menu3 = [
    
    {
        title: 'Мои точки',
        link:'tockas',
        order: 0
    },
    {
        title: 'Продажи',
        link:'status',
        order: 1
    },
	{
        title: 'Реестр Продаж',
        link:'sales',
        order: 9
    },
	{
        title: 'Подписки',
        link:'podpiskas',
        order: 7
    },
	{
        title: 'Договоры',
        link:'contracts',
        order: 8
    },
    {
        title: 'Клиенты',
        link:'clients',
        order: 2
    },
    {
        title: 'Промокоды',
        link:'settings',
        order: 3
    },
    {
        title: 'Промоакции',
        link:'promos',
        order: 4
    },
    {
        title: 'Уведомления',
        link:'warns',
        order: 5
    },
    {
        title: 'Логи',
        link:'logs',
        order: 6
    },
]

const menu2 = [
    
    {
        title: 'Мои точки',
        link:'tockas',
        order: 0
    },
    {
        title: 'Промокоды',
        link:'settings',
        order: 3
    },
    {
        title: 'Логи',
        link:'logs',
        order: 6
    },
]

const menu213 = [
    
    {
        title: 'Мои точки',
        link:'tockas',
        order: 0
    },
    {
        title: 'Продажи',
        link:'status',
        order: 1
    },
    {
        title: 'Клиенты',
        link:'clients',
        order: 2
    },
    {
        title: 'Промокоды',
        link:'settings',
        order: 3
    },
    {
        title: 'Логи',
        link:'logs',
        order: 6
    },
]

const menu21 = [
    
    {
        title: 'Мои точки',
        link:'tockas',
        order: 0
    },
    {
        title: 'Промокоды',
        link:'settings',
        order: 3
    },
    {
        title: 'Логи',
        link:'logs',
        order: 6
    },
]

const menu4 = [
    
    {
        title: 'Продажи',
        link:'status',
        order: 1
    },
	{
        title: 'Реестр Продаж',
        link:'sales',
        order: 9
    },
    {
        title: 'Клиенты',
        link:'clients',
        order: 2
    },
]

const menu5 = [
    
    
	{
        title: 'Договоры',
        link:'contracts',
        order: 8
    }
]

const admin = JSON.parse(sessionStorage.getItem('user'));


const Header = (props) => {
    let renderSwitch = props.renderSwitch;
    let selectedMenu = props.selectedMenu;
    const navigate = useNavigate();
    var selectedTab = props.selected;

    const badgeRef = useRef<HTMLInputElement>(null);
    const [position, setPosition] = useState(0);
	const [selectedContract,setSelectedContract] = useState(null);

	const [contracts, setContracts] = useState([]);
	const exceptAdmins = [95,112,10,58,108,98,20,51,71,49]

	const fetchContracts = async () => {

		console.log('DOGOWOR FETCH CONTRACTS ISHLEDI ' + admin.id);
		console.log(admin.id);
		setContracts([])
		console.log('DOGOWOR1 FETCH CONTRACTS ISHLEDI ' + admin.id);
		setSelectedContract(null)
		console.log('DOGOWOR2 FETCH CONTRACTS ISHLEDI ' + admin.id);
		// setSigned(false)
		  const { data } = await axios.post(
			"http://46.17.250.209:8081/get/tocka/contracts",{
			  admin_id: admin.id,
			  token:'mesanatoken'
			}
		  )
		  console.log('DOGOWOR HANT ' + data.contracts.length);
		  setContracts(data.contracts);
		  //setIpes(data.ipes)
	  
		  if (selectedContract != null) {
			setSelectedContract(selectedContract.contract)
		  }
	  
	  
		  var rows2 = [];
		  var ind2 = 0;
			
		  console.log(data.ipes);
	  
		  data.contracts.map((contract,ind) => {
			  var ind3 = ind2 + 1 + ind;
	
			  console.log(contract);
			  
			  rows2.push({ id: contract.id, 
				col1: contract.cname, 
				col2: contract.signed,//moment.utc(moment(podpiska.startdate).add(3,'hours')).format("DD.MM.YYYY"), 
				// col3: moment.utc(moment(podpiska.enddate).add(3,'hours')).format("DD.MM.YYYY"), 
				// col4: podpiska.cost,
				// col5: podpiska.paid,
				// col6: {podpiska : podpiska},
				// col7: podpiska.billid,
				 contract: contract
			  });
	  
			  
				//setLoading(false)
	  
		  });
	  
		  //setPageState(old => ({ ...old, isLoading: false, data: data.podpiskas, total: data.sany }))
		  //setLoading(false)
	  
		  setContracts(rows2);
	  
		//   setSany(data.sany);
		  
		  if (data.contracts.length == 0){
			// swal("ВНИМАНИЕ", 'НИЧЕГО НЕ НАЙДЕНО', "warning", {
			//   buttons: false,
			//   timer: 2000,
			// })
		  } 
		  
		
	
		
	  };
	

    useEffect(() => {
	fetchContracts()
    setPosition(badgeRef.current? (badgeRef.current.getBoundingClientRect().width/2): 0);
    }, [badgeRef]);


  const token = sessionStorage.getItem('accessToken');
  

  
    
	const Header2 = ()=>{
		var selectedTab = props.selected;

		

			
				if (admin.admin_type === 2 || admin.admin_type === 5){
					return(menu2.map((item,idx)=>(
			
						
					//     selectedTab == idx ? <li className='menu-selected' key={idx}>
					//     <Link  to={'/dashboard/'+item.link} 
					//         >{item.title}</Link>
					// </li> : <li  key={idx}>
					//         <Link  to={'/dashboard/'+item.link} 
					//             >{item.title}</Link>
					//     </li>
			
					
			
					<OwnToggleButton sx={{
						m: 0.5,
						variant:"contained",
						backgroundColor: 'info.main',
						color: '#fff',
						'&:hover': {
						backgroundColor: 'info.light',
						color: '#fff',
						// opacity: [0.3, 0.4, 0.3],
						},
						'&:selected': {
							backgroundColor: 'info.light',
							// opacity: [0.3, 0.4, 0.3],
							color: '#000',
						},
					}}
					selectedColor= '#ffb74d'//'#4fc3f7'
						value="check"
						fontWeight = "700"
						selected={selectedTab === item.order ? true : false}
						onChange={() => {
							
							// //console.log('BASDYM ' + idx);
			
							
							// <Link  to={'/dashboard/'+item.link} 
							navigate('/dashboard/'+item.link,{ replace: true })
			
						}}
						>
						{/* <CheckIcon /> */}
						
						<Typography sx={{fontWeight: 'bold', fontSize: 13}}>{item.title}</Typography>
						</OwnToggleButton>
			
							
					)))
					
				} else if (admin.admin_type === 1){
					return(menu3.map((item,idx)=>(
			
			
						<OwnToggleButton sx={{
							m: 0.5,
							variant:"contained",
							backgroundColor: 'info.main',
							color: '#fff',
							'&:hover': {
							backgroundColor: 'info.light',
							color: '#fff',
							// opacity: [0.3, 0.4, 0.3],
							},
							'&:selected': {
								backgroundColor: 'info.light',
								// opacity: [0.3, 0.4, 0.3],
								color: '#000',
							},
						}}
						selectedColor= '#ffb74d'//'#4fc3f7'
							value="check"
							fontWeight = "700"
							selected={selectedTab === item.order ? true : false}
							onChange={() => {
								
								// // //console.log('BASDYM ' + idx);
								// <Link  to={'/dashboard/'+item.link} 
								navigate('/dashboard/'+item.link,{ replace: true })
			
							}}
							>
							{/* <CheckIcon /> */}
							
							<Typography sx={{fontWeight: 'bold', fontSize: 13}}>{item.title}</Typography>
							</OwnToggleButton>
			
			
					//     selectedTab == idx ? <li className='menu-selected' key={idx}>
					//     <Link  to={'/dashboard/'+item.link} 
					//         >{item.title}</Link>
					// </li> : <li  key={idx}>
					//         <Link  to={'/dashboard/'+item.link} 
					//             >{item.title}</Link>
					//     </li>
			
							
					)))
					
				} else if (admin.admin_type === 3){
					return(menu4.map((item,idx)=>(
			
					//     selectedTab == idx ? <li className='menu-selected' key={idx}>
					//     <Link  to={'/dashboard/'+item.link} 
					//         >{item.title}</Link>
					// </li> : <li  key={idx}>
					//         <Link  to={'/dashboard/'+item.link} 
					//             >{item.title}</Link>
					//     </li>
			
					<OwnToggleButton sx={{
						m: 0.5,
						variant:"contained",
						backgroundColor: 'info.main',
						color: '#fff',
						'&:hover': {
						backgroundColor: 'info.light',
						color: '#fff',
						// opacity: [0.3, 0.4, 0.3],
						},
						'&:selected': {
							backgroundColor: 'info.light',
							// opacity: [0.3, 0.4, 0.3],
							color: '#000',
						},
					}}
					selectedColor= '#ffb74d'//'#4fc3f7'
						value="check"
						fontWeight = "700"
						selected={selectedTab === item.order ? true : false}
						onChange={() => {
							
							// // //console.log('BASDYM ' + idx);
							// <Link  to={'/dashboard/'+item.link} 
							navigate('/dashboard/'+item.link,{ replace: true })
			
						}}
						>
						{/* <CheckIcon /> */}
						
						<Typography sx={{fontWeight: 'bold', fontSize: 13}}>{item.title}</Typography>
						</OwnToggleButton>
			
							
					)))
			
				} 
				else if (admin.admin_type === 4){
					return(menu21.map((item,idx)=>(
			
					//     selectedTab == idx ? <li className='menu-selected' key={idx}>
					//     <Link  to={'/dashboard/'+item.link} 
					//         >{item.title}</Link>
					// </li> : <li  key={idx}>
					//         <Link  to={'/dashboard/'+item.link} 
					//             >{item.title}</Link>
					//     </li>
			
					<OwnToggleButton sx={{
						m: 0.5,
						variant:"contained",
						backgroundColor: 'info.main',
						color: '#fff',
						'&:hover': {
						backgroundColor: 'info.light',
						color: '#fff',
						// opacity: [0.3, 0.4, 0.3],
						},
						'&:selected': {
							backgroundColor: 'info.light',
							// opacity: [0.3, 0.4, 0.3],
							color: '#000',
						},
					}}
					selectedColor= '#ffb74d'//'#4fc3f7'
						value="check"
						fontWeight = "700"
						selected={selectedTab === item.order ? true : false}
						onChange={() => {
							
							// // //console.log('BASDYM ' + idx);
							// <Link  to={'/dashboard/'+item.link} 
							navigate('/dashboard/'+item.link,{ replace: true })
			
						}}
						>
						{/* <CheckIcon /> */}
						
						<Typography sx={{fontWeight: 'bold', fontSize: 13}}>{item.title}</Typography>
						</OwnToggleButton>
			
							
					)))
				}
				else if (admin.admin_type === 6){
					return(menu213.map((item,idx)=>(
			
					//     selectedTab == idx ? <li className='menu-selected' key={idx}>
					//     <Link  to={'/dashboard/'+item.link} 
					//         >{item.title}</Link>
					// </li> : <li  key={idx}>
					//         <Link  to={'/dashboard/'+item.link} 
					//             >{item.title}</Link>
					//     </li>
			
					<OwnToggleButton sx={{
						m: 0.5,
						variant:"contained",
						backgroundColor: 'info.main',
						color: '#fff',
						'&:hover': {
						backgroundColor: 'info.light',
						color: '#fff',
						// opacity: [0.3, 0.4, 0.3],
						},
						'&:selected': {
							backgroundColor: 'info.light',
							// opacity: [0.3, 0.4, 0.3],
							color: '#000',
						},
					}}
					selectedColor= '#ffb74d'//'#4fc3f7'
						value="check"
						fontWeight = "700"
						selected={selectedTab === item.order ? true : false}
						onChange={() => {
							
							// // //console.log('BASDYM ' + idx);
							// <Link  to={'/dashboard/'+item.link} 
							navigate('/dashboard/'+item.link,{ replace: true })
			
						}}
						>
						{/* <CheckIcon /> */}
						
						<Typography sx={{fontWeight: 'bold', fontSize: 13}}>{item.title}</Typography>
						</OwnToggleButton>
			
							
					)))
				}
				
				else {
					console.log('BURDAYIM DOGOWOR');
					if (!exceptAdmins.includes(admin.id)) {
						var signed = true;
						console.log('DOGOWOR ONCTRACT SANY ' + contracts.length);
						for (var i = 0; i < contracts.length; i++) {
							console.log('DOGOWOR CONTRACT SIGNED ' + contracts[i].signed);
							if (contracts[i].signed == false) {
								signed = false;
							}
						}
						console.log('DOGOWOR NOT SIGNED ' + signed);
							if (!signed) {
								
								swal("ВНИМАНИЕ", 'ДЛЯ ПРОДОЛЖЕНИЯ РАБОТЫ ПРИМИТЕ СОГЛАШЕНИЯ ЗАПОЛНИВ ДАННЫЕ!', "warning", {
									buttons: false,
									// timer: 2000,
								  })

								selectedTab = 8;
								return(menu5.map((item,idx)=>(
							
									//     selectedTab == idx ? <li className='menu-selected' key={idx}>
									//     <Link  to={'/dashboard/'+item.link} 
									//         >{item.title}</Link>
									// </li> : <li  key={idx}>
									//         <Link  to={'/dashboard/'+item.link} 
									//             >{item.title}</Link>
									//     </li>
									
									<Badge badgeContent = { 
										
											<Typography sx={{fontWeight: 'bold', fontSize: 15}}>{item.order == 5 ?  4 : 0}</Typography>
										
										} 
										invisible =  {item.order == 5 ?  false : true}
									overlap="circular"
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									sx={{
										"& .MuiBadge-badge": {
										color: "white",
										backgroundColor: "warning.dark",
										alignContent: 'center',
										fontSize: 15, 
										height: 25, minWidth: 25, 
										borderRadius: '50%',
										right: `${position + 10}px`,
										}
									}}>
									
									<OwnToggleButton sx={{
										m: 0.5,
										variant:"contained",
										backgroundColor: 'info.main',
										color: '#fff',
										'&:hover': {
										backgroundColor: 'info.light',
										color: '#fff',
										// opacity: [0.3, 0.4, 0.3],
										},
										'&:selected': {
											backgroundColor: 'info.light',
											// opacity: [0.3, 0.4, 0.3],
											color: '#000',
										},
									}}
									selectedColor= '#ffb74d'//'#4fc3f7'
										value="check"
										fontWeight = "700"
										
										selected={selectedTab === item.order ? true : false}
										onChange={() => {
											
											// // //console.log('BASDYM ' + idx);
											// <Link  to={'/dashboard/'+item.link} 
											navigate('/dashboard/'+item.link,{ replace: true })
							
										}}
										>
										{/* <CheckIcon /> */}
										
										<Typography sx={{fontWeight: 'bold', fontSize: 13}}>{item.title}</Typography>
										</OwnToggleButton>
							
									</Badge>
							
									
							
											
									)))
							} else {
								return(menu.map((item,idx)=>(
			
			
									<OwnToggleButton sx={{
										m: 0.5,
										variant:"contained",
										backgroundColor: 'info.main',
										color: '#fff',
										'&:hover': {
										backgroundColor: 'info.light',
										color: '#fff',
										// opacity: [0.3, 0.4, 0.3],
										},
										'&:selected': {
											backgroundColor: 'info.light',
											// opacity: [0.3, 0.4, 0.3],
											color: '#000',
										},
									}}
									selectedColor= '#ffb74d'//'#4fc3f7'
										value="check"
										fontWeight = "700"
										selected={selectedTab === item.order ? true : false}
										onChange={() => {
											
											// // //console.log('BASDYM ' + idx);
											// <Link  to={'/dashboard/'+item.link} 
											navigate('/dashboard/'+item.link,{ replace: true })
						
										}}
										>
										{/* <CheckIcon /> */}
										
										<Typography sx={{fontWeight: 'bold', fontSize: 13}}>{item.title}</Typography>
										</OwnToggleButton>
						
						
								//     selectedTab == idx ? <li className='menu-selected' key={idx}>
								//     <Link  to={'/dashboard/'+item.link} 
								//         >{item.title}</Link>
								// </li> : <li  key={idx}>
								//         <Link  to={'/dashboard/'+item.link} 
								//             >{item.title}</Link>
								//     </li>
						
										
								)))
							}
					}
					else {
							return(menu.map((item,idx)=>(
			
			
								<OwnToggleButton sx={{
									m: 0.5,
									variant:"contained",
									backgroundColor: 'info.main',
									color: '#fff',
									'&:hover': {
									backgroundColor: 'info.light',
									color: '#fff',
									// opacity: [0.3, 0.4, 0.3],
									},
									'&:selected': {
										backgroundColor: 'info.light',
										// opacity: [0.3, 0.4, 0.3],
										color: '#000',
									},
								}}
								selectedColor= '#ffb74d'//'#4fc3f7'
									value="check"
									fontWeight = "700"
									selected={selectedTab === item.order ? true : false}
									onChange={() => {
										
										// // //console.log('BASDYM ' + idx);
										// <Link  to={'/dashboard/'+item.link} 
										navigate('/dashboard/'+item.link,{ replace: true })
					
									}}
									>
									{/* <CheckIcon /> */}
									
									<Typography sx={{fontWeight: 'bold', fontSize: 13}}>{item.title}</Typography>
									</OwnToggleButton>
					
					
							//     selectedTab == idx ? <li className='menu-selected' key={idx}>
							//     <Link  to={'/dashboard/'+item.link} 
							//         >{item.title}</Link>
							// </li> : <li  key={idx}>
							//         <Link  to={'/dashboard/'+item.link} 
							//             >{item.title}</Link>
							//     </li>
					
									
							)))
						}

					// return(menu.map((item,idx)=>(
			
					// //     selectedTab == idx ? <li className='menu-selected' key={idx}>
					// //     <Link  to={'/dashboard/'+item.link} 
					// //         >{item.title}</Link>
					// // </li> : <li  key={idx}>
					// //         <Link  to={'/dashboard/'+item.link} 
					// //             >{item.title}</Link>
					// //     </li>
					
					// <Badge badgeContent = { 
						
					// 		<Typography sx={{fontWeight: 'bold', fontSize: 15}}>{item.order == 5 ?  4 : 0}</Typography>
						
					// 	} 
					// 	invisible =  {item.order == 5 ?  false : true}
					// overlap="circular"
					// anchorOrigin={{
					// 	vertical: 'top',
					// 	horizontal: 'right',
					// }}
					// sx={{
					// 	"& .MuiBadge-badge": {
					// 	color: "white",
					// 	backgroundColor: "warning.dark",
					// 	alignContent: 'center',
					// 	fontSize: 15, 
					// 	height: 25, minWidth: 25, 
					// 	borderRadius: '50%',
					// 	right: `${position + 10}px`,
					// 	}
					// }}>
					
					// <OwnToggleButton sx={{
					// 	m: 0.5,
					// 	variant:"contained",
					// 	backgroundColor: 'info.main',
					// 	color: '#fff',
					// 	'&:hover': {
					// 	backgroundColor: 'info.light',
					// 	color: '#fff',
					// 	// opacity: [0.3, 0.4, 0.3],
					// 	},
					// 	'&:selected': {
					// 		backgroundColor: 'info.light',
					// 		// opacity: [0.3, 0.4, 0.3],
					// 		color: '#000',
					// 	},
					// }}
					// selectedColor= '#ffb74d'//'#4fc3f7'
					// 	value="check"
					// 	fontWeight = "700"
					// 	selected={selectedTab === item.order ? true : false}
					// 	onChange={() => {
							
					// 		// // //console.log('BASDYM ' + idx);
					// 		// <Link  to={'/dashboard/'+item.link} 
					// 		navigate('/dashboard/'+item.link,{ replace: true })
			
					// 	}}
					// 	>
					// 	{/* <CheckIcon /> */}
						
					// 	<Typography sx={{fontWeight: 'bold', fontSize: 13}}>{item.title}</Typography>
					// 	</OwnToggleButton>
			
					// </Badge>
			
					
			
							
					// )))
				}
			
	}
    
    



  return (
    <div className='header'>
    <div className='headerLogo'>
            <img src={Logo} alt='' />
    </div>
    <div className="wrapper">
        <ul className='menu'>
            
        {<Header2 />}
        
        </ul>
        <button className='logOutButton' onClick={()=>
        {
            sessionStorage.removeItem("accessToken");
            sessionStorage.removeItem("user");
            navigate('/login',{ replace: true })
        }
        }>Выйти</button>
    </div>
    
	</div>
  )
}

export default Header
